import React, { useState, useContext, useRef, useEffect } from "react";
import { Divider, Form, Input, Modal, Spin, Tabs } from "antd";
import { useDispatch, useSelector, connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { boundError } from "../../utlis/boundError";
import { loginSuccess } from "../../../redux/actions";
import { ButtonPrimary } from "../../atoms/ButtonPrimary";
import { LOGIN_TABS } from "../../../constants/types";
import { showError, showSuccess } from "../../utlis/messages";
import { UserServices } from "../../../services";
import { AuthWrapper } from "./AuthWrapper";
import axios from "axios";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib-esm/types/Auth";
import { GoogleOutlined, AppleOutlined } from "@ant-design/icons";

interface IFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword?: string;
  referralCode?: string;
}
const LoginRaw = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const userServices = UserServices.getInstance();
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);
  const [tabSelected, setTabSelected] = useState(LOGIN_TABS.LOGIN);
  const [formValues, setFormValues] = useState();
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const validation = async (values) => {
    const { firstName, lastName, email, password, confirmPassword, referralCode } = values;

    if (tabSelected === LOGIN_TABS.SIGNUP && !firstName) {
      showError("First name is required.");
      return false;
    }

    if (tabSelected === LOGIN_TABS.SIGNUP && !lastName) {
      showError("Last name is required.");
      return false;
    }

    if (!email) {
      showError("Email is required.");
      return false;
    }
    if (!password) {
      showError("Password is required.");
      return false;
    }
    if (tabSelected === LOGIN_TABS.SIGNUP && confirmPassword !== password) {
      showError("Passwords do not match.");
      return false;
    }
    if (referralCode) {
      const data = await axios
        .get(`${process.env.API_BASE_URL}/api/v1.0/user/checkIsValidCode?referralCode=${referralCode}`)
        .then((res) => res.data.data);

      if (!data.isValidCode) {
        showError("Referral code does not exist.");
        return false;
      }
    }
    return true;
  };

  const login = async (values: IFormValues) => {
    if (!(await validation(values))) {
      return;
    }
    const { email, password, confirmPassword } = values;

    setLoading(true);
    try {
      const userAuth = await userServices.login({ email, password });
      dispatch(loginSuccess(userAuth));
      navigate("/");
      showSuccess("Welcome to EZAT dashboard!");
    } catch (err: any) {
      if (err.code === "UserNotConfirmedException" || err.name === "UserNotConfirmedException") {
        await userServices.resendSignUpCode({ email });
        navigate("/sign-up", {
          state: {
            email,
            password
          }
        });
      } else {
        handleLoginError(err);
      }
    }

    setLoading(false);
  };

  const loginWithThirdParty = async (provider: CognitoHostedUIIdentityProvider) => {
    setLoading(true);
    try {
      const userAuth = await userServices.loginWithThirdParty(provider);
      dispatch(loginSuccess(userAuth));
      navigate("/");
      showSuccess("Welcome to EZAT dashboard!");
    } catch (err: any) {
      let errMessage = err?.message || err;
      if (errMessage.includes("signInUserSession")) return;
      handleLoginError(err);
    }

    setLoading(false);
  };

  const signup = async (values: IFormValues) => {
    if (!(await validation(values))) {
      return;
    }
    const { firstName, lastName, email, password, confirmPassword, referralCode } = values;

    setLoading(true);
    try {
      const userAuth = await userServices.signup({
        email,
        password,
        passwordConfirm: confirmPassword,
        attributes: {
          given_name: firstName,
          family_name: lastName,
          "custom:referral_code": referralCode
        }
      });

      navigate("/sign-up", {
        state: {
          email,
          password
        }
      });
    } catch (err) {
      handleLoginError(err);
    }

    setLoading(false);
  };

  const handleLoginError = async (err, values = {}) => {
    let errMessage = err?.message || err;
    if (typeof errMessage !== "string") {
      errMessage = JSON.stringify(errMessage);
    }
    showError(errMessage);
  };

  return (
    <Spin spinning={loading}>
      <AuthWrapper>
        <div className="login-container">
          <div className="login-box">
            <div
              style={{
                paddingLeft: 20,
                paddingTop: 30,
                fontSize: 30,
                fontWeight: 500
              }}
            >
              {tabSelected}
            </div>
            <Form
              style={{ padding: "20px" }}
              onFinish={tabSelected === LOGIN_TABS.LOGIN ? login : signup}
              layout="vertical"
              form={form}
              name="login-form"
              id="login-form"
            >
              {tabSelected === LOGIN_TABS.SIGNUP && (
                <>
                  <Form.Item label="First name" name="firstName">
                    <Input allowClear id="firstName" autoFocus onChange={() => setLoginError("")} />
                  </Form.Item>
                  <Form.Item label="Last name" name="lastName">
                    <Input allowClear id="lastName" onChange={() => setLoginError("")} />
                  </Form.Item>
                </>
              )}

              <Form.Item label="Email" name="email" normalize={(value) => value.replace(" ", "").toLowerCase()}>
                <Input allowClear id="email" autoFocus type="email" onChange={() => setLoginError("")} />
              </Form.Item>
              <Form.Item label="Password" name="password">
                <Input.Password allowClear id="password" />
              </Form.Item>
              {tabSelected === LOGIN_TABS.SIGNUP && (
                <Form.Item label="Confirm password" name="confirmPassword">
                  <Input.Password allowClear id="confirmPassword" />
                </Form.Item>
              )}
              {tabSelected === LOGIN_TABS.SIGNUP && (
                <Form.Item label="Referral code" name="referralCode">
                  <Input placeholder="Optional" allowClear id="referralCode" onChange={() => setLoginError("")} />
                </Form.Item>
              )}
              {tabSelected === LOGIN_TABS.LOGIN && (
                <div className="forget-password" onClick={() => navigate("/forgot-password")}>
                  Forgot password
                </div>
              )}
              <ButtonPrimary htmlType="submit" type="primary" size={"medium"} block>
                {tabSelected}
              </ButtonPrimary>
              <Divider plain>Or</Divider>
              <ButtonPrimary
                size={"medium"}
                block
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
                onClick={() => loginWithThirdParty(CognitoHostedUIIdentityProvider.Google)}
              >
                <GoogleOutlined />
                Sign in with Google
              </ButtonPrimary>
              {process.env.ENV !== "prod" && (
                <ButtonPrimary
                  size={"medium"}
                  block
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                  onClick={() => loginWithThirdParty(CognitoHostedUIIdentityProvider.Apple)}
                >
                  <AppleOutlined />
                  Sign in with Apple
                </ButtonPrimary>
              )}
              {tabSelected === LOGIN_TABS.LOGIN && (
                <div
                  className="mode-toggle"
                  // style={{ marginTop: 30, fontSize: 12 }}
                >
                  Not a member?
                  <span
                    onClick={() => setTabSelected(LOGIN_TABS.SIGNUP)}
                    style={{
                      marginLeft: 8,
                      cursor: "pointer",
                      textDecoration: "underline"
                    }}
                  >
                    {LOGIN_TABS.SIGNUP}
                  </span>
                </div>
              )}
              {tabSelected === LOGIN_TABS.SIGNUP && (
                <div
                  className="mode-toggle"
                  // style={{ marginTop: 30, fontSize: 12 }}
                >
                  Already a member?
                  <span
                    onClick={() => setTabSelected(LOGIN_TABS.LOGIN)}
                    style={{
                      marginLeft: 8,
                      cursor: "pointer",
                      textDecoration: "underline"
                    }}
                  >
                    {LOGIN_TABS.LOGIN}
                  </span>
                </div>
              )}
            </Form>
          </div>
        </div>
      </AuthWrapper>
    </Spin>
  );
};

const Login = boundError(connect(null, { loginSuccess })(LoginRaw));
export default Login;
