import React, { useEffect, useState } from "react";
import { Layout } from "antd";

import { Header } from "./Header";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { boundError } from "../../utlis/boundError";
import { EventUtils, EVENT_KEYS } from "../../utlis/events";
import Sider from "antd/es/layout/Sider";
import { SideMenu } from "./SideMenu";
import { NotificationBar } from "./NotificationBar";

const { Content } = Layout;

const MainLayoutRaw = () => {
  const location = useLocation();
  const [showSider, setShowSider] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [showNotiBar, setShowNotiBar] = useState(false);

  const test = useSelector((state) => state);
  useEffect(() => {
    EventUtils.listenEvent(EVENT_KEYS.ON_CURRENT_ACCOUNT_UPDATE, () => {});
    EventUtils.listenEvent(EVENT_KEYS.ON_CURRENT_USER_UPDATE, () => {});
    if (
      window.location.href.search("/accounts") !== -1 ||
      window.location.href.search("/change-password") !== -1 ||
      window.location.href.search("/ambassador-program") !== -1
    ) {
      setShowSider(false);
      setShowNotiBar(false);
    } else {
      setShowSider(true);
      setShowNotiBar(true);
    }
    setCollapsed(false);
  }, [window.location.href]);

  return (
    <Layout>
      {showNotiBar && <NotificationBar />}
      <Header />
      <Layout
        style={{
          backgroundColor: "white"
        }}
      >
        {showSider && (
          <Sider
            collapsedWidth={40}
            collapsible
            className="menu-sider"
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
          >
            <SideMenu />
          </Sider>
        )}
        <Content
          style={{
            margin: "0",
            overflow: "initial",
            minHeight: "100vh",
            backgroundColor: "white",
            marginLeft: showSider ? (collapsed ? 40 : 200) : 0,
            transition: "all 0.2s ease 0s"
          }}
        >
          <div className="site-layout-background" style={{ padding: 0, textAlign: "center" }}>
            <Outlet />
          </div>
        </Content>
      </Layout>

      <Layout className="site-layout"></Layout>
      {/* <BottomNavBar /> */}
    </Layout>
  );
};

export const MainLayout = boundError(MainLayoutRaw);
