import {
  doActivateApiKey,
  doCreateApiKey,
  doDeactivateApiKey,
  doDeleteApiKey,
  doGetApiKeys,
  doUpdateApiKeyName
} from "../apis/apiKeyApis";

export class ApiKeyService {
  static apiKeyService: ApiKeyService;

  static getInstance() {
    if (!this.apiKeyService) {
      this.apiKeyService = new ApiKeyService();
    }

    return this.apiKeyService;
  }

  async getApiKeys() {
    return await doGetApiKeys({ limit: 100, offset: 0 });
  }

  async createApiKey(apiKeyName) {
    return await doCreateApiKey(apiKeyName);
  }

  async updateApiKeyName(apiKeyId, apiKeyName) {
    return await doUpdateApiKeyName(apiKeyId, apiKeyName);
  }

  async deleteApiKey(apiKeyID) {
    return await doDeleteApiKey(apiKeyID);
  }

  async activateApiKey(apiKeyID) {
    return await doActivateApiKey(apiKeyID);
  }

  async deactivateApiKey(apiKeyID) {
    return await doDeactivateApiKey(apiKeyID);
  }
}
