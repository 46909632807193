import axios from "axios";
import { EventUtils, EVENT_KEYS } from "../components/utlis/events";
import { AccountsServices, UserServices } from "../services";

const handleRefreshError = (failedRequest: any) => {
  const userServices = UserServices.getInstance();

  localStorage.clear();
  userServices.signOut();
  return Promise.reject(failedRequest);
};

const decorateAxiosForApi = ({ apiBaseUrl, overwriteIdToken }: any = {}) => {
  const decoratedAxios = axios.create({ baseURL: apiBaseUrl });
  const requestConfigProcessor = async (config: any) => {
    if (!config.headers?.common?.Authorization) {
      const userServices = UserServices.getInstance();
      // const accountsServices = AccountsServices.getInstance();

      let idToken = overwriteIdToken ?? userServices.idToken;
      let accountId = "";
      if (!idToken) {
        idToken = (await userServices.getCurrentTokens()).idToken;
      }

      // EventUtils.listenEvent(EVENT_KEYS.ON_CURRENT_ACCOUNT_UPDATE, () => {
      accountId = AccountsServices.getInstance().currentAccountId;
      // });

      decoratedAxios.defaults.headers.common["Authorization"] = idToken;
      return {
        ...config,
        headers: {
          "ebs-account-id": accountId,
          ...config.headers,
          Authorization: idToken
        }
      };
    }
    return config;
  };

  decoratedAxios.interceptors.request.use(requestConfigProcessor, (err) => {
    return Promise.reject(err);
  });

  const refreshIdTokenAndRetry = async (failedRequest: any) => {
    failedRequest.config.skipRetry = true;
    try {
      const userServices = UserServices.getInstance();

      const idToken = (await userServices.getCurrentTokens()).idToken;
      failedRequest.config.headers["Authorization"] = idToken;
      decoratedAxios.defaults.headers.common["Authorization"] = idToken;

      return decoratedAxios.request(failedRequest.config);
    } catch (err) {
      return handleRefreshError(failedRequest);
    }
  };

  decoratedAxios.interceptors.response.use(
    (res) => res,
    (err) => {
      if (err.response?.data?.message === "Token expired" && err.response?.data?.error === "Unauthorized") {
        handleRefreshError(err);
      }
      const shouldRefresh = err.response?.data?.message === "CognitoTokenExpired" && !err.config.skipRetry;
      if (shouldRefresh) {
        return refreshIdTokenAndRetry(err);
      }

      if (err.response?.data?.message) {
        return Promise.reject(err.response?.data?.message);
      }

      return Promise.reject(err);
    }
  );

  return decoratedAxios;
};

// eslint-disable-next-line import/no-mutable-exports
let apiAxios = decorateAxiosForApi({ apiBaseUrl: process.env.API_BASE_URL });
const initAxios = ({ overwriteIdToken }: any = {}) => {
  apiAxios = decorateAxiosForApi({
    apiBaseUrl: process.env.API_BASE_URL,
    overwriteIdToken
  });
};

export const concatApiQuery = (api: string, data: any) => {
  if (data) {
    const keys = Object.keys(data);
    let firstParam = 0;
    for (let i = 0; i < keys.length; i++) {
      if (data[keys[i]] !== undefined) {
        if (firstParam === 0) {
          api = api + "?";
          firstParam++;
        } else {
          api = api + "&";
        }
        api = api + `${keys[i]}=${data[keys[i]]}`;
      }
    }
  }
  return api;
};

export const responseDataHandler = (data: any) => {
  if (data?.statusCode >= 200 || data?.status < 300) {
    return data?.data;
  } else {
    if (data?.message) throw new Error(data?.message);

    throw new Error("Unknown error!");
  }
};

export const callApi = (
  api: string,
  { method = "GET", data, headers }: { method?: any; data?: any; headers?: any } = {}
) => {
  const accountsServices = AccountsServices.getInstance();

  return apiAxios({
    url: api,
    method,
    data,
    headers: {
      "ebs-account-id": accountsServices?.currentAccountId,
      ...headers
    }
  });
};

export { apiAxios, initAxios };
