import AccountsList from "./List";
import CreateAccount from "./Create";
import ChangePassword from "../registration/ChangePassword";
import AmbassadorProgram from "../registration/AmbassadorProgram";
const accountListRouter = [
  { path: "/accounts", element: <AccountsList /> },
  { path: "/accounts/create", element: <CreateAccount /> },
  { path: "/change-password", element: <ChangePassword /> },
  { path: "/ambassador-program", element: <AmbassadorProgram /> }
];

export default accountListRouter;
