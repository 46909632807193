import React, { useState, useEffect } from "react";
import moment from "moment";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import ReactECharts from "echarts-for-react";
import { AccountsServices } from "../../services";
import { showError } from "../utlis/messages";
import { getBillingResources } from "../../apis/accountApis";

export default function BillingApiUsageChart() {
  const { RangePicker } = DatePicker;
  const rangePresets: {
    label: string;
    value: [Dayjs, Dayjs];
  }[] = [
    { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
    { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
    { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
    { label: "Last 180 Days", value: [dayjs().add(-180, "d"), dayjs()] }
  ];
  const [loading, setLoading] = useState(true);
  const accountsServices = AccountsServices.getInstance();
  const [availableResources, setAvailableResources] = useState([
    "RPC_API_POLYGON_MAINNET",
    "RPC_API_POLYGON_TESTNET",
    "RPC_API_ETHEREUM_MAINNET",
    "RPC_API_ETHEREUM_TESTNET"
  ]);
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [options, setOptions] = useState({});

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    fetchResourceUsage();
  }, [dateStart, dateEnd]);

  const init = async () => {
    // fetch available resources
    try {
      const result = await getBillingResources();
      setAvailableResources(result.filter((r) => r.indexOf("TOKEN_FORWARDING") == -1));
    } catch (error: any) {
      showError(error);
    }
    setDateStart(moment().subtract(7, "days").format("YYYY-MM-DD"));
    setDateEnd(moment().format("YYYY-MM-DD"));
    setLoading(false);
  };

  const fetchResourceUsage = async () => {
    try {
      if (!dateStart || dateStart === "" || !dateEnd || dateEnd === "") {
        return;
      }
      const result = await accountsServices.getAccountResourceUsage({
        accountId: accountsServices.currentAccountId,
        dateStart,
        dateEnd,
        resourceNameIn: JSON.stringify(availableResources),
        limit: 100,
        offset: 0,
        order: "asc"
      });

      // reformat data
      const rawDate = result.accountResourceUsage;
      const totalCount = result.totalCount;
      while (rawDate.length < totalCount) {
        const result = await accountsServices.getAccountResourceUsage({
          accountId: accountsServices.currentAccountId,
          dateStart,
          dateEnd,
          resourceNameIn: JSON.stringify(availableResources),
          limit: 100,
          offset: rawDate.length,
          order: "asc"
        });
        rawDate.push(...result.accountResourceUsage);
      }
      const data = {};
      availableResources.forEach((resourceName) => {
        data[resourceName] = {};
      });

      rawDate.forEach((item) => {
        const { resourceName, date, usage } = item;
        data[resourceName][date] = usage;
      });

      const dateFormat = moment(dateStart).isSame(dateEnd, "year") ? "MMM. DD" : "MMM. DD, YYYY";

      for (const resourceName in data) {
        const usage = data[resourceName];
        data[resourceName] = [];
        for (var cur = moment(dateStart); cur.isSameOrBefore(moment(dateEnd)); cur.add(1, "days")) {
          data[resourceName].push([cur.format(dateFormat), usage[cur.format("YYYY-MM-DD")] ?? 0]);
        }
      }
      setOptions(generateOptions(data));
    } catch (error: any) {
      showError(error);
    }
  };

  const onRangeChange = (dates, dateStrings) => {
    if (dateStrings) {
      setDateStart(dateStrings[0]);
      setDateEnd(dateStrings[1]);
    }
  };

  const generateOptions = (accountResourceUsage) => {
    return {
      tooltip: {
        trigger: "axis",
        textStyle: {
          align: "left"
        }
      },
      legend: {
        data: Object.keys(accountResourceUsage).map((resourceName) => resourceName.replace(/_/g, " "))
      },
      xAxis: {
        type: "category",
        boundaryGap: false
      },
      yAxis: {
        type: "value"
      },
      series: Object.keys(accountResourceUsage).map((resourceName) => ({
        name: resourceName.replace(/_/g, " "),
        type: "line",
        stack: "usage",
        areaStyle: { normal: {} },
        data: accountResourceUsage[resourceName]
      })),
      dataZoom: [
        {
          type: "inside",
          xAxisIndex: 0,
          start: 0,
          end: 100
        },
        { start: 0, end: 100 }
      ]
    };
  };

  return (
    <div
      style={{
        margin: "60px auto",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 10
      }}
    >
      <p
        style={{
          fontSize: 20,
          fontWeight: 600,
          textAlign: "left"
        }}
      >
        Service Usage
      </p>
      <div
        style={{
          width: "90%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-start",
          gap: 10
        }}
      >
        {!loading && (
          <RangePicker
            onChange={onRangeChange}
            presets={rangePresets}
            defaultValue={[dayjs(dateStart, "YYYY-MM-DD"), dayjs(dateEnd, "YYYY-MM-DD")]}
          />
        )}
      </div>
      {!loading && <ReactECharts notMerge option={options} style={{ width: "100%", padding: 0 }} />}
    </div>
  );
}
