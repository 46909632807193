import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Form,
  Input,
  Modal,
  Spin,
  Tabs,
  Table,
  Tag,
  TableColumnsType,
  Badge,
  Dropdown,
  InputNumber,
  Switch,
  Pagination,
  Drawer
} from "antd";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { boundError } from "../../../utlis/boundError";
import { useSelector } from "react-redux";
import { Button, Space, Divider } from "antd";
import { PlusOutlined, DownloadOutlined, LinkOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { showError, showSuccess, showWarning } from "../../../utlis/messages";
import { AccountsServices } from "../../../../services";
import moment from "moment";
import { PaymentService } from "../../../../services/PaymentServices";
import { Buffer } from "buffer";
import { AccountTransaction } from "../../../../constants/types";
import {
  doDeleteGasFeeFundingWallet,
  doDeleteTokenForwardingWallet,
  doRefreshGasFeeFundingWalletBalance,
  doUpdateGasFeeFundingWalletName
} from "../../../../apis/tokenForwardingApis";
import { DrawerDetailRow } from "../../../molecules/DrawerDetailRow";

interface DataType {
  // id: string;
  // currency: string;
  // address: string;
  // updatedAt: string;
  [x: string]: any;
}

const GasFeeFundingWalletTableRaw = ({ data, refreshPage, navigatePage }) => {
  const isAuthenticated = useSelector((state) => state);
  const accountsServices = AccountsServices.getInstance();
  const paymentService = PaymentService.getInstance();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [defaultCard, setDefaultCard] = useState();
  const [typeFilter, setTypeFilter] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState<any>();
  const [deleteModal, setDeleteModal] = useState(false);
  const [showUpdateNameModal, setShowUpdateNameModal] = useState(false);
  const [walletName, setWalletName] = useState("");

  useEffect(() => {}, []);

  const refreshBalance = async () => {
    try {
      setLoading(true);
      if (!selectedWallet) {
        return showError("Please select a wallet.");
      }
      await doRefreshGasFeeFundingWalletBalance(selectedWallet.id);
      setOpenDrawer(false);
      refreshPage();
      showSuccess("Balance has been refreshed.");
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateName = async () => {
    try {
      setLoading(true);
      if (!selectedWallet) {
        return showError("Please select a wallet.");
      }
      await doUpdateGasFeeFundingWalletName(selectedWallet.id, walletName);
      setOpenDrawer(false);
      setShowUpdateNameModal(false);
      refreshPage();
      showSuccess("Wallet name has been updated.");
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteWallet = async () => {
    try {
      setLoading(true);
      if (!selectedWallet) {
        return showError("Please select a wallet.");
      }
      await doDeleteGasFeeFundingWallet(selectedWallet.id);
      drawerOnClose();
      refreshPage();
      showSuccess("Wallet has been deleted");
    } catch (error: any) {
      showError(error);
    } finally {
      setDeleteModal(false);
      setLoading(false);
    }
  };

  const drawerOnClose = () => {
    setOpenDrawer(false);
  };

  const rowOnClick = (record) => {
    setOpenDrawer(true);
    setSelectedWallet(record);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Network",
      dataIndex: "network",
      key: "network",
      render: (_, record) => <div>{(record.network || "").replace("_", " ")}</div>
    },
    {
      title: "Address",
      dataIndex: "publicAddress",
      key: "publicAddress"
    },
    {
      title: "Current Balance",
      dataIndex: "balance",
      key: "balance"
    },
    {
      title: "Balance Last Checked At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
      render: (_, record) => <div>{moment(record.updatedAt).format("YYYY-MM-DD HH:mm:ss")}</div>
    }
  ];

  return (
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        rowClassName="pointer-cursor"
        rowKey="id"
        onRow={(record, rowIndex) => {
          return {
            onClick: () => rowOnClick(record)
          };
        }}
      />
      <Pagination
        style={{ textAlign: "right", marginTop: 10 }}
        // size="small"
        pageSize={10}
        defaultCurrent={1}
        total={data?.totalCount}
        showSizeChanger={false}
        // onChange={navigatePage}
        responsive={true}
      />
      <Drawer
        title="Wallet Details"
        placement="right"
        onClose={drawerOnClose}
        open={openDrawer}
        style={{ marginTop: 64 }}
        size="large"
      >
        <DrawerDetailRow title="ID" description={selectedWallet?.id} />
        <DrawerDetailRow title="Name" description={selectedWallet?.name} />
        <DrawerDetailRow
          title="Address"
          description={selectedWallet?.publicAddress}
          linkable={true}
          linkType="address"
          network={selectedWallet?.network}
        />
        <DrawerDetailRow title="Network" description={(selectedWallet?.network || "").replace("_", " ")} />
        <DrawerDetailRow title="Token" description={selectedWallet?.token} />
        <DrawerDetailRow title="Balance" description={selectedWallet?.balance} />
        <DrawerDetailRow
          title="Balance Last Checked At"
          description={moment(selectedWallet?.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
        />
        <div style={{ textAlign: "right", marginBottom: 5 }}>
          <Button type="primary" shape="round" size={"middle"} onClick={() => setShowUpdateNameModal(true)}>
            Update Name
          </Button>
        </div>
        <div style={{ textAlign: "right", marginBottom: 5 }}>
          <Button type="primary" shape="round" size={"middle"} onClick={refreshBalance}>
            Refresh Balance
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            fontSize: 16,
            marginBottom: 10
          }}
        >
          <Button type="primary" danger onClick={() => setDeleteModal(true)}>
            Delete Wallet
          </Button>
        </div>
      </Drawer>
      <Modal
        title={`Deleting Wallet ${selectedWallet?.id}`}
        open={deleteModal}
        onCancel={() => {
          setDeleteModal(false);
        }}
        onOk={deleteWallet}
        okText="Delete"
        cancelText="Cancel"
        centered
        okType="danger"
      >
        <p>Wallet {selectedWallet?.id} will be permanently deleted, related services may be effected</p>
      </Modal>
      <Modal
        title="Edit Wallet Name"
        open={showUpdateNameModal}
        onCancel={() => {
          setShowUpdateNameModal(false);
          setWalletName("");
        }}
        onOk={updateName}
        okText="Update"
        cancelText="Cancel"
      >
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 20 }}>
          <span>Account Name:</span>
          <Input
            style={{ width: "50%", marginLeft: 10 }}
            value={walletName || selectedWallet?.name}
            onChange={(e) => setWalletName(e.target.value)}
          />
        </div>
      </Modal>
    </Spin>
  );
};

const GasFeeFundingWalletTable = boundError(GasFeeFundingWalletTableRaw);
export default GasFeeFundingWalletTable;
