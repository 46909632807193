import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import SignUpVerification from "./SignUpVerification";

const registrationRouter = [
  { path: "/login", element: <Login /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "/sign-up", element: <SignUpVerification /> }
];

export default registrationRouter;
