// @ts-nocheck
import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Input, Space } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { boundError } from "../../utlis/boundError";
import { showError, showSuccess, showWarning } from "../../utlis/messages";
import { AccountsServices } from "../../../services";
import NavigationBar from "../../molecules/NavigationBar";
import { ButtonPrimary } from "../../atoms/ButtonPrimary";
import { submitSupportTicket } from "../../../apis/accountApis";

const ContactUsRaw = () => {
  const accountsServices = AccountsServices.getInstance();
  const accountName = accountsServices.getCurrentAccount()!.account?.name;
  const accountId = accountsServices.getCurrentAccount()!.account?.id;
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    const { message } = values;

    if (!message || message.length < 10) {
      showError("Your message is too short, please describe the issue in more detail.");
      return false;
    }

    try {
      await submitSupportTicket(message);
      form.resetFields();
      showSuccess("We received your message, we will get back to you soon.");
    } catch (err: any) {
      showError("Failed to submit, please try again later.");
    }
  };

  return (
    <div className="main-container center-container">
      <NavigationBar currentPage="Contact Us" />
      <Space direction="vertical" size={20}>
        <p
          style={{
            fontSize: 20,
            fontWeight: 600,
            textAlign: "left"
          }}
        >
          Contact Us
        </p>

        <Form
          style={{ padding: "10px", maxWidth: 600 }}
          onFinish={onSubmit}
          layout="vertical"
          labelAlign="left"
          form={form}
          name="contact-us-form"
          id="contact-us-form"
        >
          <Form.Item label="Account" name="account">
            <Input allowClear id="accountId" autoFocus disabled defaultValue={`${accountName}(${accountId})`} />
          </Form.Item>
          <Form.Item label="Message" name="message">
            <Input.TextArea allowClear id="message" style={{ minHeight: 200 }} />
          </Form.Item>
          <ButtonPrimary htmlType="submit" type="primary" size={"medium"} block style={{ width: 100 }}>
            Submit
          </ButtonPrimary>
          <Form.Item></Form.Item>
        </Form>
      </Space>
    </div>
  );
};

const ContactUs = boundError(ContactUsRaw);
export default ContactUs;
