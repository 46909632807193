import { Breadcrumb } from "antd";
import { boundError } from "../utlis";
import { AccountsServices } from "../../services";
import { Link } from "react-router-dom";
import { VALID_ROUTES } from "../router";

const NavigationBarRaw = ({
  nonClickableSubItem,
  items,
  currentPage,
  subItems
}: {
  nonClickableSubItem?: string;
  currentPage?: string;
  items: string[];
  subItems: string[];
}) => {
  const accountsServices = AccountsServices.getInstance();

  function firstLower(string) {
    const text = string.replaceAll(" ", "");
    return (text && text[0].toLowerCase() + text.slice(1)) || text;
  }

  return (
    <Breadcrumb style={{ marginBottom: 20 }}>
      <Breadcrumb.Item>
        <Link to={`/account/${accountsServices.getCurrentAccount()!.account?.id}/overview`}>
          {accountsServices.currentAccountName}
        </Link>
      </Breadcrumb.Item>
      {nonClickableSubItem && <Breadcrumb.Item>{nonClickableSubItem}</Breadcrumb.Item>}
      {(items || [])
        .filter((item) => VALID_ROUTES.indexOf(firstLower(item)) > -1)
        .map((item) => (
          <Breadcrumb.Item>
            <Link
              to={`/account/${accountsServices.getCurrentAccount()!.account?.id}${
                nonClickableSubItem ? `/${firstLower(nonClickableSubItem)}/` : "/"
              }${firstLower(item)}`}
            >
              {item}
            </Link>
          </Breadcrumb.Item>
        ))}
      {(subItems || []).map((item) => (
        <Breadcrumb.Item>{item}</Breadcrumb.Item>
      ))}
      {currentPage && <Breadcrumb.Item>{currentPage}</Breadcrumb.Item>}
    </Breadcrumb>
  );
};

const NavigationBar = boundError(NavigationBarRaw);
export default NavigationBar;
