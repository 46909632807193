import { Network } from "../../constants/types";

export const first4Last4 = (address: string) => {
  if (!address) return "";
  const first4 = address.slice(0, 4);
  const last4 = address.slice(address.length - 4, address.length);
  return `${first4}...${last4}`;
};

export const getAddressPageByNetwork = (network: string, address: string) => {
  let url = "";
  switch (network) {
    case Network.TokenForwardingWallet.Name.ETHEREUM_MAINNET:
      url = `https://etherscan.io/address/${address}`;
      break;
    case Network.TokenForwardingWallet.Name.ETHEREUM_SEPOLIA:
      url = `https://sepolia.etherscan.io/address/${address}`;
      break;
    case Network.TokenForwardingWallet.Name.BSC_MAINNET:
      url = `https://bscscan.com/address/${address}`;
      break;
    case Network.TokenForwardingWallet.Name.BSC_TESTNET:
      url = `https://testnet.bscscan.com/address/${address}`;
      break;
    case Network.TokenForwardingWallet.Name.POLYGON_MAINNET:
      url = `https://polygonscan.com/address/${address}`;
      break;
    case Network.TokenForwardingWallet.Name.POLYGON_AMOY:
      url = `https://amoy.polygonscan.com/address/${address}`;
      break;
    case Network.TokenForwardingWallet.Name.AURORA_MAINNET:
      url = `https://explorer.mainnet.aurora.dev/address/${address}`;
      break;
    case Network.TokenForwardingWallet.Name.AURORA_TESTNET:
      url = `https://explorer.testnet.aurora.dev/address/${address}`;
      break;
    default:
      url = "";
  }
  return url;
};

export const getAddressTrxByHash = (network: string, transactionHash: string) => {
  let url = "";
  switch (network) {
    case Network.TokenForwardingWallet.Name.ETHEREUM_MAINNET:
      url = `https://etherscan.io/tx/${transactionHash}`;
      break;
    case Network.TokenForwardingWallet.Name.ETHEREUM_SEPOLIA:
      url = `https://sepolia.etherscan.io/tx/${transactionHash}`;
      break;
    case Network.TokenForwardingWallet.Name.BSC_MAINNET:
      url = `https://bscscan.com/tx/${transactionHash}`;
      break;
    case Network.TokenForwardingWallet.Name.BSC_TESTNET:
      url = `https://testnet.bscscan.com/tx/${transactionHash}`;
      break;
    case Network.TokenForwardingWallet.Name.POLYGON_MAINNET:
      url = `https://polygonscan.com/tx/${transactionHash}`;
      break;
    case Network.TokenForwardingWallet.Name.POLYGON_AMOY:
      url = `https://amoy.polygonscan.com/tx/${transactionHash}`;
      break;
    case Network.TokenForwardingWallet.Name.AURORA_MAINNET:
      url = `https://explorer.mainnet.aurora.dev/tx/${transactionHash}`;
      break;
    case Network.TokenForwardingWallet.Name.AURORA_TESTNET:
      url = `https://explorer.testnet.aurora.dev/tx/${transactionHash}`;
      break;
    default:
      url = "";
  }
  return url;
};
