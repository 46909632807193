// @ts-nocheck
import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Form,
  Input,
  Modal,
  Spin,
  Tabs,
  Table,
  Tag,
  TableColumnsType,
  Badge,
  Dropdown,
  InputNumber,
  Switch
} from "antd";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { boundError } from "../../utlis/boundError";
import { useSelector } from "react-redux";
import { Button, Space, Divider, Card } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { showError, showSuccess, showWarning } from "../../utlis/messages";
import { AccountsServices, UserServices } from "../../../services";
import { ButtonPrimary } from "../../atoms/ButtonPrimary";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import { DownOutlined, EditOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons";
import amex from "../../../assets/cardTypes/amex.svg";
import jcb from "../../../assets/cardTypes/jcb.svg";
import mastercard from "../../../assets/cardTypes/mastercard.svg";
import unionpay from "../../../assets/cardTypes/unionpay.svg";
import visa from "../../../assets/cardTypes/visa.svg";
import moment from "moment";
import { PaymentService } from "../../../services/PaymentServices";
import AddCardModal from "../../molecules/AddCardModal";
import EditCardModal from "../../molecules/EditCardModal";
import { BrandIcon } from "../../atoms/BrandIcon";
import Big from "big.js";
import NavigationBar from "../../molecules/NavigationBar";

interface DataType {
  [x: string]: any;
  id: string;
  brand: string;
  exp_month: number;
  exp_year: number;
  last4: string;
  key: number;
}
interface IStripeInfo {
  client_secret: string;
  customerId: string;
  setupIntentId: string;
  accountId: string;
}

const DepositSectionRaw = () => {
  const isAuthenticated = useSelector((state) => state);
  const accountsServices = AccountsServices.getInstance();
  const paymentService = PaymentService.getInstance();
  const userServices = UserServices.getInstance();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [defaultCard, setDefaultCard] = useState();
  const [autoDepositOn, setAutoDepositOn] = useState(
    accountsServices.getCurrentAccount()?.account?.setting?.autoDeposit?.autoDepositEnabled
  );
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);
  const location = useLocation();
  const [globalSettings, setGlobalSettings] = useState();
  const [amount, setAmount] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [cards, setCards] = useState<any>();
  const [minBalance, setMinBalance] = useState(
    accountsServices.getCurrentAccount()?.account?.setting?.autoDeposit?.minBalance || 1
  );
  const [autoDepositAmount, setAutoDepositAmount] = useState(
    accountsServices.getCurrentAccount()?.account?.setting?.autoDeposit?.autoDepositAmount || 0
  );
  const [stripeInfo, setStripeInfo] = useState<IStripeInfo>({
    client_secret: "",
    customerId: "",
    setupIntentId: "",
    accountId: ""
  });
  const [showRedirectAddPaymentCardPop, setShowRedirectAddPaymentCardPop] = useState(false);

  useEffect(() => {
    fetchSettings();
    fetchAccountDetails();
  }, [refresh]);

  const fetchAccountDetails = async () => {
    try {
      setLoading(true);
      await accountsServices.assembleAccountByIdByFetchingRemotely({
        accountId: accountsServices.currentAccountId,
        setAsCurrentAccount: true
      });
      const account = accountsServices.getCurrentAccount();
      let cards: DataType[] = [];
      let key = 0;
      (account?.cards || []).forEach((card) =>
        cards.push({
          ...card,
          key: ++key
        })
      );
      setCards(cards);
      if (cards.length == 0) {
        setShowRedirectAddPaymentCardPop(true);
      }
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSettings = async () => {
    try {
      setLoading(true);
      const depositSettings = await userServices.getSettings("DEPOSIT");
      setGlobalSettings(depositSettings);
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const openDepositModal = () => {
    if (amount === 0) {
      return showError("Deposit amount is required.");
    }
    const defaultPayment = cards.filter((card) => card.defaultPaymentMethod);
    if (!defaultPayment.length) {
      return showWarning("Please set one payment card as default.");
    }
    setDefaultCard(defaultPayment[0]);
    setShowDepositModal(true);
  };

  const enableAutoPay = async (enabled) => {
    try {
      setLoading(true);
      if (!minBalance || !autoDepositAmount) {
        return showError("Deposit amount is required.");
      }
      await accountsServices.updateAccountSettings("autoDeposit", {
        // minBalance: new Big(minBalance),
        // autoDepositAmount: new Big(autoDepositAmount),
        autoDepositEnabled: enabled
      });
      setRefresh(!refresh);
      if (enabled) {
        setAutoDepositOn(true);
        showSuccess("Thanks for enabling auto pay.");
      } else {
        setAutoDepositOn(false);
        showSuccess(`Your auto pay has been turned off.`);
      }
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const confirmAutoPay = async () => {
    try {
      if (!minBalance || !autoDepositAmount) {
        return showError("Deposit amount is required.");
      }
      setLoading(true);
      await accountsServices.updateAccountSettings("autoDeposit", {
        minBalance: new Big(minBalance),
        autoDepositAmount: new Big(autoDepositAmount)
        // autoDepositEnabled: autoDepositOn
      });
      showSuccess(`Your auto pay setting has been saved.`);
      setRefresh(!refresh);
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const deposit = async () => {
    try {
      setLoading(true);
      await paymentService.depositAmount(defaultCard.id, amount);
      showSuccess(`Your payment is being processed. Thanks for using EZAT service!`);
      setShowDepositModal(false);
    } catch (error: any) {
      showError(error);
      setShowDepositModal(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="main-container center-container">
        <NavigationBar nonClickableSubItem="Billings" currentPage="Buy Credits" />
        <Space direction="vertical" size={50}>
          <Card
            title={<div style={{ display: "flex", alignItems: "center" }}>Make One Time Pay</div>}
            style={{ width: 628, height: 205 }}
            bodyStyle={{ textAlign: "right" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                width: "70%"
              }}
            >
              <p className="margin-none">Deposit Amount:</p>
              <InputNumber
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                // defaultValue={globalSettings?.settings?.minAmount || 1}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                onChange={setAmount}
                prefix={"USD"}
                style={{
                  width: 145,
                  marginLeft: 5,
                  borderRadius: 2
                }}
                min={globalSettings?.settings?.minAmount || 1}
                max={99999}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 20,
                justifyContent: "end",
                width: "70%"
              }}
            >
              <ButtonPrimary
                type="primary"
                size={"medium"}
                block
                onClick={openDepositModal}
                style={{ borderRadius: 2, width: 145 }}
              >
                Confirm Payment
              </ButtonPrimary>
            </div>
          </Card>
          <Card
            title={<div style={{ display: "flex", alignItems: "center" }}>Auto Pay</div>}
            style={{ width: 628 }}
            bodyStyle={{ textAlign: "right" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                width: "70%"
              }}
            >
              <p className="margin-none">Enable Auto Pay:</p>
              <div style={{ width: 145, textAlign: "left", marginLeft: 10 }}>
                <Switch checked={autoDepositOn} onChange={(e) => enableAutoPay(e)} />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                width: "70%",
                marginTop: 20
              }}
            >
              <p className="margin-none">Deposit When My Credit is Below:</p>
              <InputNumber
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                defaultValue={minBalance}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                onChange={setMinBalance}
                min={1}
                max={99999}
                prefix={"USD"}
                style={{
                  borderRadius: 2,
                  width: 145,
                  marginLeft: 10
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                width: "70%",
                marginTop: 20
              }}
            >
              <p className="margin-none">Deposit Amount:</p>
              <InputNumber
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                defaultValue={autoDepositAmount || globalSettings?.settings?.minAmount}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                onChange={setAutoDepositAmount}
                min={globalSettings?.settings?.minAmount || 1}
                max={99999}
                prefix={"USD"}
                style={{
                  borderRadius: 2,
                  width: 145,
                  marginLeft: 10
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                width: "70%",
                color: "grey"
              }}
            >
              Minimum: ${globalSettings?.settings?.minAmount}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 20,
                justifyContent: "end",
                width: "70%"
              }}
            >
              <ButtonPrimary
                type="primary"
                size={"medium"}
                block
                onClick={confirmAutoPay}
                style={{ borderRadius: 2, width: 145 }}
              >
                Save Auto Pay
              </ButtonPrimary>
            </div>
          </Card>
        </Space>
      </div>

      {/* MODAL SECTION */}
      <Modal
        title={`$${amount} USD will be deposited from the following card:`}
        open={showDepositModal}
        onCancel={() => setShowDepositModal(false)}
        onOk={() => deposit()}
        okText="Deposit"
        cancelText="Cancel"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <div>
            <BrandIcon brand={defaultCard?.card?.brand} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 10
            }}
          >
            <div
              style={{
                fontWeight: 500
              }}
            >
              {defaultCard?.card?.brand.toUpperCase()} •••• {defaultCard?.card?.last4}{" "}
              {defaultCard?.defaultPaymentMethod === true ? (
                <span
                  style={{
                    padding: "1px 6px",
                    backgroundColor: "rgb(164 222 255)",
                    borderRadius: 10
                  }}
                >
                  Default
                </span>
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                color: "#687385"
              }}
            >
              Expires{" "}
              {moment()
                .month(defaultCard?.card?.exp_month - 1)
                .format("MMMM")}{" "}
              {defaultCard?.card.exp_year}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title={"This account does not have a payment card."}
        centered
        open={showRedirectAddPaymentCardPop}
        onOk={() => navigate(`/account/${accountsServices.getCurrentAccount()!.account?.id}/billings/addPaymentCard`)}
        okText="Add"
        closable={false}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <p>Click Add button to add a payment card.</p>
      </Modal>
    </Spin>
  );
};

const DepositSection = boundError(DepositSectionRaw);
export default DepositSection;
