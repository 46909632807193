import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Form,
  Input,
  Modal,
  Spin,
  Tabs,
  Table,
  Tag,
  TableColumnsType,
  Badge,
  Dropdown,
  InputNumber,
  Switch,
  Pagination,
  Drawer
} from "antd";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { boundError } from "../../../utlis/boundError";
import { useSelector } from "react-redux";
import { Button, Space, Divider } from "antd";
import { PlusOutlined, DownloadOutlined, LinkOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { showError, showSuccess, showWarning } from "../../../utlis/messages";
import { AccountsServices } from "../../../../services";
import moment from "moment";
import { PaymentService } from "../../../../services/PaymentServices";
import { Buffer } from "buffer";
import { AccountTransaction } from "../../../../constants/types";
import { doDeleteTokenForwardingWallet, doUpdateForwardingWalletStatus } from "../../../../apis/tokenForwardingApis";
import { first4Last4 } from "../../../utlis/helpers";
import { DrawerDetailRow } from "../../../molecules/DrawerDetailRow";

interface DataType {
  // id: string;
  // currency: string;
  // address: string;
  // updatedAt: string;
  [x: string]: any;
}

const TokenForwardingWalletTableRaw = ({ data, refreshPage, navigatePage }) => {
  const isAuthenticated = useSelector((state) => state);
  const accountsServices = AccountsServices.getInstance();
  const paymentService = PaymentService.getInstance();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [defaultCard, setDefaultCard] = useState();
  const [typeFilter, setTypeFilter] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState<any>();
  const [deleteModal, setDeleteModal] = useState(false);
  const [disableModal, setDisableModal] = useState(false);
  const [disableReason, setDisableReason] = useState("");

  useEffect(() => {}, []);

  const deleteWallet = async () => {
    try {
      setLoading(true);
      if (!selectedWallet) {
        return showError("Please select a wallet.");
      }
      await doDeleteTokenForwardingWallet(selectedWallet.id);
      drawerOnClose();
      refreshPage();
      showSuccess("Wallet has been deleted");
    } catch (error: any) {
      showError(error);
    } finally {
      setDeleteModal(false);
      setLoading(false);
    }
  };

  const disableWallet = async () => {
    try {
      setLoading(true);
      if (!selectedWallet) {
        return showError("Please select a wallet.");
      }
      await doUpdateForwardingWalletStatus(selectedWallet.id, "DISABLED", disableReason);
      drawerOnClose();
      refreshPage();
      showSuccess("Wallet has been disabled");
    } catch (error: any) {
      showError(error);
    } finally {
      setDisableModal(false);
      setDisableReason("");
      setLoading(false);
    }
  };

  const enableWallet = async () => {
    try {
      setLoading(true);
      if (!selectedWallet) {
        return showError("Please select a wallet.");
      }
      await doUpdateForwardingWalletStatus(selectedWallet.id, "ENABLED");
      drawerOnClose();
      refreshPage();
      showSuccess("Wallet has been enabled");
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const drawerOnClose = () => {
    setOpenDrawer(false);
  };

  const rowOnClick = (record) => {
    setOpenDrawer(true);
    setSelectedWallet(record);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Token",
      dataIndex: "token",
      key: "token"
    },
    {
      title: "Address",
      dataIndex: "publicAddress",
      key: "publicAddress"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <span
          style={{
            padding: "1px 8px",
            backgroundColor: record.status === "ENABLED" ? "rgba(246, 255, 237, 1)" : "rgba(244, 244, 244, 1)",
            borderRadius: 2,
            color: record.status === "ENABLED" ? "rgba(82, 196, 26, 1)" : "rgba(93, 96, 92, 1)",
            border: record.status === "ENABLED" ? "1px solid #B7EB8F" : "1px solid rgba(217, 217, 217, 1)"
          }}
        >
          {record.status}
        </span>
      )
    },
    {
      title: "Balance Last Checked At",
      dataIndex: "balanceLastCheckedAt",
      key: "balanceLastCheckedAt",
      sorter: (a, b) => moment(a.balanceLastCheckedAt).unix() - moment(b.balanceLastCheckedAt).unix(),
      render: (_, record) => <div>{moment(record.balanceLastCheckedAt).format("YYYY-MM-DD HH:mm:ss")}</div>
    }
  ];

  return (
    <Spin spinning={loading}>
      <Table
        rowClassName="pointer-cursor"
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey="id"
        onRow={(record, rowIndex) => {
          return {
            onClick: () => rowOnClick(record)
          };
        }}
      />
      <Pagination
        style={{ textAlign: "right", marginTop: 10 }}
        // size="small"
        pageSize={10}
        defaultCurrent={1}
        total={data?.totalCount}
        showSizeChanger={false}
        // onChange={navigatePage}
        responsive={true}
      />
      <Drawer
        title="Wallet Details"
        placement="right"
        onClose={drawerOnClose}
        open={openDrawer}
        style={{ marginTop: 64 }}
        size="large"
      >
        <DrawerDetailRow title="ID" description={selectedWallet?.id} />
        <DrawerDetailRow
          title="Address"
          description={selectedWallet?.publicAddress}
          linkable={true}
          linkType="address"
          network={selectedWallet?.network}
        />
        <DrawerDetailRow title="Network" description={(selectedWallet?.network || "").replace("_", " ")} />
        <DrawerDetailRow title="Token" description={selectedWallet?.token} />
        <DrawerDetailRow title="Minimum Forwarding Amount" description={selectedWallet?.minimumForwardingAmount} />
        <DrawerDetailRow
          title="Destination Public Address"
          description={selectedWallet?.destinationPublicAddress}
          linkable={true}
          linkType="address"
          network={selectedWallet?.network}
        />
        <DrawerDetailRow title="Gas Fee Funding Wallet ID" description={selectedWallet?.gasFeeFundingWalletId} />
        <DrawerDetailRow title="Webhook URL" description={selectedWallet?.webhookSetting?.url} />
        <DrawerDetailRow title="Webhook Header" description={selectedWallet?.webhookSetting?.header} />
        <DrawerDetailRow title="Webhook Header Value" description={selectedWallet?.webhookSetting?.headerValue} />
        <DrawerDetailRow
          title="Balance Last Checked At"
          description={moment(selectedWallet?.balanceLastCheckedAt).format("YYYY-MM-DD HH:mm:ss")}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: 16,
            marginBottom: 10
          }}
        >
          <div style={{ display: "flex", alignItems: "center", color: "rgba(0, 0, 0, 0.46)" }}>Transactions</div>
          <div style={{ maxWidth: 410, textAlign: "right" }}>
            <Button
              type="primary"
              shape="round"
              size={"middle"}
              onClick={() =>
                navigate(
                  `/account/${
                    accountsServices.getCurrentAccount()!.account?.id
                  }/services/token-forwarding/transactions`,
                  {
                    state: {
                      walletId: selectedWallet?.id
                    }
                  }
                )
              }
            >
              View Transactions
            </Button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            fontSize: 16,
            marginBottom: 10
          }}
        >
          {selectedWallet?.status !== "ENABLED" && (
            <Button type="primary" style={{ marginRight: 5 }} onClick={enableWallet}>
              Enable Wallet
            </Button>
          )}
          {selectedWallet?.status !== "DISABLED" && (
            <Button type="primary" style={{ marginRight: 5 }} ghost danger onClick={() => setDisableModal(true)}>
              Disable Wallet
            </Button>
          )}
          <Button type="primary" danger onClick={() => setDeleteModal(true)}>
            Delete Wallet
          </Button>
        </div>
      </Drawer>
      <Modal
        title={`Deleting Wallet ${selectedWallet?.id}`}
        open={deleteModal}
        onCancel={() => {
          setDeleteModal(false);
        }}
        onOk={deleteWallet}
        okText="Delete"
        cancelText="Cancel"
        centered
        okType="danger"
      >
        <p>Wallet {selectedWallet?.id} will be permanently deleted, related services may be effected</p>
      </Modal>
      <Modal
        title={`Disabling Wallet ${selectedWallet?.id}`}
        open={disableModal}
        onCancel={() => {
          setDisableReason("");
          setDisableModal(false);
        }}
        onOk={disableWallet}
        okText="Disable"
        okType="danger"
      >
        <p style={{ color: "rgba(0, 0, 0, 0.45)" }}>
          Please note: Wallet {selectedWallet?.id} will be disabled, related services may be effected
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
            width: "90%"
          }}
        >
          Reason:{" "}
          <Input
            style={{ width: "70%", marginLeft: 10 }}
            onChange={(e) => {
              setDisableReason(e.target.value);
            }}
          />
        </div>
      </Modal>
    </Spin>
  );
};

const TokenForwardingWalletTable = boundError(TokenForwardingWalletTableRaw);
export default TokenForwardingWalletTable;
