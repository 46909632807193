import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Input, Modal, Spin, Tabs } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { boundError } from "../../utlis/boundError";
import { loginSuccess } from "../../../redux/actions";
import { ButtonPrimary } from "../../atoms/ButtonPrimary";
import { showError, showSuccess } from "../../utlis/messages";
import { UserServices } from "../../../services";
import ReactCodeInput from "react-code-input";
import { AwsService } from "../../../services/AwsServices";
import { LOGIN_TABS } from "../../../constants/types";
import { AuthWrapper } from "./AuthWrapper";

const ResetPasswordRaw = () => {
  const userServices = UserServices.getInstance();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);
  const location = useLocation();
  const [resendCountdown, setResendCountdown] = useState(60);

  useEffect(() => {
    const tick = setInterval(() => {
      if (resendCountdown >= 0) setResendCountdown((prev) => prev - 1);
    }, 1000);

    return () => {
      clearInterval(tick);
      isMounted.current = false;
    };
  }, []);

  const validation = (values) => {
    const { password, confirmPassword } = values;

    if (!password) {
      showError("Password is required.");
      return false;
    }
    if (!confirmPassword) {
      showError("Confirm password is required.");
      return false;
    }

    if (confirmPassword !== password) {
      showError("Passwords do not match.");
      return false;
    }

    if (!verificationCode) {
      showError("Verification code is required.");
      return false;
    }
    return true;
  };

  const sendRequest = async (values) => {
    try {
      setLoading(true);
      if (!validation(values)) {
        return;
      }
      const { password } = values;
      await AwsService.getInstance().forgotPasswordSubmit({
        username: location.state.email,
        code: verificationCode,
        newPassword: password
      });
      navigate("/login");
      showSuccess("Password has been updated");
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const resendSignUpCode = async () => {
    if (resendCountdown <= 0) {
      const email = location.state.email;
      await userServices.resendSignUpCode({ email });
      setResendCountdown(60);
    }
  };

  return (
    <Spin spinning={loading}>
      <AuthWrapper>
        <div className="login-container">
          <div className="login-box">
            <div className="back-to-login" onClick={() => navigate("/login")}>
              <LeftOutlined style={{ marginRight: 10 }} />
              Back to login
            </div>
            <div
              style={{
                paddingLeft: 20,
                paddingTop: 30,
                fontSize: 30,
                fontWeight: 500
              }}
            >
              {LOGIN_TABS.RESET}
            </div>
            <div style={{ marginTop: 30, textAlign: "center" }}>
              <h5>{location.state?.email || "email"}</h5>
              <p style={{ margin: 0, textAlign: "center" }}>A reset email code has sent to your email.</p>
            </div>
            <Form
              style={{ padding: 20 }}
              onFinish={sendRequest}
              layout="vertical"
              form={form}
              name="login-form"
              id="login-form"
            >
              <Form.Item label="Password" name="password">
                <Input.Password allowClear id="password" />
              </Form.Item>
              <Form.Item label="Confirm password" name="confirmPassword">
                <Input.Password allowClear id="confirmPassword" />
              </Form.Item>
              <Form.Item label="Verification code" name="verificationCode">
                <ReactCodeInput
                  name={""}
                  inputMode={"numeric"}
                  type="number"
                  fields={6}
                  autoFocus
                  value={""}
                  onChange={setVerificationCode}
                />
                <div style={{ marginTop: 5 }}>
                  Code hasn't arrived?{" "}
                  <span
                    style={{
                      color: resendCountdown >= 0 ? "grey" : "blue",
                      fontWeight: 600,
                      cursor: "pointer"
                    }}
                    onClick={resendSignUpCode}
                  >
                    Re-send code
                    {resendCountdown >= 0 ? ` in ${resendCountdown}s` : ""}
                  </span>{" "}
                </div>
              </Form.Item>

              <ButtonPrimary htmlType="submit" type="primary" size={"medium"} block>
                Reset
              </ButtonPrimary>
            </Form>
          </div>
        </div>
      </AuthWrapper>
    </Spin>
  );
};

const ResetPassword = boundError(ResetPasswordRaw);
export default ResetPassword;
