import Router from "./components/router";
import { useEffect, useState } from "react";
import { initAxios } from "./apis/axios";
import { AwsService } from "./services/AwsServices";
import { useDispatch } from "react-redux";
import { loginSuccess } from "./redux/actions";
import { boundError } from "./components/utlis";
import { AccountsServices, UserServices } from "./services";
import { showSuccess } from "./components/utlis/messages";

function App() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    onAppLoad().then(() => setIsLoading(false));
  }, []);

  const onAppLoad = async () => {
    try {
      const account = localStorage.getItem("currentAccount");
      const userProfile = localStorage.getItem("user");
      if (account) AccountsServices.getInstance().setCurrentAccount(JSON.parse(account));
      if (userProfile) UserServices.getInstance().setUser(JSON.parse(userProfile));
      const user = await AwsService.getInstance().getCurrentAuthenticatedUser();
      dispatch(loginSuccess(user));
      // if (user.attributes.identities.includes("Google")) {
      //   showSuccess("Welcome to EZAT dashboard!");
      // }
    } catch (err) {
      console.log("No authenticated user.", err);
      localStorage.clear();
    }
  };

  if (isLoading) {
    return null;
  }

  return <Router />;
}

export default boundError(App);
