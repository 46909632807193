import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Input, Modal, Spin, Tabs, Table, Tag, Card } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { boundError } from "../../utlis/boundError";
import { useSelector } from "react-redux";
import { Button, Space } from "antd";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { showError, showSuccess } from "../../utlis/messages";
import { AccountsServices } from "../../../services";
import BillingApiUsageChart from "../../molecules/BillingApiUsageChart";
import BillingChart from "../../molecules/BillingChart";
import NavigationBar from "../../molecules/NavigationBar";
import Big from "big.js";

const AccountDetailsRaw = () => {
  const isAuthenticated = useSelector((state) => state);
  const accountsServices = AccountsServices.getInstance();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [accountName, setAccountName] = useState("");
  const [accountDetails, setAccountDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DataType[]>([]);
  const isMounted = useRef(true);
  const location = useLocation();
  const [usageSum, setUsageSum] = useState(0);
  const [billingSum, setBillingSum] = useState(0);

  interface DataType {
    id: string;
    name: string;
    roleName: string;
    status: string;
    userId: string;
    key: number;
  }

  useEffect(() => {
    fetchAccountsList();
    calculateMonthlyUsageSum();
    calculateMonthlyBillingSum();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchAccountsList = async () => {
    try {
      setLoading(true);
      // const result = await accountsServices.fetchUserAccounts();
      // console.log(result);
      // let accounts: DataType[] = [];
      // let key = 0;
      // result.accounts.forEach((account) =>
      //   accounts.push({
      //     id: "..." + account?.id?.substr(account?.id.length - 12),
      //     ...account,
      //     key: ++key,
      //   })
      // );
      // setData(accounts);
      // console.log(accounts);
    } catch (error: any) {
      showError({ error });
    } finally {
      setLoading(false);
    }
  };

  const calculateMonthlyUsageSum = async () => {
    const today = moment().format("YYYY-MM-DD");
    const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
    const result = await accountsServices.getAccountResourceUsage({
      accountId: accountsServices.currentAccountId,
      dateStart: startOfMonth,
      dateEnd: today,
      limit: 1000,
      offset: 0,
      order: "asc"
    });
    const transactions = result.accountResourceUsage;
    let sum = 0;
    transactions.forEach((item) => (sum += item.usage));
    setUsageSum(sum);
  };

  const calculateMonthlyBillingSum = async () => {
    const today = moment().format("YYYY-MM-DD");
    const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
    const result = await accountsServices.getAccountTransactions({
      typeIn: JSON.stringify(["WITHDRAW_RESOURCE_USAGE"]),
      dateStart: startOfMonth,
      dateEnd: today,
      limit: 1000,
      offset: 0
    });
    const transactions = result.transactions;
    let sum = new Big(0);
    transactions.forEach((item) => (sum = sum.plus(new Big(item.amount).abs())));
    setBillingSum(sum.toFixed(2));
  };

  const RenderPercentage = ({ percentage }) => {
    if (percentage > 0) {
      return (
        <span
          style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "rgba(32, 195, 48, 0.85)" }}
        >
          <CaretUpOutlined style={{ marginRight: 5 }} />
          {percentage}%
        </span>
      );
    } else {
      return (
        <span
          style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "rgba(195, 51, 32, 0.85)" }}
        >
          <CaretDownOutlined style={{ marginRight: 5 }} />
          {percentage}%
        </span>
      );
    }
  };

  const OverviewCards = () => {
    return (
      <>
        <p
          style={{
            fontSize: 20,
            fontWeight: 600,
            textAlign: "left"
          }}
        >
          Overview
        </p>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "5vw"
          }}
        >
          <Card style={{ flex: 1 }}>
            <p>Available Credits (USD)</p>
            <p
              style={{
                fontSize: 24
              }}
            >
              {accountsServices.currentAccountBalance}
            </p>
            {/* <RenderPercentage percentage={50} /> */}
          </Card>
          <Card style={{ flex: 1 }}>
            <p>Monthly Bill</p>
            <p
              style={{
                fontSize: 24
              }}
            >
              {billingSum}
            </p>
            {/* <RenderPercentage percentage={-30} /> */}
          </Card>
          <Card style={{ flex: 1 }}>
            <p>Monthly API Calls</p>
            <p
              style={{
                fontSize: 24
              }}
            >
              {usageSum}
            </p>
            {/* <RenderPercentage percentage={77.8} /> */}
          </Card>
        </div>
      </>
    );
  };

  return (
    <Spin spinning={loading}>
      <div className="main-container center-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%"
          }}
        >
          <NavigationBar currentPage="Account Overview" />
          <OverviewCards />
          <BillingChart />
          <BillingApiUsageChart />
        </div>
      </div>
    </Spin>
  );
};

const AccountDetails = boundError(AccountDetailsRaw);
export default AccountDetails;
