import React, { useState, useContext, useEffect } from "react";
import Icon from "@ant-design/icons";
import { boundError } from "../../utlis";
import { ExclamationCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { AccountsServices } from "../../../services";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";

const NotificationBarRaw = () => {
  const accountsServices = AccountsServices.getInstance();
  const navigate = useNavigate();
  const [showNotiBar, setShowNotiBar] = useState(false);

  useEffect(() => {
    if (!accountsServices.getCurrentAccount()!.account?.isBillingApiEnabled) {
      setShowNotiBar(true);
    }
  }, []);

  const closeNotibar = () => {
    setShowNotiBar(false);
  };

  return (
    <div className={showNotiBar ? "header-notification-container" : "hide"}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <ExclamationCircleOutlined style={{ color: "white", fontSize: 30 }} />
        <span className="header-notification-text">
          <p>{"This account has been suspended due to an insufficient balance, related services may be effected."}</p>
          <p style={{ margin: 0, display: "flex", alignItems: "center" }}>
            {"Please top up credits in order to re-activate your account."}
            <a
              style={{ color: "black", marginLeft: 10 }}
              onClick={() =>
                navigate(`/account/${accountsServices.getCurrentAccount()!.account?.id}/billings/buyCredits`)
              }
            >
              Click to deposit
            </a>
            <Tooltip title="System will take a while to re-activate your account after deposit.">
              <ExclamationCircleOutlined style={{ marginLeft: 5, color: "gray" }} />
            </Tooltip>
          </p>
        </span>
        <CloseOutlined onClick={closeNotibar} />
      </div>
    </div>
  );
};

export const NotificationBar = boundError(NotificationBarRaw);
