import { Collapse } from "antd";
import { Link } from "react-router-dom";
import { CopyButton } from "../atoms/CopyButton";
import { getAddressPageByNetwork, getAddressTrxByHash } from "../utlis/helpers";
const { Panel } = Collapse;

export const DrawerDetailRow = ({
  title,
  description,
  linkable = false,
  network,
  linkType
}: {
  title: string;
  description?: string | null;
  linkable?: boolean;
  network?: string;
  linkType?: "address" | "transaction";
}) => {
  if (description === null || description === undefined) {
    return null;
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        fontSize: 16,
        marginBottom: 10
      }}
    >
      <div style={{ display: "flex", alignItems: "center", color: "rgba(0, 0, 0, 0.46)" }}>{title}</div>
      {description.length > 160 ? (
        <Collapse ghost expandIconPosition="end" style={{ textAlign: "right" }}>
          <Panel header="View" key="1">
            <CopyButton text={description} />
            <pre
              style={{
                maxWidth: 410,
                maxHeight: 300,
                overflowY: "scroll",
                textAlign: "left",
                wordBreak: "break-all",
                marginTop: 5
              }}
            >
              {description}
            </pre>
          </Panel>
        </Collapse>
      ) : network && linkable ? (
        linkType === "address" ? (
          <Link
            to={getAddressPageByNetwork(network, description)}
            target="_blank"
            style={{ maxWidth: 410, textAlign: "right", wordBreak: "break-all" }}
          >
            {description}{" "}
          </Link>
        ) : (
          <Link
            to={getAddressTrxByHash(network, description)}
            target="_blank"
            style={{ maxWidth: 410, textAlign: "right", wordBreak: "break-all" }}
          >
            {description}
          </Link>
        )
      ) : (
        <div style={{ maxWidth: 410, textAlign: "right", wordBreak: "break-all" }}>{description}</div>
      )}
    </div>
  );
};
