import React from "react";
import { Row, Col } from "antd";
import { boundError } from "../../utlis";
import logo from "../../../assets/images/dark.svg";

const AuthWrapperRaw = ({ children }) => {
  return (
    <Row style={{ minHeight: "100vh" }}>
      <Col xs={24} sm={24} md={12} className="auth-content__container">
        {children}
      </Col>
      <Col
        xs={0}
        sm={0}
        md={12}
        justify="center"
        type="flex"
        align="middle"
        style={{
          background: "#1890ff",
          minHeight: "100vh"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            paddingBottom: 50
          }}
        >
          {/* <div className="title-text">EZAT</div> */}
          <div>
            <img style={{ width: 350 }} src={logo} />
          </div>
          <div className="subtitle-text">Blockchain Service</div>
        </div>
      </Col>
    </Row>
  );
};

export const AuthWrapper = boundError(AuthWrapperRaw);
