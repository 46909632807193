import { Amplify, Auth } from "aws-amplify";
import { awsConfig } from "../../config/aws-config";
import { AccountsServices } from "../services/AccountsServices";
import { initAxios } from "../apis/axios";
import { AwsService } from "./AwsServices";
import { doGetSettings } from "../apis/settingApis";
import { IUser } from "../models/interfaces/User";
import { doGetAmbassadorInfo, doGetUserInfo } from "../apis/userApis";
import { EventUtils, EVENT_KEYS } from "../components/utlis/events";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib-esm/types/Auth";

export class UserServices {
  static appServices: UserServices;

  email: string | undefined;
  idToken: string | undefined;
  isLogin = false;
  user: IUser | undefined;

  static getInstance() {
    if (!this.appServices) {
      this.appServices = new UserServices();
    }

    return this.appServices;
  }

  async login({ email, password }: { email: string; password: string }) {
    const user = await AwsService.getInstance().signIn(email, password);
    initAxios();
    return user;
  }

  async loginWithThirdParty(provider: CognitoHostedUIIdentityProvider) {
    const user = await AwsService.getInstance().signInWithThirdParty(provider);
    initAxios();
    return user;
  }

  async resendSignUpCode({ email }: { email: string }) {
    return await AwsService.getInstance().resendSignUpCode(email);
  }

  async signup({
    email,
    password,
    passwordConfirm,
    attributes = {}
  }: {
    email: string;
    password: string;
    passwordConfirm?: string;
    attributes?: any;
  }) {
    if (password !== passwordConfirm) {
      throw new Error("Password miss match!");
    }
    const result = await AwsService.getInstance().signUp(email, password, null, attributes);
    return result;
  }

  async getCurrentTokens() {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();
    const accessToken = session.getAccessToken().getJwtToken();
    const refreshToken = session.getRefreshToken().getToken();

    this.idToken = idToken;

    return { idToken, accessToken, refreshToken };
  }

  async signOut() {
    try {
      await Auth.signOut();
      window.open("/", "_self");
    } catch (err) {
      console.log("error signing out: ", err);
    }
  }

  async checkIsLogin() {
    const tokens = await this.getCurrentTokens();
    return tokens?.idToken !== undefined;
  }

  async getSettings(name?) {
    return await doGetSettings(name);
  }

  setUser(user: IUser | undefined) {
    this.user = user;
    EventUtils.emitEvent(EVENT_KEYS.ON_CURRENT_USER_UPDATE);
  }

  async getUserInfo() {
    const user = await doGetUserInfo();
    const ambassador = await doGetAmbassadorInfo();

    user.ambassador = ambassador;
    this.user = user;
    localStorage.setItem("user", JSON.stringify(user));
    EventUtils.emitEvent(EVENT_KEYS.ON_CURRENT_USER_UPDATE);
  }

  // fetchAccounts = async ({ offset = 0, limit = 50 } = {}) =>
  //   await AccountsServices.getInstance().fetchUserAccounts({ offset, limit })
}
