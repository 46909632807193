export const listenEvent = (eventKey: any, callback: any) => {
  document?.addEventListener(eventKey, callback);
};

export const emitEvent = (eventKey: any) => {
  if (eventKey) {
    return document?.dispatchEvent(new Event(eventKey));
  }

  return false;
};
