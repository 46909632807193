import { IAccountDetails } from "../models/interfaces/AccountDetails";
import { apiAxios, callApi, concatApiQuery, responseDataHandler } from "./axios";
import { PaginationType } from "./types";

export const doGetAccountListApi = ({ limit, offset }: PaginationType) =>
  apiAxios
    .get(concatApiQuery("/api/v1.0/account", { limit, offset }), { headers: { "ebs-account-id": "" } })
    .then(({ data }) => responseDataHandler(data));

export const doGetAccountDetailsApi = ({ accountId }: { accountId: string }): Promise<IAccountDetails> =>
  apiAxios
    .get(`/api/v1.0/account/details`, {
      headers: { "ebs-account-id": accountId }
    })
    .then(({ data }) => responseDataHandler(data));

export const doCreateAccountApi = ({
  accountName,
  stripeCustomerId
}: {
  accountName: string;
  stripeCustomerId: string;
}) =>
  apiAxios.post(`/api/v1.0/account`, { accountName, stripeCustomerId }).then(({ data }) => responseDataHandler(data));

export const doUpdateAccountNameApi = (accountId: string, accountName: string) =>
  apiAxios
    .post(
      `/api/v1.0/account/updateAccountName`,
      { accountName },
      {
        headers: { "ebs-account-id": accountId }
      }
    )
    .then(({ data }) => responseDataHandler(data));

export const doFinishCreateAccountProcess = ({ accountId, accountName, redeemWelcomeCredit, cardId }) =>
  apiAxios
    .post(
      `/api/v1.0/account/finishCreateAccountProcess`,
      { accountName, redeemWelcomeCredit, cardId },
      {
        headers: { "ebs-account-id": accountId }
      }
    )
    .then(({ data }) => responseDataHandler(data));

export const doUpdateAccountSettingsApi = (settingName, fields) =>
  apiAxios
    .post(`/api/v1.0/account/updateAccountSettings`, { settingName, fields })
    .then(({ data }) => responseDataHandler(data));

export const doCreateApiKeyApi = ({ name }: { name: string }) =>
  callApi("/api/v1.0/key", {
    method: "POST",
    data: { name }
  }).then(({ data }) => responseDataHandler(data));

export const doAddUser = ({ email }: { email: string }) =>
  callApi("/api/v1.0/account/user", {
    method: "POST",
    data: { email }
  }).then(({ data }) => responseDataHandler(data));

export const doRedeemBonus = ({ accountId, type }: { accountId: string; type: string }) =>
  callApi("/api/v1.0/account/redeemBonus", {
    method: "POST",
    data: { accountId, type }
  }).then(({ data }) => responseDataHandler(data));

export const doRemoveUser = (userId) =>
  apiAxios.delete(concatApiQuery(`/api/v1.0/account/${userId}`, {})).then(({ data }) => responseDataHandler(data));

export const doGetAccountTransactions = (
  {
    dateStart,
    dateEnd,
    typeIn,
    limit = 0,
    offset = 10
  }: {
    dateStart?: string;
    dateEnd?: string;
    typeIn?: string;
    offset: number;
    limit: number;
  } = { offset: 0, limit: 10 }
) =>
  apiAxios
    .get(concatApiQuery("/api/v1.0/transaction", { limit, offset, dateStart, dateEnd, typeIn }))
    .then(({ data }) => responseDataHandler(data));

export const doGetAccountResourceUsage = ({
  accountId,
  dateStart,
  dateEnd,
  resourceNameIn,
  limit = 0,
  offset = 10,
  order = "desc"
}: {
  accountId: string;
  dateStart?: string;
  dateEnd?: string;
  resourceNameIn?: string;
  offset: number;
  limit: number;
  order: string;
}) =>
  apiAxios
    .get(
      concatApiQuery("/api/v1.0/usage/api/billing", {
        accountId,
        dateStart,
        dateEnd,
        resourceNameIn,
        limit,
        offset,
        order
      })
    )
    .then(({ data }) => responseDataHandler(data));

export const getTransactionTypes = () =>
  apiAxios.get("/api/v1.0/transaction/type").then(({ data }) => responseDataHandler(data));

export const getBillingResources = () =>
  apiAxios.get("/api/v1.0/usage/api/billing/resource").then(({ data }) => responseDataHandler(data));

export const submitSupportTicket = (message) =>
  apiAxios.post(`/api/v1.0/support/dashboard`, { message }).then(({ data }) => responseDataHandler(data));

export const doGetAccountSigningSecret = () =>
  apiAxios.get("/api/v1.0/account/signing-secret").then(({ data }) => responseDataHandler(data));

export const doCheckIfAccountValidForFeature = (featureName) =>
  apiAxios
    .get(concatApiQuery("/api/v1.0/feature/isAccountValidForFeature", { featureName }))
    .then(({ data }) => responseDataHandler(data));
