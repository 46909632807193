import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Form,
  Input,
  Modal,
  Spin,
  Tabs,
  Table,
  Tag,
  TableColumnsType,
  Badge,
  Dropdown,
  InputNumber,
  Switch,
  Pagination,
  Popconfirm
} from "antd";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { boundError } from "../../utlis/boundError";
import { useSelector } from "react-redux";
import { Button, Space, Divider } from "antd";
import { PlusOutlined, DownloadOutlined, LinkOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { showError, showSuccess, showWarning } from "../../utlis/messages";
import { AccountsServices } from "../../../services";
import moment from "moment";
import { PaymentService } from "../../../services/PaymentServices";
import { Buffer } from "buffer";
import { IRootState } from "../../../redux/store";

interface DataType {
  id: string;
  name: string;
  email: string;
  roleName: string;
  status: string;
  userId: string;
  key: number;
}

const AccountAccessSectionRaw = ({ account, refreshPage, navigatePage }) => {
  const isAuthenticated = useSelector((state) => state);
  const accountsServices = AccountsServices.getInstance();
  const paymentService = PaymentService.getInstance();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showRemoveUserPop, setShowRemoveUserPop] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>();
  const user = useSelector((state: IRootState) => state.auth.user);

  useEffect(() => {}, []);

  const addUserToAccount = async () => {
    if (!userEmail) {
      return showError("User email is required.");
    }
    try {
      setLoading(true);
      const keys = await accountsServices.addUser({ email: userEmail });
      setShowAddUserModal(false);
      showSuccess(`${userEmail} has been added to this account.`);
      setUserEmail("");
      refreshPage();
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const removeUser = async (userId) => {
    try {
      setLoading(true);
      const keys = await accountsServices.removeUser(userId);

      showSuccess(`User has been removed from this account.`);
      refreshPage();
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "User Email",
      dataIndex: "email",
      width: 400
    },
    {
      title: "First Name",
      dataIndex: "firstName"
    },
    {
      title: "Last Name",
      dataIndex: "lastName"
    },
    {
      title: "Role",
      dataIndex: "roleName"
    },
    {
      title: "Edit",
      dataIndex: ["card"],
      key: "id",
      width: 200,
      render: (_, record) =>
        record.email !== user.username &&
        record.roleName !== "OWNER" && (
          <Button
            style={{
              padding: 0
            }}
            type="link"
            danger
            title="Delete"
            onClick={() => {
              setSelectedUser(record);
              setShowRemoveUserPop(!showRemoveUserPop);
            }}
          >
            <Popconfirm
              title="Removing this user from account"
              description="The user will no long access to this account"
              open={selectedUser?.id === record.id && showRemoveUserPop}
              onConfirm={() => removeUser(record.id)}
              okButtonProps={{ loading: loading }}
              onCancel={() => setShowRemoveUserPop(false)}
            >
              Remove
            </Popconfirm>
          </Button>
        )
    }
  ];

  return (
    <Spin spinning={loading}>
      <p
        style={{
          fontSize: 20,
          fontWeight: 600,
          textAlign: "left"
        }}
      >
        Account Access
      </p>
      <Button
        style={{
          display: "flex",
          borderRadius: 2,
          marginBottom: 10
        }}
        type="primary"
        shape="round"
        // icon={<PlusOutlined />}
        size={"middle"}
        onClick={() => setShowAddUserModal(true)}
      >
        Add User to Account
      </Button>
      <Table columns={columns} dataSource={account.userRoles} pagination={false} rowKey="id" />
      <Modal
        title="Add User"
        open={showAddUserModal}
        onCancel={() => {
          setShowAddUserModal(false);
          setUserEmail("");
        }}
        onOk={addUserToAccount}
        okText="Add"
        cancelText="Cancel"
      >
        <p>Enter user email:</p>
        <Input value={userEmail} onChange={(e) => setUserEmail(e.target.value.trim())} />
      </Modal>
    </Spin>
  );
};

const AccountAccessSection = boundError(AccountAccessSectionRaw);
export default AccountAccessSection;
