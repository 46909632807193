import { IAccountDetails } from "../models/interfaces/AccountDetails";
import { apiAxios, callApi, concatApiQuery, responseDataHandler } from "./axios";
import { PaginationType } from "./types";
import { Buffer } from "buffer";

export const doCreateStripeSetupIntents = (accountId?) =>
  apiAxios
    .get(concatApiQuery("/api/v1.0/payment/createStripeSetupIntents", {}), { headers: { "ebs-account-id": accountId } })
    .then(({ data }) => responseDataHandler(data));

export const doDownloadReceipt = (transactionId) =>
  apiAxios
    .get(concatApiQuery("/api/v1.0/payment/receipt/download", { transactionId }))
    .then(({ data }) => responseDataHandler(data));

export const doSetAsDefault = (cardId, accountId?) =>
  apiAxios
    .post(
      "/api/v1.0/payment/card/default",
      { cardId: cardId },
      { ...(accountId ? { headers: { "ebs-account-id": accountId } } : null) }
    )
    .then(({ data }) => responseDataHandler(data));

export const doRemovePaymentMethod = (cardId) =>
  apiAxios.post("/api/v1.0/payment/card/remove", { cardId: cardId }).then(({ data }) => responseDataHandler(data));

export const doUpdateCard = (cardId, expireMonth, expireYear) =>
  apiAxios
    .post("/api/v1.0/payment/card/update", { cardId, expireMonth, expireYear })
    .then(({ data }) => responseDataHandler(data));

export const doDepositAmount = (cardId, depositAmount) =>
  apiAxios
    .post("/api/v1.0/payment/card/deposit", { cardId, depositAmount })
    .then(({ data }) => responseDataHandler(data));

export const doSubscribePaymentApi = () =>
  callApi("/api/v1.0/payment/subscribePayment", { method: "POST" }).then(({ data }) => responseDataHandler(data));

export const doAddPaymentCardToAccountApi = ({ cardToken }: { cardToken: string }) =>
  callApi("/api/v1.0/payment/card", {
    method: "POST",
    data: { cardToken }
  }).then(({ data }) => responseDataHandler(data));

export const doGetAccountCardListApi = (accountId) =>
  callApi("/api/v1.0/payment/card", { headers: { "ebs-account-id": accountId } }).then(({ data }) =>
    responseDataHandler(data)
  );

export const doSetDefaultCardApi = ({ cardId }: { cardId: string }) =>
  callApi("/api/v1.0/payment/card/default", {
    method: "POST",
    data: { cardId }
  }).then(({ data }) => responseDataHandler(data));
