import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Input, Modal, Spin, Tabs, Table, Tag, Dropdown, MenuProps, Select, InputNumber } from "antd";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { boundError } from "../../../utlis/boundError";
import { useSelector } from "react-redux";
import { Button, Space } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { showError, showSuccess } from "../../../utlis/messages";
import { AccountsServices } from "../../../../services";
import { CardButton } from "../../../atoms/CardButton";
import { FundViewOutlined, WalletOutlined, UnlockOutlined, DownOutlined } from "@ant-design/icons";
import { CopyButton } from "../../../atoms/CopyButton";
import { Network } from "../../../../constants/types";
import NavigationBar from "../../../molecules/NavigationBar";
import { ButtonPrimary } from "../../../atoms/ButtonPrimary";
import {
  doCreateDestinationWallet,
  doCreateGasFeeFundingWallet,
  doCreateTokenForwardingWallet,
  doGetSmartContractInfo,
  doGetSupportedNetworksAndTokens,
  doTestWebhook
} from "../../../../apis/tokenForwardingApis";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import { doGetAccountSigningSecret } from "../../../../apis/accountApis";

const CreateWalletRaw = () => {
  const isAuthenticated = useSelector((state) => state);
  const accountsServices = AccountsServices.getInstance();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [walletType, setWalletType] = useState<any>("tokenForwardingWallet");
  const [loading, setLoading] = useState(false);
  const [smartContractAddress, setSmartContractAddress] = useState("");
  const [smartContractName, setSmartContractName] = useState("");
  const [smartContractSymbol, setSmartContractSymbol] = useState("");
  const [selectedToken, setSelectedToken] = useState("");
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [supportedNetworksAndTokens, setSupportedNetworksAndTokens] = useState({});
  const [supportedNetworks, setSupportedNetworks] = useState<string[]>([]);
  const [supportedTokens, setSupportedTokens] = useState<string[]>([]);
  const isMounted = useRef(true);
  const location = useLocation();
  const [refresh, setRefresh] = useState(false);
  const [showSmartContractInfoModal, setShowSmartContractInfoModal] = useState(false);
  const [webhookUrl, setWebhookUrl] = useState("");
  const [webhookHeader, setWebhookHeader] = useState("");
  const [webhookHeaderValue, setWebhookHeaderValue] = useState("");
  const [webhookHeaderSecret, setWebhookHeaderSecret] = useState();
  const [webhookResponse, setWebhookResponse] = useState("{}");
  const [showWebhookResponseModal, setShowWebhookResponseModal] = useState(false);

  useEffect(() => {
    fetchNetworksAndTokens();
  }, []);

  const resetFields = () => {
    setSmartContractAddress("");
    setSmartContractName("");
    setSmartContractSymbol("");
    setSelectedToken("");
    setSelectedNetwork("");
    setWebhookUrl("");
    setWebhookHeader("");
    setWebhookHeaderValue("");
  };

  const fetchNetworksAndTokens = async () => {
    try {
      setLoading(true);
      const result = await doGetSupportedNetworksAndTokens();
      setSupportedNetworksAndTokens(result);
      setSupportedNetworks(Object.keys(result));
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const getSmartContractInfo = async () => {
    try {
      setLoading(true);
      if (!selectedNetwork) {
        return showError("Network is required.");
      }
      if (!smartContractAddress) {
        return showError("Smart Contract Address is required.");
      }
      const result = await doGetSmartContractInfo(selectedNetwork, smartContractAddress);
      setSmartContractName(result.name);
      setSmartContractSymbol(result.symbol);
      setShowSmartContractInfoModal(true);
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const testWebhook = async () => {
    try {
      setLoading(true);
      if (!webhookUrl) {
        return showError("Webhook URL is required.");
      }
      const result = await doTestWebhook(webhookUrl.trim(), webhookHeader.trim(), webhookHeaderValue.trim());
      setWebhookResponse(JSON.stringify(result || {}));
      setShowWebhookResponseModal(true);
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const sendRequest = async (values) => {
    try {
      setLoading(true);
      let {
        walletType,
        network,
        name,
        token,
        smartContractAddress,
        minimumForwardingAmount,
        destinationPublicAddress,
        gasFeeFundingWalletId,
        webhookUrl,
        webhookHeader,
        webhookHeaderValue
      } = values;
      walletType = walletType || "tokenForwardingWallet";
      minimumForwardingAmount = minimumForwardingAmount || 0.00001;

      if (!network) {
        return showError("Network is required.");
      }

      if (walletType === "tokenForwardingWallet") {
        if (!token) {
          return showError("Token is required.");
        }
        if (selectedToken === "ERC20" && !smartContractAddress) {
          return showError("Smart Contract Address is required.");
        }
        if (!minimumForwardingAmount) {
          return showError("Minimum Forwarding Amount is required.");
        }
        if (!destinationPublicAddress) {
          return showError("Destination Public Address is required.");
        }
        if (selectedToken === "ERC20" && !gasFeeFundingWalletId) {
          return showError("Gas Fee Funding Wallet ID is required.");
        }
        if (webhookUrl) {
          if (!webhookHeader) {
            return showError("Webhook Header is required.");
          }
          if (!webhookHeaderValue) {
            return showError("Webhook Header Value is required.");
          }
          webhookUrl = webhookUrl.trim();
          webhookHeader = webhookHeader.trim();
          webhookHeaderValue = webhookHeaderValue.trim();
          const accountSecret = await doGetAccountSigningSecret();
          setWebhookHeaderSecret(accountSecret.secret);
        }
        await doCreateTokenForwardingWallet({
          network,
          token,
          smartContractAddress,
          webhookSetting: webhookUrl
            ? {
                url: webhookUrl,
                header: webhookHeader,
                headerValue: webhookHeaderValue,
                secret: webhookHeaderSecret
              }
            : undefined,
          minimumForwardingAmount,
          destinationPublicAddress,
          gasFeeFundingWalletId
        });
      } else {
        if (!name) {
          return showError("Name is required.");
        }
        if (walletType === "gasFeeFundingWallet") {
          await doCreateGasFeeFundingWallet(network, name);
        } else {
          if (!destinationPublicAddress) {
            return showError("Destination Public Address is required.");
          }
          await doCreateDestinationWallet(network, name, destinationPublicAddress);
        }
      }

      navigate(`/account/${accountsServices.getCurrentAccount()!.account?.id}/services/token-forwarding/wallets`);
      showSuccess("Wallet has been created.");
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="main-container center-container">
        <NavigationBar items={["Services"]} subItems={["Token Forwarding"]} currentPage="Create Wallet" />
        <p
          style={{
            fontSize: 20,
            fontWeight: 600,
            textAlign: "left"
          }}
        >
          Create Wallet
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50vw"
          }}
        >
          <Form
            style={{ padding: 20 }}
            onFinish={sendRequest}
            layout="vertical"
            form={form}
            name="login-form"
            id="login-form"
          >
            <Form.Item label="Wallet Type" name="walletType" required>
              <Select defaultValue="tokenForwardingWallet" onChange={setWalletType}>
                <Select.Option value="tokenForwardingWallet">Token Forwarding Wallet</Select.Option>
                <Select.Option value="gasFeeFundingWallet">Gas Fee Funding Wallet</Select.Option>
                <Select.Option value="destinationWallet">Destination Wallet</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Network" name="network" required>
              <Select
                onChange={(e) => {
                  setSupportedTokens(supportedNetworksAndTokens[e]);
                  form.setFieldValue("token", "");
                  setSelectedToken("");
                  setSelectedNetwork(e);
                }}
              >
                {supportedNetworks.map((network) => (
                  <Select.Option value={network}>{network.replace("_", " ")}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            {(walletType === "gasFeeFundingWallet" || walletType === "destinationWallet") && (
              <Form.Item label="Name" name="name" required>
                <Input></Input>
              </Form.Item>
            )}
            {walletType === "destinationWallet" && (
              <Form.Item label="Destination Public Address" name="destinationPublicAddress" required>
                <Input></Input>
              </Form.Item>
            )}

            {walletType === "tokenForwardingWallet" && (
              <>
                <Form.Item label="Token" name="token" required>
                  <Select onChange={setSelectedToken}>
                    {supportedTokens.length ? (
                      supportedTokens.map((token) => <Select.Option value={token}>{token}</Select.Option>)
                    ) : (
                      <Select.Option value="">Please select a network</Select.Option>
                    )}
                  </Select>
                </Form.Item>
                {selectedToken === "ERC20" && (
                  <Form.Item label="Smart Contract Address" name="smartContractAddress" required>
                    <Space.Compact style={{ width: "100%" }}>
                      <Input
                        onChange={(e) => {
                          setSmartContractAddress(e.target.value);
                        }}
                      />
                      <Button type="primary" onClick={getSmartContractInfo} disabled={!smartContractAddress}>
                        Verify
                      </Button>
                    </Space.Compact>
                  </Form.Item>
                )}
                <Form.Item label="Minimum Forwarding Amount" name="minimumForwardingAmount" required>
                  <InputNumber
                    defaultValue="0.00001"
                    min="0"
                    max="100"
                    step="0.00001"
                    prefix={selectedToken}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item label="Destination Public Address" name="destinationPublicAddress" required>
                  <Select
                    onChange={(e) => {
                      if (e === "create-destination-public-wallet") {
                        resetFields();
                        form.resetFields();
                        setWalletType("destinationWallet");
                        form.setFieldValue("walletType", "destinationWallet");
                      }
                    }}
                  >
                    <Select.Option value="create-destination-public-wallet">
                      ---------- Create Destination Public Wallet ---------
                    </Select.Option>
                    {(location?.state?.destinationWallets || [])
                      .filter((wallet) => wallet.network == selectedNetwork)
                      .map((wallet) => {
                        if (wallet) {
                          return (
                            <Select.Option value={wallet.destinationPublicAddress}>
                              {(wallet.name || "") + " (" + wallet.destinationPublicAddress + ")"}
                            </Select.Option>
                          );
                        }
                      })}
                  </Select>
                </Form.Item>
                {selectedToken === "ERC20" && (
                  <Form.Item label="Gas Fee Funding Wallet ID" name="gasFeeFundingWalletId" required>
                    <Select
                      onChange={(e) => {
                        if (e === "create-gas-fee-wallet") {
                          resetFields();
                          form.resetFields();
                          setWalletType("gasFeeFundingWallet");
                          form.setFieldValue("walletType", "gasFeeFundingWallet");
                        }
                      }}
                    >
                      <Select.Option value="create-gas-fee-wallet">
                        ---------- Create Gas Fee Funding Wallet ---------
                      </Select.Option>
                      {(location?.state?.gasFeeFundingWallets || [])
                        .filter((wallet) => wallet.network == selectedNetwork)
                        .map((wallet) => {
                          if (wallet) {
                            return (
                              <Select.Option value={wallet.id}>
                                {(wallet.name || "") + " (" + wallet.id + ")"}
                              </Select.Option>
                            );
                          }
                        })}
                    </Select>
                  </Form.Item>
                )}
                <Form.Item label="Webhook Url" name="webhookUrl">
                  <Space.Compact style={{ width: "100%" }}>
                    <Input
                      onChange={(e) => {
                        setWebhookUrl(e.target.value);
                      }}
                    />
                    <Button type="primary" onClick={testWebhook} disabled={!webhookUrl}>
                      Test
                    </Button>
                  </Space.Compact>
                </Form.Item>
                <Form.Item label="Webhook Header" name="webhookHeader">
                  <Input
                    onChange={(e) => {
                      setWebhookHeader(e.target.value);
                    }}
                  ></Input>
                </Form.Item>
                <Form.Item label="Webhook Header Value" name="webhookHeaderValue">
                  <Input
                    onChange={(e) => {
                      setWebhookHeaderValue(e.target.value);
                    }}
                  ></Input>
                </Form.Item>
              </>
            )}
            <ButtonPrimary htmlType="submit" type="primary" size={"medium"} block>
              Create
            </ButtonPrimary>
          </Form>
        </div>
      </div>
      <Modal
        title="Smart Contract Information"
        open={showSmartContractInfoModal}
        onCancel={() => {
          setShowSmartContractInfoModal(false);
        }}
        onOk={() => {
          setShowSmartContractInfoModal(false);
        }}
        okText="Done"
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
            flexDirection: "column",
            width: "90%"
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              width: "100%"
            }}
          >
            <span>Address:</span>
            <Input value={smartContractAddress} style={{ width: "70%", marginLeft: 10 }} />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 20,
              justifyContent: "end",
              width: "100%"
            }}
          >
            <span>Name:</span>
            <Input value={smartContractName} style={{ width: "70%", marginLeft: 10 }} />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 20,
              justifyContent: "end",
              width: "100%"
            }}
          >
            <span>Symbol:</span>
            <Input value={smartContractSymbol} style={{ width: "70%", marginLeft: 10 }} />
          </div>
        </div>
      </Modal>
      <Modal
        title="Webhook Response"
        open={showWebhookResponseModal}
        onCancel={() => {
          setShowWebhookResponseModal(false);
        }}
        onOk={() => {
          setShowWebhookResponseModal(false);
        }}
        okText="Done"
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
            flexDirection: "column",
            width: "90%"
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              width: "100%"
            }}
          >
            <span>Url:</span>
            <Input value={webhookUrl} style={{ width: "70%", marginLeft: 10 }} />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 20,
              justifyContent: "end",
              width: "100%"
            }}
          >
            <span>Response:</span>
            <TextArea value={webhookResponse} style={{ width: "70%", marginLeft: 10 }} />
          </div>
        </div>
      </Modal>
    </Spin>
  );
};

const CreateWallet = boundError(CreateWalletRaw);
export default CreateWallet;
