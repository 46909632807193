import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Input, Modal, Spin, Tabs, Table, Tag, Steps, Result, Checkbox, Tooltip } from "antd";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { boundError } from "../../utlis/boundError";
import { useSelector } from "react-redux";
import { Button, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { showError, showSuccess } from "../../utlis/messages";
import { AccountsServices, UserServices } from "../../../services";
import { ButtonPrimary } from "../../atoms/ButtonPrimary";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../../molecules/CheckoutForm";
import { PaymentService } from "../../../services/PaymentServices";
import { IRootState } from "../../../redux/store";
import { useStripe, useElements, PaymentElement, Elements } from "@stripe/react-stripe-js";
import StripeForm from "../../molecules/StripeForm";

const stripePromise = loadStripe(process.env.STRIPE_PK!);

const CreateRaw = () => {
  const isAuthenticated = useSelector((state) => state);
  const accountsServices = AccountsServices.getInstance();
  const paymentService = PaymentService.getInstance();
  const userServices = UserServices.getInstance();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [accountName, setAccountName] = useState("");
  const [loading, setLoading] = useState(false);
  const [showCreateAccountBox, setShowCreateAccountBox] = useState(true);
  const [showAddPaymentBox, setShowAddPaymentBox] = useState(false);
  const [showSuccessBox, setSuccessBox] = useState(false);
  const [isAddPaymentCardMandatory, setIsAddPaymentCardMandatory] = useState(false);
  const [data, setData] = useState<DataType[]>([]);
  const isMounted = useRef(true);
  const [current, setCurrent] = useState(0);
  const [redeemWelcomeCredit, setRedeemWelcomeCredit] = useState(false);
  const [stripeInfo, setStripeInfo] = useState<IStripeInfo>({
    client_secret: "",
    customerId: "",
    setupIntentId: "",
    accountId: ""
  });
  const user = useSelector((state: IRootState) => state.auth.user);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const stripeBtnRef = useRef<any>();

  interface DataType {
    id: string;
    name: string;
    roleName: string;
    status: string;
    userId: string;
    key: number;
  }

  interface IStripeInfo {
    client_secret: string;
    customerId: string;
    setupIntentId: string;
    accountId: string;
  }

  useEffect(() => {
    fetchSettings();
    fetchStripeInfo();
  }, []);

  const createAccount = async (setupIntent) => {
    try {
      setLoading(true);
      // const result = await accountsServices.updateAccountName(stripeInfo.accountId, accountName);
      await accountsServices.finishCreateAccountProcess({
        accountId: stripeInfo.accountId,
        accountName,
        redeemWelcomeCredit,
        cardId: setupIntent.payment_method
      });
      // const cardId = setupIntent.payment_method;
      // await paymentService.setCardAsDefault(cardId, stripeInfo.accountId);
      // if (redeemWelcomeCredit) {
      //   await accountsServices.redeemBonus({ accountId: stripeInfo.accountId, type: "welcomeCredit" });
      // }
      setShowAddPaymentBox(false);
      setSuccessBox(true);
      next();
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSettings = async () => {
    try {
      setLoading(true);
      const data = await userServices.getSettings("ADD_PAYMENT_CARD");
      setIsAddPaymentCardMandatory(data?.settings?.mandatory);
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchStripeInfo = async () => {
    try {
      setLoading(true);
      const result = await paymentService.createStripeSetupIntents();
      setStripeInfo(result.stripe);
    } catch (error: any) {
      showError(error);
      navigate("/accounts");
    } finally {
      setLoading(false);
    }
  };

  const steps = [
    {
      title: "Account Name",
      description: <div className="description-no-wrap">Set up account name for easy tracking</div>
    },
    {
      title: `Payment ${isAddPaymentCardMandatory ? "" : "(Optional)"}`,
      description: <div className="description-no-wrap">Add a payment card to get started!</div>
    },
    {
      title: "Congratulations!",
      description: <div className="description-no-wrap">You account is created.</div>
    }
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({ key: item.title, title: item.title, description: item.description }));

  const renderCreateAccountNameCard = () => {
    return (
      <>
        <p>
          <span style={{ color: "red" }}>*</span>Account Name:
        </p>
        <Input value={accountName} onChange={(e) => setAccountName(e.target.value)} />
        <div
          style={{
            textAlign: "left",
            color: "rgba(0, 0, 0, 0.45)",
            fontSize: 13,
            margin: "10px 20px"
          }}
        >
          Account name is required and can only contain the set of Unicode letters, digits, white-space, '_', '.', '/',
          '=', '+', '-' with minimum length of 1 and maximum length of 256.
        </div>
        {!userServices.user?.bonusRedemption?.welcomeCredit && (
          // <Checkbox
          //   checked={redeemWelcomeCredit}
          //   onChange={(e) =>
          //     accountsServices.redeemBonus({
          //       accountId: "5fb4b9f2-ad16-41fc-8cb8-573ae25aef27",
          //       type: "welcomeCredit"
          //     })
          //   }
          // >
          <Checkbox checked={redeemWelcomeCredit} onChange={(e) => setRedeemWelcomeCredit(e.target.checked)}>
            <span
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              Redeem the Welcome Credit ($10USD) on this account{" "}
              <Tooltip title="EZAT presents a one-time welcome credit to every user">
                <ExclamationCircleOutlined style={{ marginLeft: 5, color: "gray" }} />
              </Tooltip>
            </span>
          </Checkbox>
        )}
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 20,
            justifyContent: "space-evenly"
          }}
        >
          <ButtonPrimary
            style={{
              width: "20%"
            }}
            type="primary"
            danger
            onClick={() => navigate("/accounts")}
          >
            Cancel
          </ButtonPrimary>
          <ButtonPrimary
            style={{
              width: "20%"
            }}
            type="primary"
            onClick={() => {
              if (!accountName || !/^[\d\w\s+=_./-]{1,256}$/.test(accountName))
                return showError("Invalid account name");
              next();
              setShowCreateAccountBox(false);
              setShowAddPaymentBox(true);
            }}
          >
            Next
          </ButtonPrimary>
        </div>
      </>
    );
  };
  const appearance = {
    theme: "stripe"
  };
  const options = {
    clientSecret: stripeInfo.client_secret
    // appearance,
  };

  const renderSuccessCard = () => {
    return (
      <Result
        status="success"
        title="Your EZAT account has been created successfully. Please return to My Account to view the details."
        extra={[
          <Button type="primary" key="console" onClick={() => navigate("/accounts")}>
            Done
          </Button>
        ]}
      />
    );
  };

  const renderAddPaymentCard = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center"
          }}
        >
          <Elements options={options} stripe={stripePromise}>
            <StripeForm stripeBtnRef={stripeBtnRef} onSuccess={createAccount} />
          </Elements>
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly"
          }}
        >
          <ButtonPrimary
            style={{
              width: "100px"
            }}
            type="primary"
            danger
            onClick={() => {
              prev();
              setShowCreateAccountBox(true);
              setShowAddPaymentBox(false);
            }}
          >
            Back
          </ButtonPrimary>
          <div
            style={{
              display: "flex"
            }}
          >
            <ButtonPrimary
              style={{
                width: "100px"
              }}
              type="primary"
              // disabled={buttonDisabled}
              onClick={() => {
                stripeBtnRef.current?.click();
              }}
            >
              Create
            </ButtonPrimary>
            {!isAddPaymentCardMandatory && (
              <Button
                style={{ marginRight: 5 }}
                type="link"
                onClick={() => {
                  createAccount(stripeInfo);
                }}
              >
                Skip
              </Button>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <Spin spinning={loading}>
      <div className="main-container center-container">
        <div
          className="shadow-box"
          style={{
            margin: "5%",
            padding: 50
          }}
        >
          <Steps current={current} items={items} />
          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            <div
              className="create-account-form"
              style={{
                width: 500
              }}
            >
              {showCreateAccountBox && renderCreateAccountNameCard()}
              {showAddPaymentBox && renderAddPaymentCard()}
              {showSuccessBox && renderSuccessCard()}
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

const Create = boundError(CreateRaw);
export default Create;
