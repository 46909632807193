import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Input, Modal, Spin, Tabs, Table, Tag } from "antd";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { boundError } from "../../utlis/boundError";
import { useSelector } from "react-redux";
import { Button, Space } from "antd";
import { PlusCircleOutlined, FormOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { showError, showSuccess } from "../../utlis/messages";
import { AccountsServices, UserServices } from "../../../services";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../../redux/actions";

const ListRaw = () => {
  const isAuthenticated = useSelector((state) => state);
  const dispatch = useDispatch();
  const accountsServices = AccountsServices.getInstance();
  const userServices = UserServices.getInstance();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DataType[]>([]);
  const isMounted = useRef(true);

  interface DataType {
    id: string;
    name: string;
    roleName: string;
    status: string;
    userId: string;
    key: number;
    isBillingApiEnabled: boolean;
  }

  useEffect(() => {
    // DO NOT make multiple api calls at once in this component
    // it causes 500 error when create a new user and visit this page in the first time
    // put the other calls in async instead
    fetchAccountsList();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchAccountsList = async () => {
    try {
      setLoading(true);
      const result = await accountsServices.fetchUserAccounts();
      let accounts: DataType[] = [];
      let key = 0;
      result.accounts
        .filter((a) => a.name !== "ACCOUNT_CREATION")
        .forEach((account) =>
          accounts.push({
            id: "..." + account?.id?.substr(account?.id.length - 12),
            ...account,
            key: ++key
          })
        );
      setData(accounts);
    } catch (error: any) {
      showError(error);
    } finally {
      fetchUserInfo();
      setLoading(false);
    }
  };

  const fetchUserInfo = async () => {
    try {
      await userServices.getUserInfo();
    } catch (error: any) {
      showError(error);
    }
  };

  const rowOnClick = async (record) => {
    try {
      setLoading(true);
      const account = accountsServices.findAccountById(record?.id);
      if (account) {
        await accountsServices.assembleAccountByIdByFetchingRemotely({
          accountId: record?.id,
          setAsCurrentAccount: true
        });
      }
    } catch (error: any) {
      showError(error);
    } finally {
      navigate(`/account/${record.id}/overview`);
      setLoading(false);
    }
  };

  const createNewAccountAction = async () => {
    if ((data || []).length >= 10) {
      return showError("Accounts has reached the limit 10");
    }
    navigate("/accounts/create");
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Account name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          {record.name}
        </div>
      )
    },
    {
      title: "Account ID",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <span
          style={{
            padding: "1px 8px",
            backgroundColor: record.isBillingApiEnabled ? "rgba(246, 255, 237, 1)" : "rgba(244, 244, 244, 1)",
            borderRadius: 2,
            color: record.isBillingApiEnabled ? "rgba(82, 196, 26, 1)" : "rgba(93, 96, 92, 1)",
            border: record.isBillingApiEnabled ? "1px solid #B7EB8F" : "1px solid rgba(217, 217, 217, 1)"
          }}
        >
          {record.isBillingApiEnabled ? "ACTIVE" : "SUSPENDED"}
        </span>
      )
    },
    {
      title: "Role",
      dataIndex: "roleName",
      key: "roleName"
    }
  ];

  return (
    <Spin spinning={loading}>
      <div className="main-container">
        <div className="account-list-container">
          <h3 style={{ marginTop: 0 }}>My Accounts</h3>
          <div className="div-flex-center">
            <Button onClick={createNewAccountAction} className="div-flex-center" type="primary">
              <PlusCircleOutlined />
              Create new account
            </Button>
          </div>
          <Table
            className="account-list-table"
            columns={columns}
            pagination={{ position: ["bottomRight"] }}
            dataSource={data}
            rowClassName="pointer-cursor"
            onRow={(record, rowIndex) => {
              return {
                onClick: () => rowOnClick(record)
              };
            }}
          />
        </div>
      </div>
    </Spin>
  );
};

const List = boundError(ListRaw);
export default List;
