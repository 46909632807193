import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Input, Modal, Spin, Tabs, Table, Tag } from "antd";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { boundError } from "../../utlis/boundError";
import { useSelector } from "react-redux";
import { Button, Space } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { showError, showSuccess } from "../../utlis/messages";
import { AccountsServices } from "../../../services";
import { CardButton } from "../../atoms/CardButton";
import { FundViewOutlined, WalletOutlined, UnlockOutlined, LeftOutlined } from "@ant-design/icons";
import { CopyButton } from "../../atoms/CopyButton";
import { Network } from "../../../constants/types";
import NavigationBar from "../../molecules/NavigationBar";

const RPCServiceRaw = () => {
  const isAuthenticated = useSelector((state) => state);
  const accountsServices = AccountsServices.getInstance();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [accountName, setAccountName] = useState("");
  const [accountDetails, setAccountDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [apiKeyId, setApiKeyId] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [ethereumMainnet, setEthereumMainnet] = useState("");
  const [ethereumSepolia, setEthereumSepolia] = useState("");
  const [bscMainnet, setBscMainnet] = useState("");
  const [bscTestnet, setBscTestnet] = useState("");
  const [polygonMainnet, setPolygonMainnet] = useState("");
  const [polygonTestnet, setPolygonTestnet] = useState("");
  const [metabitMainnet, setMetabitMainnet] = useState("");
  const [metabitTestnet, setMetabitTestnet] = useState("");
  const [auroraMainnet, setAuroraMainnet] = useState("");
  const [auroraTestnet, setAuroraTestnet] = useState("");
  const isMounted = useRef(true);
  const location = useLocation();

  useEffect(() => {
    setApiKeyId(apiKeyId || "[API_KEY_ID]");
    setApiKey(apiKey || "[API_KEY]");

    setEthereumMainnet(getRpcEndPoint(Network.RPC.Name.ETHEREUM_MAINNET));
    setEthereumSepolia(getRpcEndPoint(Network.RPC.Name.ETHEREUM_SEPOLIA));
    setBscMainnet(getRpcEndPoint(Network.RPC.Name.BSC_MAINNET));
    setBscTestnet(getRpcEndPoint(Network.RPC.Name.BSC_TESTNET));
    setPolygonMainnet(getRpcEndPoint(Network.RPC.Name.POLYGON_MAINNET));
    setPolygonTestnet(getRpcEndPoint(Network.RPC.Name.POLYGON_TESTNET));
    setMetabitMainnet(getRpcEndPoint(Network.RPC.Name.METABIT_MAINNET));
    setMetabitTestnet(getRpcEndPoint(Network.RPC.Name.METABIT_TESTNET));
    setAuroraMainnet(getRpcEndPoint(Network.RPC.Name.AURORA_MAINNET));
    setAuroraTestnet(getRpcEndPoint(Network.RPC.Name.AURORA_TESTNET));
  }, [apiKeyId, apiKey]);

  const NETWORK_NAME_TO_PATH = {
    [Network.RPC.Name.ETHEREUM_MAINNET]: "/ethereum/mainnet",
    [Network.RPC.Name.ETHEREUM_SEPOLIA]: "/ethereum/sepolia",
    [Network.RPC.Name.BSC_MAINNET]: "/bsc/mainnet",
    [Network.RPC.Name.BSC_TESTNET]: "/bsc/testnet",
    [Network.RPC.Name.POLYGON_MAINNET]: "/polygon/mainnet",
    [Network.RPC.Name.POLYGON_TESTNET]: "/polygon/amoy",
    [Network.RPC.Name.METABIT_MAINNET]: "/metabit/mainnet",
    [Network.RPC.Name.METABIT_TESTNET]: "/metabit/testnet",
    [Network.RPC.Name.AURORA_MAINNET]: "/aurora/mainnet",
    [Network.RPC.Name.AURORA_TESTNET]: "/aurora/testnet"
  };

  const getRpcEndPoint = (network: Network.RPC.Name) => {
    return `${process.env.API_BASE_URL}/api/v1.0/rpc${NETWORK_NAME_TO_PATH[network]}?x-api-key-id=${apiKeyId}&x-api-key=${apiKey}`;
  };

  const NetWorkRow = ({ network, url }) => {
    return (
      <div
        style={{
          display: "flex",
          marginTop: 10,
          alignItems: "center",
          whiteSpace: "nowrap",
          textAlign: "left"
        }}
      >
        <span
          style={{
            marginRight: 10,
            width: 150
          }}
        >
          {network}
        </span>
        <span
          style={{
            marginRight: 10
          }}
        >
          POST
        </span>
        <CopyButton text={url} style={{ marginRight: 5, marginLeft: 0 }} />
        <Input value={url} />
      </div>
    );
  };

  return (
    <Spin spinning={loading}>
      <div className="main-container center-container">
        <NavigationBar items={["Services"]} currentPage="RPC" />
        <p
          style={{
            fontSize: 20,
            fontWeight: 600,
            textAlign: "left"
          }}
        >
          RPC Service
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "90%"
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              style={{ width: 150, marginBottom: 10 }}
              type="primary"
              shape="round"
              size={"middle"}
              onClick={() => navigate(`/account/${accountsServices.getCurrentAccount()!.account?.id}/apiKeys`)}
            >
              Create New Key
            </Button>
            <Button
              style={{ padding: 0 }}
              type="link"
              shape="round"
              size={"middle"}
              onClick={() => window.open("https://docs.ebs.ezat.io/ebs-documentation/", "_blank")}
            >
              Documentation
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%"
            }}
          >
            <div
              style={{
                display: "flex",
                width: "50%",
                whiteSpace: "nowrap",
                alignItems: "center"
              }}
            >
              API key ID
              <Input
                style={{
                  marginLeft: 10,
                  marginRight: 10
                }}
                placeholder="Enter your existing key id"
                onChange={(e) => setApiKeyId(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                width: "50%",
                whiteSpace: "nowrap",
                alignItems: "center"
              }}
            >
              API key
              <Input
                style={{
                  marginLeft: 10
                }}
                placeholder="Enter your existing key"
                onChange={(e) => setApiKey(e.target.value)}
              />
            </div>
          </div>
          <NetWorkRow network={Network.RPC.Name.ETHEREUM_MAINNET} url={ethereumMainnet} />
          <NetWorkRow network={Network.RPC.Name.ETHEREUM_SEPOLIA} url={ethereumSepolia} />
          <NetWorkRow network={Network.RPC.Name.BSC_MAINNET} url={bscMainnet} />
          <NetWorkRow network={Network.RPC.Name.BSC_TESTNET} url={bscTestnet} />
          <NetWorkRow network={Network.RPC.Name.POLYGON_MAINNET} url={polygonMainnet} />
          <NetWorkRow network={Network.RPC.Name.POLYGON_TESTNET} url={polygonTestnet} />
          <NetWorkRow network={Network.RPC.Name.METABIT_MAINNET} url={metabitMainnet} />
          <NetWorkRow network={Network.RPC.Name.METABIT_TESTNET} url={metabitTestnet} />
          <NetWorkRow network={Network.RPC.Name.AURORA_MAINNET} url={auroraMainnet} />
          <NetWorkRow network={Network.RPC.Name.AURORA_TESTNET} url={auroraTestnet} />
        </div>
      </div>
    </Spin>
  );
};

const RPCService = boundError(RPCServiceRaw);
export default RPCService;
