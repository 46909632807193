import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Form,
  Input,
  Modal,
  Spin,
  Tabs,
  Table,
  Tag,
  TableColumnsType,
  Badge,
  Dropdown,
  InputNumber,
  Switch,
  Pagination,
  Card
} from "antd";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { boundError } from "../../utlis/boundError";
import { useSelector } from "react-redux";
import { Button, Space, Divider } from "antd";
import { PlusOutlined, DownloadOutlined, FormOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { showError, showSuccess, showWarning } from "../../utlis/messages";
import { AccountsServices } from "../../../services";
import moment from "moment";
import { PaymentService } from "../../../services/PaymentServices";
import { Buffer } from "buffer";
import { doGetAccountSigningSecret } from "../../../apis/accountApis";

const AccountInformationSectionRaw = ({ account, refreshPage }) => {
  const isAuthenticated = useSelector((state) => state);
  const accountsServices = AccountsServices.getInstance();
  const paymentService = PaymentService.getInstance();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [showUpdateNameModal, setShowUpdateNameModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({ id: "", name: "" });
  const [accountName, setAccountName] = useState("");
  const [signingSecret, setSigningSecret] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  const fetchSigningSecret = async () => {
    try {
      setLoading(true);
      const secret = await doGetAccountSigningSecret();
      setSigningSecret(secret.secret);
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateAccountName = async () => {
    if (!accountName && !selectedAccount.name) {
      return showError("Account name is required.");
    }
    try {
      setLoading(true);
      const result = await accountsServices.updateAccountName(selectedAccount.id, accountName || selectedAccount.name);
      setShowUpdateNameModal(false);
      showSuccess(`${accountName || selectedAccount.name} has been updated.`);
      refreshPage();
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
      setAccountName("");
    }
  };

  return (
    <Spin spinning={loading}>
      <p
        style={{
          fontSize: 20,
          fontWeight: 600,
          textAlign: "left"
        }}
      >
        Account Information
      </p>
      <Card style={{ width: "100%", textAlign: "left" }}>
        <div
          style={{
            width: "70%",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: 10,
            alignItems: "center"
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ fontWeight: 500 }}>Account Name:&nbsp;</span>
            {account!.name} &nbsp;
            <FormOutlined
              style={{ cursor: "pointer", color: "#007bff" }}
              onClick={(e) => {
                e.stopPropagation();
                setShowUpdateNameModal(true);
                setSelectedAccount(account);
              }}
            />
          </div>
          <div>
            <span style={{ fontWeight: 500 }}>Account Status: </span>
            {account!.status}
          </div>
          <div>
            <span style={{ fontWeight: 500 }}>Account ID: </span>
            {account!.id}
          </div>
          <div>
            <span style={{ fontWeight: 500 }}>Creation Date: </span>
            {moment(account!.createdAt).format("YYYY-MM-DD")}
          </div>
          <div>
            <span style={{ fontWeight: 500 }}>Account Owner: </span>
            {account!.owner!.email}
          </div>
          <div>
            <span style={{ fontWeight: 500 }}>Signing Secret: </span>
            {signingSecret}
            {!signingSecret && (
              <Button
                style={{
                  padding: 0,
                  color: "#007bff"
                }}
                type="link"
                onClick={fetchSigningSecret}
              >
                Reveal
              </Button>
            )}
          </div>
        </div>
      </Card>
      <Modal
        title="Edit Account Name"
        open={showUpdateNameModal}
        onCancel={() => {
          setShowUpdateNameModal(false);
          setAccountName("");
        }}
        onOk={updateAccountName}
        okText="Update"
        cancelText="Cancel"
      >
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 20 }}>
          <span>Account Name:</span>
          <Input
            style={{ width: "50%", marginLeft: 10 }}
            value={accountName || selectedAccount.name}
            onChange={(e) => setAccountName(e.target.value)}
          />
        </div>
      </Modal>
    </Spin>
  );
};

const AccountInformationSection = boundError(AccountInformationSectionRaw);
export default AccountInformationSection;
