import ServiceOverview from "./overview";
import RPCService from "./rpcService";
import TokenForwardingWallet from "./tokenForwarding/wallet_list";
import TokenForwardTransaction from "./tokenForwarding/transaction";
import CreateWallet from "./tokenForwarding/wallet_create";

const ServiceRouter = [
  { path: "/account/:accountId/services", element: <ServiceOverview /> },
  { path: "/account/:accountId/services/rpc", element: <RPCService /> },
  { path: "/account/:accountId/services/token-forwarding/wallets", element: <TokenForwardingWallet /> },
  { path: "/account/:accountId/services/token-forwarding/wallets/create", element: <CreateWallet /> },
  { path: "/account/:accountId/services/token-forwarding/transactions", element: <TokenForwardTransaction /> }
];

export default ServiceRouter;
