import React, { Children, useEffect, useRef, useState } from "react";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
  PlusCircleOutlined,
  HomeFilled,
  KeyOutlined,
  BookOutlined,
  DollarOutlined,
  ReadOutlined,
  CustomerServiceOutlined,
  FileSearchOutlined,
  FormOutlined
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import { boundError } from "../../utlis/boundError";
import { AccountsServices, UserServices } from "../../../services";
import { EventUtils, EVENT_KEYS } from "../../utlis/events";
import { useLocation, useNavigate } from "react-router-dom";

const SideMenuRaw = () => {
  const accountsServices = AccountsServices.getInstance();
  const userServices = UserServices.getInstance();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = useState(["overview"]);

  useEffect(() => {
    const keys = location.pathname.includes("apiKeys")
      ? ["apiKeys"]
      : location.pathname.includes("services/rpc")
      ? ["rpc-service"]
      : location.pathname.includes("services/token-forwarding/wallets")
      ? ["token-forwarding-wallets"]
      : location.pathname.includes("services/token-forwarding/transactions")
      ? ["token-forwarding-transactions"]
      : location.pathname.includes("services")
      ? ["services-overview"]
      : location.pathname.includes("billings/overview")
      ? ["billings-overview"]
      : location.pathname.includes("addPaymentCard")
      ? ["add-payment-cards"]
      : location.pathname.includes("buyCredits")
      ? ["buy-credits"]
      : location.pathname.includes("billings")
      ? ["billings"]
      : location.pathname.includes("services")
      ? ["services"]
      : location.pathname.includes("settings")
      ? ["settings"]
      : location.pathname.includes("contactUs")
      ? ["contactUs"]
      : ["overview"];
    setSelectedKey(keys);
  }, [location.pathname]);

  const items = [
    {
      label: "Overview",
      key: "overview",
      icon: <FileSearchOutlined />,
      style: { padding: "0px 24px", fontSize: 14 },
      onClick: () => {
        navigate(`/account/${accountsServices.getCurrentAccount()!.account?.id}/overview`);
      }
    },
    {
      label: "Services",
      key: "services",
      icon: <CustomerServiceOutlined />,
      children: [
        {
          label: "Overview",
          key: "services-overview",
          onClick: () => {
            navigate(`/account/${accountsServices.getCurrentAccount()!.account?.id}/services`);
          }
        },
        {
          label: "RPC",
          key: "rpc-service",
          onClick: () => {
            navigate(`/account/${accountsServices.getCurrentAccount()!.account?.id}/services/rpc`);
          }
        },
        accountsServices.getCurrentAccount()?.account?.features?.tokenForwardingEnabled
          ? {
              label: "Token Forwarding",
              key: "token-forwarding-service",
              children: [
                {
                  label: "Wallets",
                  key: "token-forwarding-wallets",
                  onClick: () => {
                    navigate(
                      `/account/${accountsServices.getCurrentAccount()!.account?.id}/services/token-forwarding/wallets`
                    );
                  }
                },
                {
                  label: "Transactions",
                  key: "token-forwarding-transactions",
                  onClick: () => {
                    navigate(
                      `/account/${
                        accountsServices.getCurrentAccount()!.account?.id
                      }/services/token-forwarding/transactions`
                    );
                  }
                }
              ]
            }
          : null
      ]
    },
    {
      label: "API Keys",
      key: "apiKeys",
      icon: <KeyOutlined />,
      style: { padding: "0px 24px" },
      onClick: () => {
        navigate(`/account/${accountsServices.getCurrentAccount()!.account?.id}/apiKeys`);
      }
    },
    {
      label: "Billings",
      key: "billings",
      icon: <DollarOutlined />,
      children: [
        {
          label: "Overview",
          key: "billings-overview",
          onClick: () => {
            navigate(`/account/${accountsServices.getCurrentAccount()!.account?.id}/billings/overview`);
          }
        },
        {
          label: "Buy Credits",
          key: "buy-credits",
          onClick: () => {
            navigate(`/account/${accountsServices.getCurrentAccount()!.account?.id}/billings/buyCredits`);
          }
        },
        {
          label: "Add Payment Cards",
          key: "add-payment-cards",
          onClick: () => {
            navigate(`/account/${accountsServices.getCurrentAccount()!.account?.id}/billings/addPaymentCard`);
          }
        }
      ]
    },
    {
      label: "Settings",
      key: "settings",
      icon: <SettingOutlined />,
      style: { padding: "0px 24px" },
      onClick: () => {
        navigate(`/account/${accountsServices.getCurrentAccount()!.account?.id}/settings`);
      }
    },
    {
      label: "Documentation",
      key: "documentation",
      icon: <ReadOutlined />,
      style: { padding: "0px 24px" },
      onClick: () => {
        window.open("https://docs.ebs.ezat.io/ebs-documentation/", "_blank");
      }
    },
    {
      label: "Contact Us",
      key: "contactUs",
      icon: <FormOutlined />,
      style: { padding: "0px 24px" },
      onClick: () => {
        navigate(`/account/${accountsServices.getCurrentAccount()!.account?.id}/contactUs`);
      }
    }
  ];

  return (
    <Menu
      inlineCollapsed={false}
      defaultSelectedKeys={selectedKey}
      selectedKeys={selectedKey}
      defaultOpenKeys={["overview", "billings"]}
      mode="inline"
      items={items}
    />
  );
};

export const SideMenu = boundError(SideMenuRaw);
