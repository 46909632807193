import { IAccountDetails } from "../models/interfaces/AccountDetails";
import { apiAxios, callApi, concatApiQuery, responseDataHandler } from "./axios";
import { PaginationType } from "./types";
import { Buffer } from "buffer";

export const doGetApiKeys = ({ limit, offset } = { offset: 0, limit: 10 }) =>
  apiAxios.get(concatApiQuery("/api/v1.0/key", { limit, offset })).then(({ data }) => responseDataHandler(data));

export const doCreateApiKey = (apiKeyName) =>
  apiAxios.post("/api/v1.0/key/", { apiKeyName }).then(({ data }) => responseDataHandler(data));

export const doUpdateApiKeyName = (apiKeyId, apiKeyName) =>
  apiAxios.post(`/api/v1.0/key/${apiKeyId}/updateName`, { apiKeyName }).then(({ data }) => responseDataHandler(data));

export const doActivateApiKey = (apiKeyId) =>
  apiAxios.post(`/api/v1.0/key/${apiKeyId}/activate`, {}).then(({ data }) => responseDataHandler(data));

export const doDeactivateApiKey = (apiKeyId) =>
  apiAxios.post(`/api/v1.0/key/${apiKeyId}/deactivate`, {}).then(({ data }) => responseDataHandler(data));

export const doDeleteApiKey = (apiKeyId) =>
  apiAxios.delete(concatApiQuery(`/api/v1.0/key/${apiKeyId}`, {})).then(({ data }) => responseDataHandler(data));
