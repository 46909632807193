import {
  doCreateStripeSetupIntents,
  doDepositAmount,
  doDownloadReceipt,
  doRemovePaymentMethod,
  doSetAsDefault,
  doUpdateCard
} from "../apis/paymentApis";
import { UserServices } from "./UserServices";

export class PaymentService {
  static paymentService: PaymentService;

  static getInstance() {
    if (!this.paymentService) {
      this.paymentService = new PaymentService();
    }

    return this.paymentService;
  }

  async createStripeSetupIntents(accountId?) {
    return await doCreateStripeSetupIntents(accountId);
  }

  async setCardAsDefault(cardId, accountId?) {
    return await doSetAsDefault(cardId, accountId);
  }

  async updateCard(cardId, expireMonth, expireYear) {
    return await doUpdateCard(cardId, expireMonth, expireYear);
  }

  async removePaymentMethod(cardId) {
    return await doRemovePaymentMethod(cardId);
  }

  async depositAmount(cardId, depositAmount) {
    return await doDepositAmount(cardId, depositAmount);
  }

  async downloadReceipt(transactionId) {
    return await doDownloadReceipt(transactionId);
  }
}
