// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-list-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%; }

.account-list-table {
  width: 100%;
  margin: 20px 0px; }
  .account-list-table .ant-table {
    overflow: scroll; }

.create-account-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 30px; }

.description-no-wrap {
  white-space: break-spaces; }

@media only screen and (min-width: 1201px) {
  .description-no-wrap {
    white-space: nowrap; } }
`, "",{"version":3,"sources":["webpack://./src/styles/account.scss"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,WAAW,EAAA;;AAEb;EACE,WAAW;EACX,gBAAgB,EAAA;EAFlB;IAII,gBAAgB,EAAA;;AAGpB;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;EACvB,YAAY,EAAA;;AAEd;EACE,yBAAyB,EAAA;;AAG3B;EACE;IACE,mBAAmB,EAAA,EACpB","sourcesContent":["@import \"./variables.scss\";\n\n.account-list-container {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  width: 100%;\n}\n.account-list-table {\n  width: 100%;\n  margin: 20px 0px;\n  .ant-table {\n    overflow: scroll;\n  }\n}\n.create-account-form {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: center;\n  margin: 30px;\n}\n.description-no-wrap {\n  white-space: break-spaces;\n}\n\n@media only screen and (min-width: $screen-xl-min) {\n  .description-no-wrap {\n    white-space: nowrap;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
