import React, { useEffect, useState } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { showError, showSuccess } from "../utlis/messages";
import { Spin } from "antd";

const StripeForm = ({ stripeBtnRef, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    try {
      setLoading(true);
      const { error, setupIntent } = await stripe.confirmSetup({
        elements,
        confirmParams: { return_url: "https://localhost:3000/" },
        redirect: "if_required"
      });

      if (error) {
        showError(error?.message);
      } else {
        onSuccess(setupIntent);
      }
    } catch (err) {
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <div style={{ textAlign: "left", color: "#6d6e78", fontSize: "0.75rem" }}>
          The card will only be charged when you set up auto payments or when you confirm a one time payment.
        </div>
        <button ref={stripeBtnRef} disabled={!stripe} style={{ visibility: "hidden" }}>
          Verify
        </button>
      </form>
    </Spin>
  );
};

export default StripeForm;
