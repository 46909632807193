import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Input, Modal, Spin, Tabs, Table, Tag, Divider, Card } from "antd";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { boundError } from "../../utlis/boundError";
import { useSelector } from "react-redux";
import { Button, Space } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { showError, showSuccess } from "../../utlis/messages";
import { AccountsServices } from "../../../services";
import { ButtonPrimary } from "../../atoms/ButtonPrimary";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import PaymentSection from "./payment";
import DepositSection from "./deposit";
import TransactionSection from "./transactionSection";
import UsageSection from "./usageSection";
import NavigationBar from "../../molecules/NavigationBar";
import { FundViewOutlined, CreditCardFilled, UnlockOutlined, NodeCollapseOutlined } from "@ant-design/icons";

const BillingOverviewRaw = () => {
  const isAuthenticated = useSelector((state) => state);
  const accountsServices = AccountsServices.getInstance();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [accountName, setAccountName] = useState("");
  const [accountTransactions, setAccountTransactions] = useState([]);
  const [resourceUsage, setResourceUsage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState<any>();
  const [refresh, setRefresh] = useState(false);
  const [showTransactionHistory, setShowTransactionHistory] = useState(true);
  const [showUsage, setShowUsage] = useState(false);
  const isMounted = useRef(true);
  const location = useLocation();

  interface DataType {
    [x: string]: any;
    id: string;
    brand: string;
    exp_month: number;
    exp_year: number;
    last4: string;
    key: number;
  }

  useEffect(() => {
    fetchAccountDetails();
    fetchAccountDetailsTransactions();
    navigatePageAccountUsage(1);
    return () => {
      isMounted.current = false;
    };
  }, [refresh]);

  const fetchAccountDetails = async () => {
    try {
      setLoading(true);
      await accountsServices.assembleAccountByIdByFetchingRemotely({
        accountId: accountsServices.currentAccountId,
        setAsCurrentAccount: true
      });
      const account = accountsServices.getCurrentAccount();

      let cards: DataType[] = [];
      let key = 0;
      (account?.cards || []).forEach((card) =>
        cards.push({
          ...card,
          key: ++key
        })
      );
      setCards(cards);
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAccountDetailsTransactions = async () => {
    try {
      setLoading(true);
      const accountTransactions = await accountsServices.getAccountTransactions();
      setAccountTransactions(accountTransactions);
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const navigatePageAccountTransactions = async (page) => {
    try {
      setLoading(true);
      const accountTransactions = await accountsServices.getAccountTransactions({
        limit: 10,
        offset: (page - 1) * 10
      });
      setAccountTransactions(accountTransactions);
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const navigatePageAccountUsage = async (page) => {
    try {
      setLoading(true);
      const result = await accountsServices.getAccountResourceUsage({
        accountId: accountsServices.currentAccountId,
        limit: 10,
        offset: (page - 1) * 10,
        order: "desc"
      });
      setResourceUsage(result);
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshPage = () => {
    setRefresh(!refresh);
  };

  return (
    <Spin spinning={loading}>
      <div className="main-container center-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%"
          }}
        >
          <NavigationBar nonClickableSubItem="Billings" currentPage="Overview" />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: "5vw"
            }}
          >
            <Card style={{ flex: 1 }}>
              <p
                style={{
                  color: "rgb(0 0 0 / 65%)"
                }}
              >
                Available Credits (USD)
              </p>
              <p
                style={{
                  fontSize: 24
                }}
              >
                {accountsServices.currentAccountBalance}
              </p>
              <Button
                type="primary"
                onClick={() =>
                  navigate(`/account/${accountsServices.getCurrentAccount()!.account?.id}/billings/buyCredits`)
                }
              >
                Buy Credits
              </Button>
            </Card>
            <Card style={{ flex: 1 }}>
              <p
                style={{
                  color: "rgb(0 0 0 / 65%)"
                }}
              >
                Add Payment Cards
              </p>
              <p>
                <CreditCardFilled
                  style={{
                    fontSize: 40
                  }}
                />
              </p>
              <Button
                type="primary"
                onClick={() =>
                  navigate(`/account/${accountsServices.getCurrentAccount()!.account?.id}/billings/addPaymentCard`)
                }
              >
                Add Card
              </Button>
            </Card>
          </div>
          <Tabs
            style={{ width: 310, marginTop: 30 }}
            defaultActiveKey="Transaction History"
            items={[
              {
                key: "Transaction History",
                label: `Transaction History`
              },
              {
                key: "Service Usage History",
                label: `Service Usage History`
              }
            ]}
            onChange={(key: string) => {
              setShowTransactionHistory(key === "Transaction History");
              setShowUsage(key === "Service Usage History");
            }}
          />

          {showTransactionHistory && (
            <TransactionSection
              data={accountTransactions}
              refreshPage={refreshPage}
              navigatePage={navigatePageAccountTransactions}
            />
          )}
          {showUsage && (
            <UsageSection data={resourceUsage} refreshPage={refreshPage} navigatePage={navigatePageAccountUsage} />
          )}
          <p style={{ fontSize: 14, textAlign: "left" }}>
            Kindly be advised that there is a possibility of a one-day delay for the posting of transactions and service
            usage.
          </p>
        </div>
        {/* <PaymentSection cards={cards} refreshPage={refreshPage} />
          <Divider />
          <DepositSection cards={cards} refreshPage={refreshPage} />
          <Divider />
          <Divider /> */}
      </div>
    </Spin>
  );
};

const BillingOverview = boundError(BillingOverviewRaw);
export default BillingOverview;
