import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Input, Modal, Spin, Tabs } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { boundError } from "../../utlis/boundError";
import { loginSuccess } from "../../../redux/actions";
import { ButtonPrimary } from "../../atoms/ButtonPrimary";
import { LOGIN_TABS } from "../../../constants/types";
import { showError, showSuccess } from "../../utlis/messages";
import { UserServices } from "../../../services";
import { AwsService } from "../../../services/AwsServices";
import { AuthWrapper } from "./AuthWrapper";

const ForgotPasswordRaw = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const sendRequest = async (values) => {
    try {
      setLoading(true);
      const { email } = values;
      if (!email) {
        return showError("Please enter an email");
      }
      const result = await AwsService.getInstance().resetPassword({
        email
      });
      if (result?.CodeDeliveryDetails?.Destination) {
        showSuccess(`Verification code to reset password has been sent to: ${email}.`);
        navigate("/reset-password", { state: { email } });
      }
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <AuthWrapper>
        <div className="login-container">
          <div className="login-box">
            <div className="back-to-login" onClick={() => navigate("/login")}>
              <LeftOutlined style={{ marginRight: 10 }} />
              Back to login
            </div>
            <div
              style={{
                paddingLeft: 20,
                paddingTop: 30,
                fontSize: 30,
                fontWeight: 500
              }}
            >
              {LOGIN_TABS.RESET}
            </div>
            <Form
              style={{ padding: "20px" }}
              onFinish={sendRequest}
              layout="vertical"
              form={form}
              name="login-form"
              id="login-form"
            >
              <Form.Item label="Email" name="email" normalize={(value) => value.replace(" ", "").toLowerCase()}>
                <Input allowClear id="email" autoFocus type="email" onChange={() => setLoginError("")} />
              </Form.Item>

              <ButtonPrimary htmlType="submit" type="primary" size={"medium"} block>
                Send request
              </ButtonPrimary>
            </Form>
          </div>
        </div>
      </AuthWrapper>
    </Spin>
  );
};

const ForgotPassword = boundError(ForgotPasswordRaw);
export default ForgotPassword;
