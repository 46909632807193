import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Input, Modal, Spin, Tabs, Table, Tag, Divider } from "antd";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { boundError } from "../../utlis/boundError";
import { useSelector } from "react-redux";
import { Button, Space } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { showError, showSuccess } from "../../utlis/messages";
import { AccountsServices } from "../../../services";
import AccountInformationSection from "./accountInformation";
import AccountAccessSection from "./accountAccess";
import NavigationBar from "../../molecules/NavigationBar";

const SettingOverviewRaw = () => {
  const isAuthenticated = useSelector((state) => state);
  const accountsServices = AccountsServices.getInstance();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [accountName, setAccountName] = useState("");
  const [accountDetails, setAccountDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [account, setAccount] = useState(accountsServices.getCurrentAccount());
  const isMounted = useRef(true);
  const location = useLocation();
  const [refresh, setRefresh] = useState(false);

  interface DataType {
    id: string;
    name: string;
    roleName: string;
    status: string;
    userId: string;
    key: number;
  }

  useEffect(() => {
    fetchAccountDetails();
    return () => {
      isMounted.current = false;
    };
  }, [refresh]);

  const fetchAccountDetails = async () => {
    await accountsServices.assembleAccountByIdByFetchingRemotely({
      accountId: accountsServices.currentAccountId,
      setAsCurrentAccount: true
    });
    setAccount(accountsServices.getCurrentAccount());
  };

  const deleteAccount = async () => {
    try {
      setLoading(true);
      navigate("/accounts");
      showSuccess("Account has been deleted");
    } catch (error: any) {
      showError(error);
    } finally {
      setDeleteModal(false);
      setLoading(false);
    }
  };

  const refreshPage = () => {
    setRefresh(!refresh);
  };

  return (
    <Spin spinning={loading}>
      <div className="main-container center-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%"
          }}
        >
          <NavigationBar currentPage="Settings" />
          <Space direction="vertical" size={"large"}>
            <AccountInformationSection account={account?.account} refreshPage={refreshPage} />
            <AccountAccessSection account={account?.account} refreshPage={refreshPage} />
          </Space>
          {/* <Divider />
          <Button
            type="primary"
            danger
            style={{
              width: 150
            }}
            onClick={() => setDeleteModal(true)}
          >
            Delete account
          </Button> */}
        </div>
      </div>
      <Modal
        title={`Deleting Account ${account?.account?.name}`}
        open={deleteModal}
        onCancel={() => {
          setDeleteModal(false);
        }}
        onOk={deleteAccount}
        okText="Delete"
        cancelText="Cancel"
        centered
        okType="danger"
      >
        <p>Account {account?.account?.name} will be permanently deleted, related services may be effected</p>
      </Modal>
    </Spin>
  );
};

const SettingOverview = boundError(SettingOverviewRaw);
export default SettingOverview;
