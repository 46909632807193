import { doCreateApiKeyApi, doGetAccountDetailsApi } from "../apis/accountApis";
import {
  doGetAccountCardListApi,
  doSubscribePaymentApi,
  doAddPaymentCardToAccountApi,
  doSetDefaultCardApi
} from "../apis/paymentApis";
import { EventUtils, EVENT_KEYS } from "../components/utlis/events";
import { IAccount, IAccountDetails, IPayment } from "./interfaces/AccountDetails";
import { ICard } from "./interfaces/Card";

export class Account {
  account: IAccount | undefined;
  payment: IPayment | undefined;
  cards: ICard[] = [];

  constructor(account: IAccountDetails) {
    this.factoryAccountFromApiData(account);
  }

  toJson() {
    return {
      account: this.account,
      payment: this.payment
    };
  }

  factoryAccountFromApiData(account: IAccountDetails) {
    this.account = account?.account;
    this.payment = account?.payment;
  }

  hasSubscription() {
    return this?.payment?.subscription?.id !== undefined;
  }

  hasPayment() {
    return this?.payment?.defaultCard !== null;
  }

  async fetchAndUpdateAccountDetail() {
    if (this.account?.id) {
      const result = await doGetAccountDetailsApi({
        accountId: this.account?.id
      });
      this.factoryAccountFromApiData(result);
      // EventUtils.emitEvent(EVENT_KEYS.ON_CURRENT_ACCOUNT_UPDATE);
    }
  }

  async fetchAndUpdateCardList() {
    const result = await doGetAccountCardListApi(this.account?.id);
    this.cards = result?.cards;
    EventUtils.emitEvent(EVENT_KEYS.ON_CURRENT_ACCOUNT_UPDATE);
    // }

    return result;
  }

  async subscribePayment({
    updateAccountAfterAction
  }: {
    updateAccountAfterAction?: boolean;
  } = {}) {
    const result = await doSubscribePaymentApi();
    if (updateAccountAfterAction) {
      await this.fetchAndUpdateAccountDetail();
    }
    return result;
  }

  async addPaymentCard({
    cardToken,
    updateAccountAfterAction
  }: {
    cardToken: string;
    updateAccountAfterAction?: boolean;
  }) {
    const result = await doAddPaymentCardToAccountApi({ cardToken });
    if (updateAccountAfterAction) {
      await this.fetchAndUpdateAccountDetail();
      await this.fetchAndUpdateCardList();
    }

    return result;
  }

  async setDefaultCard({ cardId, updateAccountAfterAction }: { cardId: string; updateAccountAfterAction?: boolean }) {
    const result = await doSetDefaultCardApi({ cardId });
    if (updateAccountAfterAction) {
      await this.fetchAndUpdateAccountDetail();
      await this.fetchAndUpdateCardList();
    }

    return result;
  }

  async createApiKey({ name, updateAccountAfterAction }: { name: string; updateAccountAfterAction?: boolean }) {
    const result = await doCreateApiKeyApi({ name });

    if (updateAccountAfterAction) {
      await this.fetchAndUpdateAccountDetail();
      await this.fetchAndUpdateCardList();
    }

    return result;
  }
}
