import React, { useState, useContext, useRef, useEffect } from "react";
import { Button, Card, Form, Input, List, Modal, Spin, Tabs } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { boundError } from "../../utlis/boundError";
import { loginSuccess } from "../../../redux/actions";
import { ButtonPrimary } from "../../atoms/ButtonPrimary";
import { LOGIN_TABS } from "../../../constants/types";
import { showError, showSuccess } from "../../utlis/messages";
import { UserServices } from "../../../services";
import { AwsService } from "../../../services/AwsServices";
import { AuthWrapper } from "./AuthWrapper";

const AmbassadorProgramRaw = () => {
  const userServices = UserServices.getInstance();
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const data = (userServices?.user?.ambassador?.linkedUsers || []).map((user) => user.email) as string[];

  return (
    <Spin spinning={loading}>
      <div className="main-container center-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%"
          }}
        >
          <h3 style={{ textAlign: "left" }}>Ambassador Program</h3>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: "5vw"
            }}
          >
            <Card style={{ flex: 1 }}>
              <p
                style={{
                  color: "rgb(0 0 0 / 65%)"
                }}
              >
                Current Balance (USD)
              </p>
              <p
                style={{
                  fontSize: 24
                }}
              >
                {userServices?.user?.ambassador?.balance || 0}
              </p>
            </Card>
            <Card style={{ flex: 1 }}>
              <p
                style={{
                  color: "rgb(0 0 0 / 65%)"
                }}
              >
                Ambassador Code
              </p>
              <p
                style={{
                  fontSize: 24
                }}
              >
                {userServices?.user?.ambassador?.code || "000000"}
              </p>
            </Card>
            <Card style={{ flex: 1 }}>
              <p
                style={{
                  color: "rgb(0 0 0 / 65%)"
                }}
              >
                Reward Rate
              </p>
              <p
                style={{
                  fontSize: 24
                }}
              >
                {(userServices?.user?.ambassador?.rewardRate || 0) * 100}%
              </p>
            </Card>
          </div>
          <hr />
          <List
            style={{ border: "1px solid #f0f0f0" }}
            size="large"
            header={<div style={{ textAlign: "left", color: "rgb(0 0 0 / 65%)" }}>Linked User Email</div>}
            bordered
            dataSource={data}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        </div>
      </div>
    </Spin>
  );
};

const AmbassadorProgram = boundError(AmbassadorProgramRaw);
export default AmbassadorProgram;
