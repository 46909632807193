export const LOGIN_TABS = {
  LOGIN: "Login",
  SIGNUP: "Sign up",
  RESET: "Reset password"
};

export namespace Network {
  export namespace RPC {
    export enum Name {
      ETHEREUM_MAINNET = "Ethereum Mainnet",
      ETHEREUM_SEPOLIA = "Ethereum Sepolia",
      BSC_MAINNET = "BSC Mainnet",
      BSC_TESTNET = "BSC Testnet",
      POLYGON_MAINNET = "Polygon Mainnet",
      POLYGON_TESTNET = "Polygon Testnet",
      METABIT_MAINNET = "Metabit Mainnet",
      METABIT_TESTNET = "Metabit Testnet",
      AURORA_MAINNET = "Aurora Mainnet",
      AURORA_TESTNET = "Aurora Testnet"
    }
  }
  export namespace TokenForwardingWallet {
    export enum Name {
      ETHEREUM_SEPOLIA = "ETHEREUM_SEPOLIA",
      ETHEREUM_MAINNET = "ETHEREUM_MAINNET",
      BITCOIN_MAINNET = "BITCOIN_MAINNET",
      BITCOIN_TESTNET = "BITCOIN_TESTNET",
      BSC_MAINNET = "BSC_MAINNET",
      BSC_TESTNET = "BSC_TESTNET",
      POLYGON_MAINNET = "POLYGON_MAINNET",
      POLYGON_AMOY = "POLYGON_AMOY",
      AURORA_MAINNET = "AURORA_MAINNET",
      AURORA_TESTNET = "AURORA_TESTNET"
    }
  }
}
export namespace AccountTransaction {
  export enum Type {
    DEPOSIT_ONE_TIME = "DEPOSIT_ONE_TIME",
    DEPOSIT_AUTO = "DEPOSIT_AUTO",
    WITHDRAW_RESOURCE_USAGE = "WITHDRAW_RESOURCE_USAGE",
    WELCOME_CREDIT = "WELCOME_CREDIT",
    ADMIN_MANUAL_DEPOSIT = "ADMIN_MANUAL_DEPOSIT"
  }
  export function generateDescription(type: AccountTransaction.Type) {
    let description = "";
    switch (type) {
      case AccountTransaction.Type.DEPOSIT_ONE_TIME:
        description = "One-time deposit";
        break;
      case AccountTransaction.Type.DEPOSIT_AUTO:
        description = "Auto deposit";
        break;
      case AccountTransaction.Type.ADMIN_MANUAL_DEPOSIT:
        description = "Manual deposit";
        break;
      case AccountTransaction.Type.WITHDRAW_RESOURCE_USAGE:
        description = "Resource usage withdrawal";
        break;
      case AccountTransaction.Type.WELCOME_CREDIT:
        description = "Welcome credit";
        break;
      default:
        description = type;
    }
    return description;
  }
}
