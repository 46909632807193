import Icon from "@ant-design/icons";
import amex from "../../assets/cardTypes/amex.svg";
import jcb from "../../assets/cardTypes/jcb.svg";
import mastercard from "../../assets/cardTypes/mastercard.svg";
import unionpay from "../../assets/cardTypes/unionpay.svg";
import visa from "../../assets/cardTypes/visa.svg";

interface IBrandIconProps {
  brand: string;
}
export const BrandIcon: React.FunctionComponent<IBrandIconProps> = ({ brand }) => {
  switch (brand) {
    case "amex":
      return <Icon component={() => <img style={{ width: 32, height: 32 }} src={amex} />} />;
    case "jcb":
      return <Icon component={() => <img style={{ width: 32, height: 32 }} src={jcb} />} />;
    case "mastercard":
      return <Icon component={() => <img style={{ width: 32, height: 32 }} src={mastercard} />} />;
    case "unionpay":
      return <Icon component={() => <img style={{ width: 32, height: 32 }} src={unionpay} />} />;
    case "visa":
    default:
      return <Icon component={() => <img style={{ width: 32, height: 32 }} src={visa} />} />;
  }
};
