import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Input, Modal, Spin, Tabs, Table, Tag, Dropdown, Popconfirm } from "antd";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { boundError } from "../../utlis/boundError";
import { useSelector } from "react-redux";
import { Button, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { showError, showSuccess } from "../../utlis/messages";
import { AccountsServices } from "../../../services";
import { ApiKeyService } from "../../../services/ApiKeyServices";
import { v4 } from "uuid";
import { CopyButton } from "../../atoms/CopyButton";
import TextArea from "antd/es/input/TextArea";
import { DownOutlined, FormOutlined, DeleteOutlined, PauseOutlined, CaretRightOutlined } from "@ant-design/icons";
import { render } from "@testing-library/react";
import NavigationBar from "../../molecules/NavigationBar";

const ApiKeyOverviewRaw = () => {
  const isAuthenticated = useSelector((state) => state);
  const accountsServices = AccountsServices.getInstance();
  const apiKeyService = ApiKeyService.getInstance();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showApiKeyHashModal, setShowApiKeyHashModal] = useState(false);
  const [showUpdateNameModal, setShowUpdateNameModal] = useState(false);
  const [keys, setKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteApiKeyPop, setShowDeleteApiKeyPop] = useState(false);
  const [showDeactivateApiKeyPop, setShowDeactivateApiKeyPop] = useState(false);
  const [apiKeyName, setApiKeyName] = useState("");
  const [selectedApi, setSelectedApi] = useState({ id: "", name: "" });
  const [apiKey, setApiKey] = useState({ id: "", key: "" });
  const [refresh, setRefresh] = useState(false);

  interface DataType {
    id: string;
    name: string;
    roleName: string;
    status: string;
    userId: string;
    key: number;
  }

  useEffect(() => {
    fetchApiKeys();
  }, [refresh]);

  const fetchApiKeys = async () => {
    try {
      setLoading(true);
      const key = await apiKeyService.getApiKeys();
      setKeys(key?.apiKeys || []);
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const createApiKey = async () => {
    if (!apiKeyName) {
      return showError("API key name is required.");
    }
    try {
      setLoading(true);
      const keys = await apiKeyService.createApiKey(apiKeyName);
      setApiKey({ id: keys.key.keyId, key: keys.key.key });
      showSuccess(`${apiKeyName} has been created.`);
      refreshPage();
      setShowApiKeyHashModal(true);
    } catch (error: any) {
      showError(error);
    } finally {
      setShowCreateModal(false);
      setLoading(false);
      setApiKeyName("");
    }
  };

  const updateApiKeyName = async () => {
    if (!apiKeyName && !selectedApi.name) {
      return showError("API  key name is required.");
    }
    try {
      setLoading(true);
      const keys = await apiKeyService.updateApiKeyName(selectedApi.id, apiKeyName || selectedApi.name);
      setShowUpdateNameModal(false);
      showSuccess(`${apiKeyName || selectedApi.name} has been updated.`);
      refreshPage();
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
      setApiKeyName("");
    }
  };

  const deleteApiKey = async (apiKeyId) => {
    try {
      setLoading(true);
      const keys = await apiKeyService.deleteApiKey(apiKeyId);
      refreshPage();
      showSuccess("API key has been deleted.");
    } catch (error: any) {
      showError(error);
    } finally {
      setShowDeleteApiKeyPop(false);
      setLoading(false);
    }
  };

  const activateApiKey = async (apiKeyId) => {
    try {
      setLoading(true);
      const keys = await apiKeyService.activateApiKey(apiKeyId);
      refreshPage();
      showSuccess("API key has been activated.");
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const deactivateApiKey = async (apiKeyId) => {
    try {
      setLoading(true);
      const keys = await apiKeyService.deactivateApiKey(apiKeyId);
      refreshPage();
      showSuccess("API  key has been deactivated.");
    } catch (error: any) {
      showError(error);
    } finally {
      setShowDeactivateApiKeyPop(false);
      setLoading(false);
    }
  };

  const refreshPage = () => {
    setRefresh(!refresh);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "API Key Name",
      dataIndex: "name",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          {record.name}&nbsp;
          <FormOutlined
            style={{ cursor: "pointer", color: "#007bff" }}
            onClick={() => {
              setShowUpdateNameModal(true);
              setSelectedApi(record);
            }}
          />
        </div>
      )
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, record) => (
        <span
          style={{
            padding: "1px 8px",
            backgroundColor: record.status === "ACTIVE" ? "rgba(246, 255, 237, 1)" : "rgba(244, 244, 244, 1)",
            borderRadius: 2,
            color: record.status === "ACTIVE" ? "rgba(82, 196, 26, 1)" : "rgba(93, 96, 92, 1)",
            border: record.status === "ACTIVE" ? "1px solid #B7EB8F" : "1px solid rgba(217, 217, 217, 1)"
          }}
        >
          {record.status}
        </span>
      )
    },
    {
      title: "Edit",
      dataIndex: ["card"],
      key: "id",
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          {record.status !== "ACTIVE" && (
            <Button
              style={{
                padding: 0,
                color: "#35b994"
              }}
              type="link"
              title="Activate"
              onClick={() => {
                activateApiKey(record.id);
              }}
            >
              Activate
            </Button>
          )}
          {record.status === "ACTIVE" && (
            <Button
              style={{
                padding: 0
              }}
              type="link"
              title="Deactivate"
              onClick={() => {
                setShowDeactivateApiKeyPop(!showDeactivateApiKeyPop);
                setSelectedApi(record);
              }}
            >
              <Popconfirm
                title="Confirm to deactivate API key?"
                description="API key cannot be used to access any services once deactivated."
                open={selectedApi.id === record.id && showDeactivateApiKeyPop}
                onConfirm={() => {
                  deactivateApiKey(record.id);
                }}
                okButtonProps={{ loading: loading }}
                onCancel={() => setShowDeactivateApiKeyPop(false)}
              >
                Deactivate
              </Popconfirm>
            </Button>
          )}

          <Button
            style={{
              padding: 0
            }}
            type="link"
            danger
            title="Delete"
            disabled={record.status !== "INACTIVE"}
            onClick={() => {
              setShowDeleteApiKeyPop(!showDeleteApiKeyPop);
              setSelectedApi(record);
            }}
          >
            <Popconfirm
              title="Confirm to delete API key?"
              description="API key will be permanently deleted and cannot be used to access any services."
              open={selectedApi.id === record.id && showDeleteApiKeyPop}
              onConfirm={() => deleteApiKey(record.id)}
              okButtonProps={{ loading: loading }}
              onCancel={() => setShowDeleteApiKeyPop(false)}
            >
              Delete
            </Popconfirm>
          </Button>
        </Space>
      )
    }
  ];

  return (
    <Spin spinning={loading}>
      <div className="main-container center-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%"
          }}
        >
          <NavigationBar currentPage="API  Keys" />
          <p
            style={{
              fontSize: 20,
              fontWeight: 600,
              textAlign: "left"
            }}
          >
            API Key Management
          </p>
          <div
            style={{
              // width: 80,
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              marginBottom: 20
            }}
          >
            <Button
              style={{ borderRadius: 2 }}
              type="primary"
              shape="round"
              // icon={<PlusOutlined />}
              size={"middle"}
              onClick={() => setShowCreateModal(true)}
            >
              Generate New API Keys
            </Button>
          </div>
          <Table columns={columns} dataSource={keys} pagination={false} rowKey="id" />
        </div>
      </div>
      <Modal
        title="Generate New API Keys"
        open={showCreateModal}
        onCancel={() => {
          setShowCreateModal(false);
          setApiKeyName("");
        }}
        onOk={createApiKey}
        okText="Generate"
        cancelText="Cancel"
      >
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 20 }}>
          <span style={{ color: "red" }}>*</span>API Key Name:
          <Input
            style={{ width: "50%", marginLeft: 10 }}
            value={apiKeyName}
            onChange={(e) => setApiKeyName(e.target.value)}
          />
        </div>
      </Modal>
      <Modal
        title="Generate New API Keys"
        open={showApiKeyHashModal}
        onCancel={() => {
          setShowApiKeyHashModal(false);
          setApiKeyName("");
        }}
        onOk={() => {
          setShowApiKeyHashModal(false);
          setApiKeyName("");
        }}
        okText="Done"
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <p style={{ color: "rgba(0, 0, 0, 0.45)" }}>
          Please note: API Key and ID are only displayed once. Please save them safely before clicking “Done”.
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
            flexDirection: "column",
            width: "90%"
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              width: "100%"
            }}
          >
            <span>API Key ID:</span>
            <Input value={apiKey.id} style={{ width: "70%", marginLeft: 10 }} />
            <CopyButton text={apiKey.id} style={{ marginLeft: 10 }} />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 20,
              justifyContent: "end",
              width: "100%"
            }}
          >
            <span>API Key:</span>
            <Input value={apiKey.key} style={{ width: "70%", marginLeft: 10 }} />
            <CopyButton text={apiKey.key} />
          </div>
        </div>
      </Modal>
      <Modal
        title="Edit API Key"
        open={showUpdateNameModal}
        onCancel={() => {
          setShowUpdateNameModal(false);
          setApiKeyName("");
        }}
        onOk={updateApiKeyName}
        okText="Update"
        cancelText="Cancel"
      >
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 20 }}>
          <span>API Key Name:</span>
          <Input
            style={{ width: "50%", marginLeft: 10 }}
            value={apiKeyName || selectedApi.name}
            onChange={(e) => setApiKeyName(e.target.value)}
          />
        </div>
      </Modal>
    </Spin>
  );
};

const ApiKeyOverview = boundError(ApiKeyOverviewRaw);
export default ApiKeyOverview;
