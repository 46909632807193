import { Layout, Dropdown, Menu, Drawer, Button, Image, MenuProps } from "antd";
import { useNavigate } from "react-router-dom";
import { boundError } from "../../utlis/boundError";
import { useSelector } from "react-redux";
import { IRootState } from "../../../redux/store";
import { isMobile } from "react-device-detect";
import { SettingOutlined } from "@ant-design/icons";
import { showError } from "../../utlis/messages";
import { UserServices } from "../../../services";
import logo from "../../../assets/images/light.svg";
import { useEffect } from "react";

const { Header: AntHeader } = Layout;

const HeaderRaw = () => {
  const navigate = useNavigate();
  const userServices = UserServices.getInstance();
  const user = useSelector((state: IRootState) => state.auth.user);

  useEffect(() => {
    setTimeout(() => {
      fetchUserInfo();
    }, 1000);
  }, []);

  const fetchUserInfo = async () => {
    try {
      await userServices.getUserInfo();
    } catch (error: any) {
      showError(error);
    }
  };

  const signOut = async () => {
    try {
      localStorage.clear();
      await userServices.signOut();
    } catch (error: any) {
      showError(error);
    }
  };

  const items: MenuProps["items"] = [
    {
      label: "Create account",
      key: "createAccount",
      onClick: () => navigate("/accounts/create")
    },
    {
      label: "My accounts",
      key: "myAccount",
      onClick: () => navigate("/accounts")
    },
    {
      label: "Change password",
      key: "changePassword",
      onClick: () => navigate("/change-password")
    },
    userServices?.user?.ambassador
      ? {
          label: "Ambassador Program",
          key: "ambassadorProgram",
          onClick: () => navigate("/ambassador-program")
        }
      : null,
    {
      label: "Sign out",
      key: "signOut",
      onClick: () => signOut()
    }
  ];

  return (
    <AntHeader className="sticky-top">
      <div className="header-container">
        <div
          style={{
            display: "flex",
            height: 64
          }}
        >
          <img style={{ height: 60 }} src={logo} />
          <div
            style={{
              fontSize: "16px",
              letterSpacing: "0.5px",
              color: "white",
              fontWeight: 500,
              marginLeft: 5,
              paddingTop: 11
            }}
          >
            Blockchain Service
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <Dropdown menu={{ items }}>
            <a onClick={(e) => e.preventDefault()}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "$primary",
                  color: "white",
                  maxWidth: "400px"
                }}
              >
                {!isMobile && user.attributes.email}
                <SettingOutlined
                  style={{
                    marginLeft: 5
                  }}
                />
              </div>
            </a>
          </Dropdown>
        </div>
      </div>
    </AntHeader>
  );
};

export const Header = boundError(HeaderRaw);
