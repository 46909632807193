import { IAccountDetails } from "../models/interfaces/AccountDetails";
import { apiAxios, callApi, concatApiQuery, responseDataHandler } from "./axios";
import { PaginationType } from "./types";
import { Buffer } from "buffer";

export const doGetUserInfo = () =>
  apiAxios.get(concatApiQuery("/api/v1.0/user", {})).then(({ data }) => responseDataHandler(data));

export const doGetAmbassadorInfo = () =>
  apiAxios.get(concatApiQuery("/api/v1.0/user/ambassador", {})).then(({ data }) => responseDataHandler(data));
