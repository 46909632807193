import { Avatar, Badge, Breadcrumb, Card, Dropdown, Modal, Skeleton, Space, Spin } from "antd";
import { boundError } from "../utlis";
import { AccountsServices } from "../../services";
import { Link } from "react-router-dom";
import { VALID_ROUTES } from "../router";
import Meta from "antd/es/card/Meta";
import { SettingOutlined, EditOutlined, PlusCircleOutlined, NodeCollapseOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import moment from "moment";
import { BrandIcon } from "../atoms/BrandIcon";
import { useState } from "react";
import EditCardModal from "./EditCardModal";
import { PaymentService } from "../../services/PaymentServices";
import { showSuccess, showError } from "../utlis/messages";

const PaymentCardRaw = ({ card, numOfCards, refreshPage }) => {
  const accountsServices = AccountsServices.getInstance();
  const paymentService = PaymentService.getInstance();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditCardModal, setShowEditCardModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const setAsDefault = async (cardId) => {
    try {
      setLoading(true);
      await paymentService.setCardAsDefault(cardId);
      showSuccess("Payment card has been set as default.");
      refreshPage();
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteCardIntent = (card) => {
    if (numOfCards === 1) {
      return showError("Please add a payment card to proceed.");
    }
    if (card.defaultPaymentMethod === true) {
      return showError("Cannot delete default payment card, please add another default card before deletion.");
    }
    setShowDeleteModal(true);
  };

  const deleteCard = async (cardId = card.id) => {
    try {
      setLoading(true);
      await paymentService.removePaymentMethod(cardId);
      showSuccess("Payment card has been removed.");
      setShowDeleteModal(false);
      refreshPage();
    } catch (error: any) {
      showError(error);
      setShowDeleteModal(false);
    } finally {
      setLoading(false);
    }
  };

  const updateCard = async (expireMonth, expireYear) => {
    try {
      setLoading(true);
      await paymentService.updateCard(card.id, expireMonth, expireYear);
      showSuccess("Payment card updated.");
      setShowEditCardModal(false);
      refreshPage();
    } catch (error: any) {
      showError(error);
      setShowEditCardModal(false);
    } finally {
      setLoading(false);
    }
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Set as default",
      onClick: () => {
        setAsDefault(card.id);
      },
      disabled: card.defaultPaymentMethod === true
    },
    {
      key: "2",
      label: "Delete",
      style: { color: "red" },
      onClick: () => {
        deleteCardIntent(card);
      }
    }
  ];

  const BadgeCard = () => {
    return (
      <Badge.Ribbon text="Default" color="cyan">
        <Card
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <Space>
                <BrandIcon brand={card.card.brand} />
                {card.card.brand.toUpperCase()} •••• {card.card.last4}{" "}
              </Space>
            </div>
          }
          style={{ width: 300, height: 176 }}
          actions={[
            <Dropdown
              trigger={["click"]}
              menu={{
                items
              }}
            >
              <SettingOutlined key="setting" />
            </Dropdown>,
            <EditOutlined
              key="edit"
              onClick={() => {
                setShowEditCardModal(true);
              }}
            />
          ]}
          bodyStyle={{ textAlign: "left" }}
        >
          Expires{" "}
          {moment()
            .month(card.card.exp_month - 1)
            .format("MMMM")}{" "}
          {card.card.exp_year}
        </Card>
      </Badge.Ribbon>
    );
  };

  const NoBadgeCard = () => {
    return (
      <Card
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <Space>
              <BrandIcon brand={card.card.brand} />
              {card.card.brand.toUpperCase()} •••• {card.card.last4}{" "}
            </Space>
          </div>
        }
        style={{ width: 300, height: 176 }}
        actions={[
          <Dropdown
            trigger={["click"]}
            menu={{
              items
            }}
          >
            <SettingOutlined key="setting" />
          </Dropdown>,
          <EditOutlined
            key="edit"
            onClick={() => {
              setShowEditCardModal(true);
            }}
          />
        ]}
        bodyStyle={{ textAlign: "left" }}
      >
        Expires{" "}
        {moment()
          .month(card.card.exp_month - 1)
          .format("MMMM")}{" "}
        {card.card.exp_year}
      </Card>
    );
  };

  return (
    <Spin spinning={loading}>
      <div style={{ width: 300 }}>
        {card.defaultPaymentMethod && <BadgeCard />}
        {!card.defaultPaymentMethod && <NoBadgeCard />}
      </div>

      <EditCardModal
        open={showEditCardModal}
        onCancel={() => setShowEditCardModal(false)}
        onOk={updateCard}
        data={card}
      />
      <Modal
        title="Remove Payment Method"
        open={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onOk={() => deleteCard()}
        okText="Remove"
        cancelText="Cancel"
      >
        <p>The payment method will no longer be usable after removing.</p>
      </Modal>
    </Spin>
  );
};

const PaymentCard = boundError(PaymentCardRaw);
export default PaymentCard;
