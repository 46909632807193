import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.scss";
import "./styles/base.scss";
import "./styles/antd.scss";
import "./styles/atoms.scss";
import "./styles/account.scss";
import "./styles/registration.scss";
import "./styles/mainLayout.scss";
import "./styles/toggle.scss";
import store from "./redux/store";
import TagManager from "react-gtm-module";

if (process.env.ENV == "prod") {
  TagManager.initialize({
    gtmId: "G-SQHVY2SKX7"
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback="loading">
        <App />
      </Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
