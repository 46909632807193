import { IAccountDetails } from "../models/interfaces/AccountDetails";
import { apiAxios, callApi, concatApiQuery, responseDataHandler } from "./axios";
import { PaginationType } from "./types";
import { Buffer } from "buffer";

export const doCreateGasFeeFundingWallet = (network, name) =>
  apiAxios
    .post("/api/v1.0/token-forwarding/gas-fee-funding-wallet", { network, name })
    .then(({ data }) => responseDataHandler(data));

export const doCreateDestinationWallet = (network, name, destinationPublicAddress) =>
  apiAxios
    .post("/api/v1.0/token-forwarding/destination", { network, name, destinationPublicAddress })
    .then(({ data }) => responseDataHandler(data));

export const doGetGasFeeFundingWallets = () =>
  apiAxios
    .get(concatApiQuery("/api/v1.0/token-forwarding/gas-fee-funding-wallet", {}))
    .then(({ data }) => responseDataHandler(data));

export const doGetDestinationWallets = () =>
  apiAxios
    .get(concatApiQuery("/api/v1.0/token-forwarding/destination", {}))
    .then(({ data }) => responseDataHandler(data));

export const doGetSupportedNetworksAndTokens = () =>
  apiAxios
    .get(concatApiQuery("/api/v1.0/token-forwarding/supported-network-and-token", {}))
    .then(({ data }) => responseDataHandler(data));

export const doCreateTokenForwardingWallet = ({
  network,
  token,
  smartContractAddress,
  webhookSetting,
  minimumForwardingAmount,
  destinationPublicAddress,
  gasFeeFundingWalletId
}) =>
  apiAxios
    .post("/api/v1.0/token-forwarding/wallet", {
      network,
      token,
      smartContractAddress,
      webhookSetting,
      minimumForwardingAmount,
      destinationPublicAddress,
      gasFeeFundingWalletId
    })
    .then(({ data }) => responseDataHandler(data));

export const doGetTokenForwardingWallets = () =>
  apiAxios
    .get(concatApiQuery("/api/v1.0/token-forwarding/wallet", { statusIn: `["ENABLED", "DISABLED"]` }))
    .then(({ data }) => responseDataHandler(data));

export const doDeleteTokenForwardingWallet = (walletId) =>
  apiAxios
    .delete(concatApiQuery(`/api/v1.0/token-forwarding/wallet/${walletId}`, {}))
    .then(({ data }) => responseDataHandler(data));

export const doDeleteGasFeeFundingWallet = (walletId) =>
  apiAxios
    .delete(concatApiQuery(`/api/v1.0/token-forwarding/gas-fee-funding-wallet/${walletId}`, {}))
    .then(({ data }) => responseDataHandler(data));

export const doDeleteDestinationWallet = (network, destinationPublicAddress) =>
  apiAxios
    .delete(`/api/v1.0/token-forwarding/destination`, { data: { network, destinationPublicAddress } })
    .then(({ data }) => responseDataHandler(data));

export const doUpdateForwardingWalletStatus = (walletId, newStatus, reason?) =>
  apiAxios
    .post("/api/v1.0/token-forwarding/wallet/status", {
      id: walletId,
      reason,
      newStatus
    })
    .then(({ data }) => responseDataHandler(data));

export const doRefreshGasFeeFundingWalletBalance = (walletId) =>
  apiAxios
    .post("/api/v1.0/token-forwarding/gas-fee-funding-wallet/balance", {
      id: walletId
    })
    .then(({ data }) => responseDataHandler(data));

export const doUpdateDestinationWalletName = (name, destinationPublicAddress, network) =>
  apiAxios
    .post("/api/v1.0/token-forwarding/destination/name", {
      name,
      destinationPublicAddress,
      network
    })
    .then(({ data }) => responseDataHandler(data));

export const doUpdateGasFeeFundingWalletName = (id, name) =>
  apiAxios
    .post("/api/v1.0/token-forwarding/gas-fee-funding-wallet/name", {
      id,
      name
    })
    .then(({ data }) => responseDataHandler(data));

export const doFetchTokenForwardingTransactions = ({
  tokenForwardingWalletId,
  address
}: {
  tokenForwardingWalletId?: string;
  address?: string;
}) =>
  apiAxios
    .get(concatApiQuery("/api/v1.0/token-forwarding/transaction", { tokenForwardingWalletId, address }))
    .then(({ data }) => responseDataHandler(data));

export const doGetSmartContractInfo = (network, smartContractAddress) =>
  apiAxios
    .get(concatApiQuery("/api/v1.0/token-forwarding/smart-contract-info", { network, smartContractAddress }))
    .then(({ data }) => responseDataHandler(data));

export const doTestWebhook = (url, header, headerValue) =>
  apiAxios
    .post("/api/v1.0/token-forwarding/testWebhook", {
      url,
      header,
      headerValue
    })
    .then(({ data }) => responseDataHandler(data));
