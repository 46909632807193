import React, { useEffect, useRef, useState } from "react";
import { useStripe, useElements, PaymentElement, Elements } from "@stripe/react-stripe-js";
import { showError, showSuccess } from "../utlis/messages";
import { Modal, Spin } from "antd";
import { boundError } from "../utlis";
import { loadStripe } from "@stripe/stripe-js";
import { AccountsServices } from "../../services";
import { PaymentService } from "../../services/PaymentServices";
import StripeForm from "./StripeForm";

const AddCardModalRaw = ({ open, onCancel, stripeInfo, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const accountsServices = AccountsServices.getInstance();
  const paymentService = PaymentService.getInstance();
  const stripeBtnRef = useRef<any>();
  const stripePromise = loadStripe(process.env.STRIPE_PK!);

  const appearance = {
    theme: "stripe"
  };
  const options = {
    clientSecret: stripeInfo.client_secret
    // appearance,
  };

  return (
    <Spin spinning={loading}>
      <Modal
        title="Add Payment Method"
        open={open}
        onCancel={onCancel}
        onOk={() => stripeBtnRef.current?.click()}
        okText="Add"
        cancelText="Cancel"
      >
        <Elements options={options} stripe={stripePromise}>
          <StripeForm stripeBtnRef={stripeBtnRef} onSuccess={onSuccess} />
        </Elements>
      </Modal>
    </Spin>
  );
};

const AddCardModal = boundError(AddCardModalRaw);
export default AddCardModal;
