import {
  doAddUser,
  doCreateAccountApi,
  doGetAccountDetailsApi,
  doGetAccountListApi,
  doGetAccountTransactions,
  doRemoveUser,
  doUpdateAccountNameApi,
  doUpdateAccountSettingsApi,
  doGetAccountResourceUsage,
  doRedeemBonus,
  doFinishCreateAccountProcess,
  doCheckIfAccountValidForFeature
} from "../apis/accountApis";
import { EventUtils, EVENT_KEYS } from "../components/utlis/events";
import { Account } from "../models/Account";
import { IAccountListItem } from "../models/interfaces/Account";
import { IAccount, IAccountDetails, IPayment } from "../models/interfaces/AccountDetails";
import { ICard } from "../models/interfaces/Card";
import Big from "big.js";

export class AccountsServices {
  static accountsServices: AccountsServices;

  offset: number | undefined;
  limit: number | undefined;
  total: number | undefined;
  count: number | undefined;

  account: IAccount | undefined;
  payment: IPayment | undefined;
  cards: ICard[] = [];
  accounts: IAccountListItem[] = [];
  private currentAccount: Account | undefined;

  static getInstance() {
    if (!this.accountsServices) {
      this.accountsServices = new AccountsServices();
    }

    return this.accountsServices;
  }

  get currentAccountName() {
    return this.getCurrentAccount()?.account?.name ?? "";
  }

  get currentAccountId() {
    return this.getCurrentAccount()?.account?.id ?? "";
  }

  get currentAccountBalance() {
    const balance = new Big(this.getCurrentAccount()?.account?.balance ?? "0");
    const amountPending = new Big(this.getCurrentAccount()?.account?.amountPending ?? "0");
    const availableCredit = balance.minus(amountPending);
    return availableCredit.toFixed(2);
  }

  // destroyCurrentAccount() {
  //   this.setCurrentAccount(undefined)
  // }

  getCurrentAccount() {
    return this.currentAccount;
  }

  findAccountById(accountId: string) {
    return this.accounts?.find((account) => account?.id === accountId);
  }

  setCurrentAccount(account: Account | undefined) {
    this.currentAccount = account;
    EventUtils.emitEvent(EVENT_KEYS.ON_CURRENT_ACCOUNT_UPDATE);
  }

  async fetchUserAccounts({ offset = 0, limit = 100 } = {}) {
    const result = await doGetAccountListApi({ offset, limit });
    this.offset = result?.offset;
    this.limit = result?.limit;
    this.total = result?.total;
    this.count = result?.count;

    if (result?.accounts && result?.accounts?.length > 0) {
      this.accounts = result?.accounts;
    }

    return result;
  }

  createNewAccount = doCreateAccountApi;
  updateAccountName = doUpdateAccountNameApi;
  updateAccountSettings = doUpdateAccountSettingsApi;
  getAccountTransactions = doGetAccountTransactions;
  finishCreateAccountProcess = doFinishCreateAccountProcess;
  addUser = doAddUser;
  removeUser = doRemoveUser;
  redeemBonus = doRedeemBonus;
  fetchAccountDetailsById = async ({ accountId, returnInstance }: { accountId: string; returnInstance?: boolean }) => {
    const result: IAccountDetails = await doGetAccountDetailsApi({ accountId });
    const accountInstance = new Account(result);
    if (returnInstance) {
      return accountInstance;
    }

    return result;
  };
  getAccountResourceUsage = doGetAccountResourceUsage;

  /// factory
  async assembleAccountByIdByFetchingRemotely({
    accountId,
    setAsCurrentAccount = true
  }: {
    accountId: string;
    setAsCurrentAccount?: boolean;
  }): Promise<Account> {
    const account = (await this.fetchAccountDetailsById({
      accountId,
      returnInstance: true
    })) as Account;

    if (setAsCurrentAccount) {
      this.setCurrentAccount(account);
    }
    await account?.fetchAndUpdateCardList();
    const tokenForwardingEnabled = await doCheckIfAccountValidForFeature("TOKEN_FORWARDING");
    account.account!.features = {};
    account.account!.features.tokenForwardingEnabled = tokenForwardingEnabled.result;
    localStorage.setItem("currentAccount", JSON.stringify(account));

    return account;
  }
}
