import React, { useEffect, useRef, useState } from "react";
import { useStripe, useElements, PaymentElement, Elements } from "@stripe/react-stripe-js";
import { showError, showSuccess } from "../utlis/messages";
import { Modal, Spin, Collapse, Input, Select, Form } from "antd";
import { boundError } from "../utlis";
import { AccountsServices } from "../../services";
import { PaymentService } from "../../services/PaymentServices";
const { Option } = Select;
const { Panel } = Collapse;

const EditCardModalRaw = ({ open, onCancel, onOk, data }) => {
  const [loading, setLoading] = useState(false);
  const [expireMonth, setExpireMonth] = useState(12);
  const [expireYear, setExpireYear] = useState(2025);

  useEffect(() => {
    setExpireMonth(data?.card?.exp_month || 1);
    setExpireYear(data?.card?.exp_year || 2025);
  }, [data]);

  return (
    <Spin spinning={loading}>
      <Modal
        centered
        title="Edit Card"
        open={open}
        onCancel={onCancel}
        onOk={() => {
          onOk(expireMonth, expireYear);
        }}
        okText="Update"
        cancelText="Cancel"
        style={{
          maxWidth: "400px"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "45%"
            }}
          >
            Expiry month
            <Select
              defaultValue={(data?.card?.exp_month || 1).toString().padStart(2, 0)}
              style={{
                width: "100%"
              }}
              onChange={(value) => {
                setExpireMonth(value);
              }}
            >
              {["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"].map((month) => (
                <Option value={month}>{month}</Option>
              ))}
            </Select>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "45%"
            }}
          >
            Expiry year
            <Select
              defaultValue={data?.card?.exp_year || 2025}
              onChange={(value) => {
                setExpireYear(value);
              }}
              style={{
                width: "100%"
              }}
            >
              {["2023", "2024", "2025", "2026", "2027", "2028", "2029"].map((year) => (
                <Option value={year}>{year}</Option>
              ))}
            </Select>
          </div>
        </div>
        {/* <Collapse ghost>
          <Panel header="More options" key="1">
            Cardholder name
            <Input
              onChange={(e) => {}}
              style={{
                margin: "5px 0px"
              }}
            />
            Street
            <Input
              onChange={(e) => {}}
              style={{
                margin: "5px 0px"
              }}
            />
            Street (line 2)
            <Input
              onChange={(e) => {}}
              style={{
                margin: "5px 0px"
              }}
            />
            City
            <Input
              onChange={(e) => {}}
              style={{
                margin: "5px 0px"
              }}
            />
            Zip/Postal
            <Input
              onChange={(e) => {}}
              style={{
                margin: "5px 0px"
              }}
            />
            State/Province
            <Input
              onChange={(e) => {}}
              style={{
                margin: "5px 0px"
              }}
            />
            Country
            <Input
              onChange={(e) => {}}
              style={{
                margin: "5px 0px"
              }}
            />
          </Panel>
        </Collapse> */}
      </Modal>
    </Spin>
  );
};

const EditCardModal = boundError(EditCardModalRaw);
export default EditCardModal;
