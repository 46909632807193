import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import accountListRouter from "../pages/accounts/_router";
import accountRouter from "../pages/account/_router";
import billingRouter from "../pages/billings/_router";
import apiKeyRouter from "../pages/apiKeys/_router";
import serviceRouter from "../pages/services/_router";
import settingRouter from "../pages/settings/_router";
import { MainLayout } from "../pages/mainLayout/MainLayout";
import registrationRouter from "../pages/registration/_router";
import AccountsList from "../pages/accounts/List";
import { useSelector } from "react-redux";
import Login from "../pages/registration/Login";

interface IRoute {
  element: any;
  path: string;
}

const routes: IRoute[] = [
  ...accountListRouter,
  ...registrationRouter,
  ...accountRouter,
  ...billingRouter,
  ...apiKeyRouter,
  ...serviceRouter,
  ...settingRouter
];

export const VALID_ROUTES = routes.map((route) => route.path.split("/")[route.path.split("/").length - 1]);

const Router = () => {
  const isAuthenticated = useSelector<{ auth: { user } }>((state) => state.auth.user);

  const renderRoute = (item: IRoute, index: number) => <Route key={index} element={item.element} path={item.path} />;

  if (!isAuthenticated) {
    localStorage.clear();
    return (
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Login />} />
          <Route path="*" element={<Navigate to="/" />} />
          {registrationRouter.map(renderRoute)}
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<MainLayout />}>
          {routes.map(renderRoute)}
          <Route path="/" element={<Navigate to="/accounts" />} />
          <Route path="*" element={<Navigate to="/accounts" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
