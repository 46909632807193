export const awsConfig = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.COGNITO_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.COGNITO_USER_POOL_ID,

    userPoolWebClientId: process.env.COGNITO_CLIENT_ID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: "USER_PASSWORD_AUTH",
    oauth: {
      domain: process.env.COGNITO_DOMAIN,
      scopes: ["openid", "email", "phone", "profile", "aws.cognito.signin.user.admin"],
      redirectSignIn: process.env.REDIRECT_URL,
      redirectSignOut: process.env.REDIRECT_URL,
      responseType: "token"
    }
  }
};
