import { Amplify, Auth } from "aws-amplify";
import { awsConfig } from "../../config/aws-config";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib-esm/types/Auth";

export class AwsService {
  static awsService: AwsService;

  static getInstance() {
    if (!this.awsService) {
      this.awsService = new AwsService();
      Amplify.configure(awsConfig);
    }

    return this.awsService;
  }

  async signIn(email, password) {
    return await Auth.signIn(email, password);
  }

  async signOut() {
    return await Auth.signOut();
  }

  async signUp(email, password, reCAPTCHAToken?, attributes = {}) {
    await Auth.signUp({
      username: email,
      password,
      validationData: { reCAPTCHAToken: reCAPTCHAToken },
      attributes: {
        email,
        ...attributes
      }
    });
  }

  async awsConfirmSignUp(email: string, code: string) {
    return await Auth.confirmSignUp(email, code);
  }

  async resendSignUpCode(email) {
    return await Auth.resendSignUp(email);
  }

  async resetPassword({ email }: { email: string }) {
    return await Auth.forgotPassword(email);
  }

  async forgotPasswordSubmit({ username, code, newPassword }: any) {
    return await Auth.forgotPasswordSubmit(username, code, newPassword);
  }

  async getCurrentAuthenticatedUser() {
    return await Auth.currentAuthenticatedUser();
  }

  async changePassword({ user, oldPassword, newPassword }: { user: any; oldPassword: string; newPassword: string }) {
    return await Auth.changePassword(user, oldPassword, newPassword);
  }

  async signInWithThirdParty(provider: CognitoHostedUIIdentityProvider) {
    return await Auth.federatedSignIn({ provider });
  }
}
