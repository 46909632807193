import React from "react";
import { boundError } from "../utlis";

const CardButtonRaw = ({ icon, text }) => {
  return (
    <div className="card-button-container">
      <div style={{ flex: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}>{icon}</div>
      <div style={{ flex: 1 }}>
        <span className="card-button-text">{text}</span>
      </div>
    </div>
  );
};

export const CardButton = boundError(CardButtonRaw);
