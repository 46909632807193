import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Form,
  Input,
  Modal,
  Spin,
  Tabs,
  Table,
  Tag,
  TableColumnsType,
  Badge,
  Dropdown,
  InputNumber,
  Switch,
  Pagination
} from "antd";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { boundError } from "../../utlis/boundError";
import { useSelector } from "react-redux";
import { Button, Space, Divider } from "antd";
import { PlusOutlined, DownloadOutlined, LinkOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { showError, showSuccess, showWarning } from "../../utlis/messages";
import { AccountsServices } from "../../../services";
import moment from "moment";
import { PaymentService } from "../../../services/PaymentServices";
import { Buffer } from "buffer";
import { AccountTransaction } from "../../../constants/types";

interface DataType {
  [x: string]: any;
  id: string;
  brand: string;
  exp_month: number;
  exp_year: number;
  last4: string;
  key: number;
}
interface IStripeInfo {
  client_secret: string;
  customerId: string;
  setupIntentId: string;
  accountId: string;
}

const TransactionSectionRaw = ({ data, refreshPage, navigatePage }) => {
  const isAuthenticated = useSelector((state) => state);
  const accountsServices = AccountsServices.getInstance();
  const paymentService = PaymentService.getInstance();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [defaultCard, setDefaultCard] = useState();
  const [typeFilter, setTypeFilter] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const set = new Set();
    (data?.transactions || []).map((transaction) => {
      set.add({
        text: transaction.type,
        value: transaction.type
      });
    });
    const arr: any = Array.from(set);
    const seen = new Set();
    const filteredArr = arr.filter((el) => {
      const duplicate = seen.has(el.value);
      seen.add(el.value);
      return !duplicate;
    });
    filteredArr.forEach((arr) => (arr.text = AccountTransaction.generateDescription(arr.text)));
    setTypeFilter(filteredArr);
  }, [data]);

  const downloadReceipt = async (transactionId) => {
    try {
      setLoading(true);
      const data = await paymentService.downloadReceipt(transactionId);

      const buffer = Buffer.from(data.pdf.data);
      const blob = new Blob([buffer]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = data.fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Transaction ID",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Transaction Type",
      dataIndex: "type",
      key: "type",
      filters: typeFilter,
      filterMode: "tree",
      filterSearch: true,
      // onFilter: (value, record) => record.type === value,
      onFilter: (value, record) => record.type === value,
      render: (_, record) => <div>{AccountTransaction.generateDescription(record.type)}</div>
    },
    {
      title: "Amount (USD)",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => a.amount - b.amount,
      render: (_, record) => (
        <span
          style={{
            color: record.amount > 0 ? "rgba(32, 195, 48, 0.85)" : "rgba(195, 51, 32, 0.85)"
          }}
        >
          {record.amount > 0 && "+"}
          {Number(record.amount).toFixed(5)}
        </span>
      )
    },
    {
      title: "Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
      render: (_, record) => <div>{moment(record.updatedAt).format("YYYY-MM-DD HH:mm:ss")}</div>
    },
    {
      title: "Receipt",
      dataIndex: "receipt",
      key: "receipt",
      render: (_, record) => {
        if (record?.metadata?.stripe?.charges?.data[0].receipt_url) {
          return (
            <div
              className="div-flex-center"
              style={{
                color: "#007bff",
                justifyContent: "flex-start"
              }}
              // onClick={() => downloadReceipt(record.id)}
              onClick={() => window.open(record.metadata.stripe.charges.data[0].receipt_url, "_blank")}
            >
              <LinkOutlined /> <a>Open</a>
              {/* <DownloadOutlined /> <a>Download</a> */}
            </div>
          );
        }
      }
    }
  ];

  return (
    <Spin spinning={loading}>
      <Table columns={columns} dataSource={data.transactions} pagination={false} rowKey="id" />
      <Pagination
        style={{ textAlign: "right", marginTop: 10 }}
        // size="small"
        pageSize={10}
        defaultCurrent={1}
        total={data?.totalCount}
        showSizeChanger={false}
        onChange={navigatePage}
        responsive={true}
      />
    </Spin>
  );
};

const TransactionSection = boundError(TransactionSectionRaw);
export default TransactionSection;
