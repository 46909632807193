import DepositSection from "./deposit";
import BillingOverview from "./overview";
import PaymentSection from "./payment";

const billingRouter = [
  { path: "/account/:accountId/billings/overview", element: <BillingOverview /> },
  { path: "/account/:accountId/billings/addPaymentCard", element: <PaymentSection /> },
  { path: "/account/:accountId/billings/buyCredits", element: <DepositSection /> }
];

export default billingRouter;
