import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Form,
  Input,
  Modal,
  Spin,
  Tabs,
  Table,
  Tag,
  TableColumnsType,
  Badge,
  Dropdown,
  InputNumber,
  Switch,
  Pagination
} from "antd";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { boundError } from "../../utlis/boundError";
import { useSelector } from "react-redux";
import { Button, Space, Divider } from "antd";
import { PlusOutlined, DownloadOutlined, LinkOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { showError, showSuccess, showWarning } from "../../utlis/messages";
import { AccountsServices } from "../../../services";
import moment from "moment";
import { PaymentService } from "../../../services/PaymentServices";
import { Buffer } from "buffer";
import { AccountTransaction } from "../../../constants/types";

interface DataType {
  [x: string]: any;
  id: string;
  brand: string;
  exp_month: number;
  exp_year: number;
  last4: string;
  key: number;
}
interface IStripeInfo {
  client_secret: string;
  customerId: string;
  setupIntentId: string;
  accountId: string;
}

const UsageSectionRaw = ({ data, refreshPage, navigatePage }) => {
  const isAuthenticated = useSelector((state) => state);
  const accountsServices = AccountsServices.getInstance();
  const paymentService = PaymentService.getInstance();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [defaultCard, setDefaultCard] = useState();
  const [typeFilter, setTypeFilter] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const set = new Set();
    (data?.accountResourceUsage || []).map((item) => {
      set.add({
        text: item.resourceName,
        value: item.resourceName
      });
    });
    const arr: any = Array.from(set);
    const seen = new Set();
    const filteredArr = arr.filter((el) => {
      const duplicate = seen.has(el.value);
      seen.add(el.value);
      return !duplicate;
    });
    setTypeFilter(filteredArr);
  }, [data]);

  const columns: ColumnsType<DataType> = [
    {
      title: "Resource Name",
      dataIndex: "resourceName",
      key: "type",
      filters: typeFilter,
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.resourceName === value,
      render: (_, record) => <div>{record.resourceName.replace(/_/g, " ")}</div>
    },
    {
      title: "Usage",
      dataIndex: "usage",
      key: "usage"
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status"
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix()
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price"
    }
  ];

  return (
    <Spin spinning={loading}>
      <Table columns={columns} dataSource={data.accountResourceUsage} pagination={false} rowKey="id" />
      <Pagination
        style={{ textAlign: "right", marginTop: 10 }}
        // size="small"
        pageSize={10}
        defaultCurrent={1}
        total={data?.totalCount}
        showSizeChanger={false}
        onChange={navigatePage}
        responsive={true}
      />
    </Spin>
  );
};

const UsageSection = boundError(UsageSectionRaw);
export default UsageSection;
