import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Input, Modal, Spin, Tabs, Table, Tag } from "antd";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { boundError } from "../../utlis/boundError";
import { useSelector } from "react-redux";
import { Button, Space } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { showError, showSuccess } from "../../utlis/messages";
import { AccountsServices, UserServices } from "../../../services";
import { CardButton } from "../../atoms/CardButton";
import { FundViewOutlined, WalletOutlined, UnlockOutlined, NodeCollapseOutlined } from "@ant-design/icons";
import NavigationBar from "../../molecules/NavigationBar";

const ServiceOverviewRaw = () => {
  const isAuthenticated = useSelector((state) => state);
  const userServices = UserServices.getInstance();
  const accountsServices = AccountsServices.getInstance();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [accountName, setAccountName] = useState("");
  const [accountDetails, setAccountDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DataType[]>([]);
  const isMounted = useRef(true);
  const location = useLocation();

  interface DataType {
    id: string;
    name: string;
    roleName: string;
    status: string;
    userId: string;
    key: number;
  }

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Spin spinning={loading}>
      <div className="main-container center-container">
        <NavigationBar currentPage="Services" />
        <div
          style={{
            display: "flex",
            width: "100%"
          }}
        >
          <div className="grid-1">
            <Link to={`/account/${accountsServices.getCurrentAccount()!.account?.id}/services/rpc`}>
              <CardButton
                icon={
                  <UnlockOutlined
                    style={{
                      fontSize: 40
                    }}
                  />
                }
                text="RPC"
              />
            </Link>

            {/* <CardButton
              icon={
                <FundViewOutlined
                  style={{
                    fontSize: 40
                  }}
                />
              }
              text="Address Monitor"
            /> */}
            {/* <CardButton
              icon={
                <WalletOutlined
                  style={{
                    fontSize: 40
                  }}
                />
              }
              text="Wallet"
            /> */}
            {accountsServices.getCurrentAccount()?.account?.features?.tokenForwardingEnabled ? (
              <Link
                to={`/account/${accountsServices.getCurrentAccount()!.account?.id}/services/token-forwarding/wallets`}
              >
                <CardButton
                  icon={
                    <NodeCollapseOutlined
                      style={{
                        fontSize: 40
                      }}
                    />
                  }
                  text="Token Forwarding"
                />
              </Link>
            ) : (
              <CardButton
                icon={
                  <NodeCollapseOutlined
                    style={{
                      fontSize: 40
                    }}
                  />
                }
                text="Token Forwarding (Coming Soon)"
              />
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};

const ServiceOverview = boundError(ServiceOverviewRaw);
export default ServiceOverview;
