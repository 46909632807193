import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Input, Modal, Spin, Tabs, Table, Tag } from "antd";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { boundError } from "../../../utlis/boundError";
import { useSelector } from "react-redux";
import { Button, Space } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { showError, showSuccess } from "../../../utlis/messages";
import { AccountsServices } from "../../../../services";
import { CardButton } from "../../../atoms/CardButton";
import { FundViewOutlined, WalletOutlined, UnlockOutlined, LeftOutlined } from "@ant-design/icons";
import { CopyButton } from "../../../atoms/CopyButton";
import { Network } from "../../../../constants/types";
import NavigationBar from "../../../molecules/NavigationBar";
import TokenForwardingWalletTable from "./tokenForwardingWalletTable";
import GasFeeFundingWalletTable from "./gasFeeFundingWalletTable";
import {
  doGetDestinationWallets,
  doGetGasFeeFundingWallets,
  doGetTokenForwardingWallets
} from "../../../../apis/tokenForwardingApis";
import DestinationWalletTable from "./destinationWalletTable";

const TokenForwardingWalletRaw = () => {
  const isAuthenticated = useSelector((state) => state);
  const accountsServices = AccountsServices.getInstance();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [showTokenForwardingWallet, setShowTokenForwardingWallet] = useState(true);
  const [showGasFeeFundingWallet, setShowGasFeeFundingWallet] = useState(false);
  const [showDestinationWallet, setShowDestinationWallet] = useState(false);
  const [tokenForwardingWallets, setTokenForwardingWallets] = useState([]);
  const [gasFeeFundingWallets, setGasFeeFundingWallets] = useState([]);
  const [destinationWallets, setDestinationWallets] = useState([]);
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);
  const location = useLocation();
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    fetchWallets();
  }, [refresh]);

  const fetchWallets = async () => {
    try {
      setLoading(true);
      const tokenForwardingWalletResult = await doGetTokenForwardingWallets();
      setTokenForwardingWallets(tokenForwardingWalletResult?.tokenForwardingWallets || []);
      const gasFeeFundingWalletResult = await doGetGasFeeFundingWallets();
      setGasFeeFundingWallets(gasFeeFundingWalletResult?.gasFeeFundingWallets || []);
      const destinationWalletResult = await doGetDestinationWallets();
      setDestinationWallets(destinationWalletResult || []);
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshPage = () => {
    setRefresh(!refresh);
  };

  return (
    <Spin spinning={loading}>
      <div className="main-container center-container">
        <NavigationBar items={["Services"]} subItems={["Token Forwarding"]} currentPage="Wallets" />
        <p
          style={{
            fontSize: 20,
            fontWeight: 600,
            textAlign: "left"
          }}
        >
          Token Forwarding Wallets
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%"
          }}
        >
          <Button
            style={{ width: 120, marginBottom: 10 }}
            type="primary"
            shape="round"
            size={"middle"}
            onClick={() =>
              navigate(
                `/account/${
                  accountsServices.getCurrentAccount()!.account?.id
                }/services/token-forwarding/wallets/create`,
                { state: { gasFeeFundingWallets, destinationWallets } }
              )
            }
          >
            Create Wallet
          </Button>
          <Tabs
            style={{ width: 512 }}
            defaultActiveKey="Token Forwarding Wallets"
            items={[
              {
                key: "Token Forwarding Wallets",
                label: `Token Forwarding Wallets`
              },
              {
                key: "Gas Fee Funding Wallets",
                label: `Gas Fee Funding Wallets`
              },
              {
                key: "Destination Wallets",
                label: `Destination Wallets`
              }
            ]}
            onChange={(key: string) => {
              setShowTokenForwardingWallet(key === "Token Forwarding Wallets");
              setShowGasFeeFundingWallet(key === "Gas Fee Funding Wallets");
              setShowDestinationWallet(key === "Destination Wallets");
            }}
          />
          {showTokenForwardingWallet && (
            <TokenForwardingWalletTable data={tokenForwardingWallets} refreshPage={refreshPage} />
          )}
          {showGasFeeFundingWallet && (
            <GasFeeFundingWalletTable data={gasFeeFundingWallets} refreshPage={refreshPage} />
          )}
          {showDestinationWallet && <DestinationWalletTable data={destinationWallets} refreshPage={refreshPage} />}
        </div>
      </div>
    </Spin>
  );
};

const TokenForwardingWallet = boundError(TokenForwardingWalletRaw);
export default TokenForwardingWallet;
