import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Input, Modal, Spin, Tabs, Table, Tag, TableColumnsType, Badge, Dropdown, Card } from "antd";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { boundError } from "../../utlis/boundError";
import { useSelector } from "react-redux";
import { Button, Space } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { showError, showSuccess } from "../../utlis/messages";
import { AccountsServices } from "../../../services";
import { ButtonPrimary } from "../../atoms/ButtonPrimary";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import { DownOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { PaymentService } from "../../../services/PaymentServices";
import AddCardModal from "../../molecules/AddCardModal";
import EditCardModal from "../../molecules/EditCardModal";
import { BrandIcon } from "../../atoms/BrandIcon";
import { FundViewOutlined, CreditCardFilled, UnlockOutlined, NodeCollapseOutlined } from "@ant-design/icons";
import NavigationBar from "../../molecules/NavigationBar";
import PaymentCard from "../../molecules/PaymentCard";

interface DataType {
  [x: string]: any;
  id: string;
  brand: string;
  exp_month: number;
  exp_year: number;
  last4: string;
  key: number;
}
interface IStripeInfo {
  client_secret: string;
  customerId: string;
  setupIntentId: string;
  accountId: string;
}

const PaymentSectionRaw = () => {
  const isAuthenticated = useSelector((state) => state);
  const accountsServices = AccountsServices.getInstance();
  const paymentService = PaymentService.getInstance();
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);
  const [refresh, setRefresh] = useState(false);
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [cards, setCards] = useState<any>();
  const [stripeInfo, setStripeInfo] = useState<IStripeInfo>({
    client_secret: "",
    customerId: "",
    setupIntentId: "",
    accountId: ""
  });

  useEffect(() => {
    fetchAccountDetails();
    return () => {
      isMounted.current = false;
    };
  }, [refresh]);

  const fetchAccountDetails = async () => {
    try {
      setLoading(true);
      await accountsServices.assembleAccountByIdByFetchingRemotely({
        accountId: accountsServices.currentAccountId,
        setAsCurrentAccount: true
      });
      const account = accountsServices.getCurrentAccount();

      let cards: DataType[] = [];
      let key = 0;
      (account?.cards || []).forEach((card) =>
        cards.push({
          ...card,
          key: ++key
        })
      );
      setCards(cards);
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshPage = () => {
    setRefresh(!refresh);
  };

  const openAddCardModal = async () => {
    try {
      setLoading(true);
      if (!stripeInfo?.client_secret) {
        const result = await paymentService.createStripeSetupIntents(accountsServices.currentAccountId);
        setStripeInfo(result.stripe);
      }
      setShowAddCardModal(true);
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="main-container center-container">
        <NavigationBar nonClickableSubItem="Billings" currentPage="Add Payment Card" />
        <div className="grid-2">
          <Card title="Add New Payment Card" style={{ width: 300, height: 176 }} bodyStyle={{ height: 120 }}>
            <Button
              style={{
                height: "100%",
                padding: 0
              }}
              type="link"
              onClick={openAddCardModal}
            >
              <PlusCircleOutlined
                style={{
                  fontSize: 40
                }}
              />
            </Button>
          </Card>
          {(cards || []).map((card) => (
            <PaymentCard card={card} numOfCards={cards.length} refreshPage={refreshPage} />
          ))}
        </div>

        <AddCardModal
          open={showAddCardModal}
          onCancel={() => setShowAddCardModal(false)}
          stripeInfo={stripeInfo}
          onSuccess={() => {
            showSuccess("Payment card added.");
            setShowAddCardModal(false);
            refreshPage();
          }}
        />
      </div>
    </Spin>
  );
};

const PaymentSection = boundError(PaymentSectionRaw);
export default PaymentSection;
