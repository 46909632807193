import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Input, Modal, Spin, Tabs, Table, Tag, Pagination, Drawer, Collapse } from "antd";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { boundError } from "../../../utlis/boundError";
import { useSelector } from "react-redux";
import { Button, Space } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { showError, showSuccess } from "../../../utlis/messages";
import { AccountsServices } from "../../../../services";
import { CardButton } from "../../../atoms/CardButton";
import { FundViewOutlined, WalletOutlined, UnlockOutlined, SearchOutlined } from "@ant-design/icons";
import { CopyButton } from "../../../atoms/CopyButton";
import { AccountTransaction, Network } from "../../../../constants/types";
import NavigationBar from "../../../molecules/NavigationBar";
import moment from "moment";
import { first4Last4 } from "../../../utlis/helpers";
import { DrawerDetailRow } from "../../../molecules/DrawerDetailRow";
import { doFetchTokenForwardingTransactions } from "../../../../apis/tokenForwardingApis";
const { Panel } = Collapse;

interface DataType {
  [x: string]: any;
}

const TokenForwardingTransactionRaw = () => {
  const isAuthenticated = useSelector((state) => state);
  const accountsServices = AccountsServices.getInstance();
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchTransaction, setSearchTransaction] = useState(location.state?.walletId || "");
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<any>();

  useEffect(() => {
    fetchTransactions();
    window.history.replaceState({}, document.title);
  }, [searchTransaction]);

  const fetchTransactions = async () => {
    try {
      setLoading(true);
      const filter: any = {};
      if (searchTransaction) {
        if (searchTransaction.slice(0, 2) === "0x") {
          filter.address = searchTransaction;
        } else {
          filter.tokenForwardingWalletId = searchTransaction;
        }
      }
      const result = await doFetchTokenForwardingTransactions(filter);
      setTransactions(result?.tokenForwardingTransactions || []);
    } catch (error: any) {
      console.error(error);
      setTransactions([]);
    } finally {
      setLoading(false);
    }
  };

  const drawerOnClose = () => {
    setOpenDrawer(false);
  };

  const rowOnClick = (record) => {
    setOpenDrawer(true);
    setSelectedTransaction(record);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Wallet ID",
      // dataIndex: "metadata",
      key: "id",
      render: (_, record) => <div>{first4Last4(record?.tokenForwardingWalletId)}</div>
    },
    {
      title: "From Wallet Address",
      // dataIndex: "metadata",
      key: "id",
      render: (_, record) => <div>{first4Last4(record?.metadata?.transactionReceipt?.from)}</div>
    },
    {
      title: "To Wallet Address",
      dataIndex: "id",
      key: "id",
      render: (_, record) => <div>{first4Last4(record?.metadata?.transactionReceipt?.to)}</div>
    },
    {
      title: "Transaction Hash",
      dataIndex: "id",
      key: "id",
      render: (_, record) => <div>{first4Last4(record?.metadata?.transactionReceipt?.transactionHash)}</div>
    },
    {
      title: "Network",
      dataIndex: "network",
      key: "network",
      render: (_, record) => <div>{(record?.network || "").replace("_", " ")}</div>
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => a.metadata.originalBalance - b.metadata.originalBalance,
      render: (_, record) => <div>{record.metadata.originalBalance}</div>
    },
    {
      title: "Webhook Status",
      dataIndex: "webhookStatus",
      key: "webhookStatus"
    },
    {
      title: "Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
      render: (_, record) => <div>{moment(record.updatedAt).format("YYYY-MM-DD HH:mm:ss")}</div>
    }
  ];

  const mockDataSource = [
    {
      id: "5209c044-f8bd-47db-9156-411879008e5d",
      tokenForwardingWalletId: "b2be2be8-1c93-4d73-9fb6-a79f238ceb26",
      status: "COMPLETED",
      metadata: {
        confirmations: 249,
        originalBalance: "0.01",
        transactionHash: "0x88455c734e635f7804ae273763acedb501df6979733f0cab5010e8f505cf9543",
        transactionReceipt: {
          to: "0xfcdaa4e7778da79668c4c79513c246d44a36be69",
          from: "0xf0905105af14a2da6be8b702945052eeb1f6e7f3",
          logs: [
            {
              id: "log_be7c1b1e",
              data: "0x00000000000000000000000000000000000000000000000000236a4c456c678000000000000000000000000000000000000000000000000000236a4c456c6780000000000000000000000000000000000000000000000000107fec42a1a19c20000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000010a3568ee70e03a0",
              topics: [
                "0xe6497e3ee548a3372136af2fcb0696db31fc6cf20260707645068bd3fe97f3c4",
                "0x0000000000000000000000000000000000000000000000000000000000001010",
                "0x000000000000000000000000f0905105af14a2da6be8b702945052eeb1f6e7f3",
                "0x000000000000000000000000fcdaa4e7778da79668c4c79513c246d44a36be69"
              ],
              address: "0x0000000000000000000000000000000000001010",
              removed: false,
              logIndex: 5,
              blockHash: "0xc56cd2ce72c2baed2bf7de4259d8b6a2422f410afbf07eb14a38570b521b24a0",
              blockNumber: 36361895,
              transactionHash: "0x88455c734e635f7804ae273763acedb501df6979733f0cab5010e8f505cf9543",
              transactionIndex: 2
            },
            {
              id: "log_867c62bc",
              data: "0x00000000000000000000000000000000000000000000000000001ca62a4f7800000000000000000000000000000000000000000000000000002386f26fc10000000000000000000000000000000000000000000000000ed5036a034a9dcd9a9700000000000000000000000000000000000000000000000000236a4c45718800000000000000000000000000000000000000000000000ed5036a1ff0c81d1297",
              topics: [
                "0x4dfe1bbbcf077ddc3e01291eea2d5c70c2b422b415d95645b9adcfd678cb1d63",
                "0x0000000000000000000000000000000000000000000000000000000000001010",
                "0x000000000000000000000000f0905105af14a2da6be8b702945052eeb1f6e7f3",
                "0x000000000000000000000000f903ba9e006193c1527bfbe65fe2123704ea3f99"
              ],
              address: "0x0000000000000000000000000000000000001010",
              removed: false,
              logIndex: 6,
              blockHash: "0xc56cd2ce72c2baed2bf7de4259d8b6a2422f410afbf07eb14a38570b521b24a0",
              blockNumber: 36361895,
              transactionHash: "0x88455c734e635f7804ae273763acedb501df6979733f0cab5010e8f505cf9543",
              transactionIndex: 2
            }
          ],
          type: "0x0",
          status: true,
          gasUsed: 21000,
          blockHash: "0xc56cd2ce72c2baed2bf7de4259d8b6a2422f410afbf07eb14a38570b521b24a0",
          logsBloom:
            "0x00000000000000000000000000000000000000000800000000000010000000000000000000000020000000100000000000008000000000000000000000000000000000000000800000000000000000800000000000000000000100000000000000000000000000000000000000000000000000000000000080000000000000000080000000000000000000000000002000000000000400000000000000000000200000000000000000000000000000000000000000001000000000000000004000000000000000000001000000000000000000000000800000108000001000000000000000000000000000000000000000000000000000000000000000100000",
          blockNumber: 36361895,
          contractAddress: null,
          transactionHash: "0x88455c734e635f7804ae273763acedb501df6979733f0cab5010e8f505cf9543",
          transactionIndex: 2,
          cumulativeGasUsed: 124415,
          effectiveGasPrice: 1500000016
        }
      },
      createdAt: "2023-06-02T18:27:57.463Z",
      updatedAt: "2023-06-02T18:37:09.035Z",
      webhookId: null
    }
  ];

  return (
    <Spin spinning={loading}>
      <div className="main-container center-container">
        <NavigationBar items={["Services"]} subItems={["Token Forwarding"]} currentPage="Transactions" />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p
            style={{
              fontSize: 20,
              fontWeight: 600,
              textAlign: "left"
            }}
          >
            Token Forwarding Transactions
          </p>
          <Input
            defaultValue={searchTransaction}
            style={{ width: 250, marginBottom: 5 }}
            prefix={<SearchOutlined />}
            placeholder="Wallet ID/ Wallet Address"
            allowClear
            onChange={(e) => {
              setTimeout(() => {
                setSearchTransaction(e.target.value);
              }, 1000);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%"
          }}
        >
          <Table
            rowClassName="pointer-cursor"
            columns={columns}
            dataSource={transactions}
            pagination={false}
            rowKey="id"
            style={{ overflow: "scroll" }}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => rowOnClick(record)
              };
            }}
          />
          <Pagination
            style={{ textAlign: "right", marginTop: 10 }}
            // size="small"
            pageSize={10}
            defaultCurrent={1}
            // total={data?.totalCount}
            showSizeChanger={false}
            // onChange={navigatePage}
            responsive={true}
          />
        </div>
      </div>
      <Drawer
        title="Transaction Details"
        placement="right"
        onClose={drawerOnClose}
        open={openDrawer}
        style={{ marginTop: 64 }}
        bodyStyle={{ marginBottom: 100 }}
        size="large"
      >
        <DrawerDetailRow title="Transaction ID" description={selectedTransaction?.id} />
        <DrawerDetailRow
          title="Token Forwarding Wallet ID"
          description={selectedTransaction?.tokenForwardingWalletId}
        />
        <DrawerDetailRow title="Network" description={(selectedTransaction?.network || "").replace("_", " ")} />
        <DrawerDetailRow title="Amount" description={selectedTransaction?.metadata?.originalBalance} />
        <DrawerDetailRow title="Token" description={selectedTransaction?.token} />
        <DrawerDetailRow
          title="From Wallet Address"
          description={selectedTransaction?.metadata?.transactionReceipt?.from}
          linkable={true}
          linkType="address"
          network={selectedTransaction?.network}
        />
        <DrawerDetailRow
          title="To Wallet Address"
          description={selectedTransaction?.metadata?.transactionReceipt?.to}
          linkable={true}
          linkType="address"
          network={selectedTransaction?.network}
        />
        <DrawerDetailRow
          title="Transaction Hash"
          description={selectedTransaction?.metadata?.transactionReceipt?.transactionHash}
          linkable={true}
          linkType="transaction"
          network={selectedTransaction?.network}
        />
        <DrawerDetailRow
          title="Gas Used"
          description={
            selectedTransaction?.metadata?.transactionReceipt?.gasUsed
              ? `${selectedTransaction?.metadata?.transactionReceipt?.gasUsed} Wei`
              : null
          }
        />
        <DrawerDetailRow
          title="Webhook Status"
          description={selectedTransaction?.webhookStatus ? selectedTransaction?.webhookStatus : null}
        />
        <DrawerDetailRow
          title="Webhook Attempt"
          description={selectedTransaction?.webhookAttempt !== undefined ? selectedTransaction?.webhookAttempt : null}
        />
        <DrawerDetailRow
          title="Webhook Log"
          description={
            selectedTransaction?.webhookLog ? JSON.stringify(selectedTransaction?.webhookLog, undefined, 2) : null
          }
        />
      </Drawer>
    </Spin>
  );
};

const TokenForwardTransaction = boundError(TokenForwardingTransactionRaw);
export default TokenForwardTransaction;
