import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Input, Modal, Spin, Tabs } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useDispatch, useSelector, connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { boundError } from "../../utlis/boundError";
import { loginSuccess } from "../../../redux/actions";
import { ButtonPrimary } from "../../atoms/ButtonPrimary";
import { showError, showSuccess } from "../../utlis/messages";
import ReactCodeInput from "react-code-input";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { UserServices } from "../../../services";
import { AwsService } from "../../../services/AwsServices";
import { AuthWrapper } from "./AuthWrapper";

const SignUpVerificationRaw = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const userServices = UserServices.getInstance();
  const [verificationCode, setVerificationCode] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const isMounted = useRef(true);
  const [resendCountdown, setResendCountdown] = useState(60);

  useEffect(() => {
    const tick = setInterval(() => {
      if (resendCountdown >= 0) setResendCountdown((prev) => prev - 1);
    }, 1000);

    return () => {
      clearInterval(tick);
      isMounted.current = false;
    };
  }, []);

  const verifyCode = async () => {
    const email = location.state.email;
    try {
      setLoading(true);
      const status = await AwsService.getInstance().awsConfirmSignUp(email, verificationCode);
      if (status === "SUCCESS") {
        const userAuth = await userServices.login(location.state);
        dispatch(loginSuccess(userAuth));
        navigate("/");
        showSuccess("Welcome to EZAT dashboard!");
      }
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const resendSignUpCode = async () => {
    if (resendCountdown <= 0) {
      const email = location.state.email;
      await userServices.resendSignUpCode({ email });
      setResendCountdown(60);
    }
  };

  return (
    <Spin spinning={loading}>
      <AuthWrapper>
        <div className="login-container">
          <div className="login-box">
            <div className="back-to-login" onClick={() => navigate("/login")}>
              <LeftOutlined style={{ marginRight: 10 }} />
              Back to login
            </div>
            <div className="verification-box">
              <h5>Email Validation</h5>
              <p style={{ margin: 0, textAlign: "center" }}>A verification code has been sent to your email.</p>
              <ReactCodeInput
                name={""}
                inputMode={"numeric"}
                type="number"
                fields={6}
                autoFocus
                value={""}
                onChange={setVerificationCode}
              />
              <ButtonPrimary type="primary" onClick={verifyCode}>
                Verify
              </ButtonPrimary>
              <div>
                Code hasn't arrived?{" "}
                <span
                  style={{
                    color: resendCountdown >= 0 ? "grey" : "blue",
                    fontWeight: 600,
                    cursor: "pointer"
                  }}
                  onClick={resendSignUpCode}
                >
                  Re-send code
                  {resendCountdown >= 0 ? ` in ${resendCountdown}s` : ""}
                </span>{" "}
              </div>
            </div>
          </div>
        </div>
      </AuthWrapper>
    </Spin>
  );
};

const SignUpVerification = boundError(SignUpVerificationRaw);
export default SignUpVerification;
