import React from "react";
import { Button } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CopyOutlined } from "@ant-design/icons";
import { showSuccess } from "../utlis/messages";
import { boundError } from "../utlis";
const CopyButtonRaw = ({ text, style }) => {
  return (
    <div title="copy to clipboard" style={{ display: "inline-block", marginLeft: 5, ...style }}>
      <CopyToClipboard
        text={text}
        // style={{
        //   borderRadius: 5,
        //   ...style
        // }}
        onCopy={() => showSuccess("Text has been copied to clipboard.")}
      >
        <CopyOutlined />
      </CopyToClipboard>
    </div>
  );
};

export const CopyButton = boundError(CopyButtonRaw);
