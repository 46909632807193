import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Input, Modal, Spin, Tabs } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { boundError } from "../../utlis/boundError";
import { loginSuccess } from "../../../redux/actions";
import { ButtonPrimary } from "../../atoms/ButtonPrimary";
import { LOGIN_TABS } from "../../../constants/types";
import { showError, showSuccess } from "../../utlis/messages";
import { UserServices } from "../../../services";
import { AwsService } from "../../../services/AwsServices";
import { AuthWrapper } from "./AuthWrapper";

const ChangePasswordRaw = () => {
  const user = useSelector<any>((state) => state.auth.user);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const validation = (values) => {
    const { oldPassword, newPassword, confirmPassword } = values;

    if (!oldPassword) {
      showError("Old password is required.");
      return false;
    }
    if (!newPassword) {
      showError("New password is required.");
      return false;
    }
    if (!confirmPassword) {
      showError("Confirm password is required.");
      return false;
    }

    if (confirmPassword !== newPassword) {
      showError("Passwords do not match.");
      return false;
    }
    return true;
  };

  const sendRequest = async (values) => {
    try {
      setLoading(true);
      if (!validation(values)) {
        return;
      }
      const { oldPassword, newPassword, confirmPassword } = values;
      await AwsService.getInstance().changePassword({
        user,
        oldPassword,
        newPassword
      });
      navigate("/accounts");
      showSuccess("Password has been updated");
    } catch (error: any) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="main-container center-container">
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <div className="shadow-box">
            <h3 style={{ textAlign: "left" }}>Change Password</h3>
            <Form
              style={{ padding: 20 }}
              onFinish={sendRequest}
              layout="vertical"
              form={form}
              name="login-form"
              id="login-form"
            >
              <Form.Item label="Old Password" name="oldPassword">
                <Input.Password allowClear id="oldPassword" />
              </Form.Item>
              <Form.Item label="New Password" name="newPassword">
                <Input.Password allowClear id="newPassword" />
              </Form.Item>
              <Form.Item label="Confirm Password" name="confirmPassword">
                <Input.Password allowClear id="confirmPassword" />
              </Form.Item>
              <ButtonPrimary htmlType="submit" type="primary" size={"medium"} block>
                Update
              </ButtonPrimary>
            </Form>
          </div>
        </div>
      </div>
    </Spin>
  );
};

const ChangePassword = boundError(ChangePasswordRaw);
export default ChangePassword;
